import { useState, useEffect } from 'react';
import { DownloadCSVLink } from './DownloadDataLink';

export default function DownloadTableCSVLink({
  data = [],
  columns = [],
  children,
  options = {},
  ...props
}) {
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const headers = [];
    for(const column of columns) {
      headers.push({
        key: column.dataField,
        label: column.__csvHeaderText || column.text,
      });
    }
    const rows = data.map(d => {
      const row = {};
      for(const column of columns) {
        const formatter = column.__csvTextFormatter instanceof Function ? column.__csvTextFormatter : null;
        row[column.dataField] = formatter ? formatter(d[column.dataField], d, options) : d[column.dataField];
      }
      return row;
    });
    setHeaders(headers);
    setRows(rows);
    setLoading(false);
  }, [data, columns]);

  return (
    <DownloadCSVLink
      {...props}
      loading={loading || props.loading}
      headers={headers}
      rows={rows}
      showLoader={false}
    >
      {children || 'Export to CSV'}
    </DownloadCSVLink>
  );
}

import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoIosWarning } from 'react-icons/io';

import LoadingSpinner from './LoadingSpinner';

import moment from 'moment';

import './title.scss';

function Title({title, loading, error, lastFetch}) {

  return (
    <Row className="title align-items-baseline mb-3">
      <Col xs="auto">
        <h2>{title || "Title"}</h2>
      </Col>
      <Col className="ml-auto" xs="auto">
        {loading ? (
          <LoadingSpinner inline size={2} />
        ) : (
          <small className="text-muted">
            {error ? (
              <OverlayTrigger placement="top" overlay={<Tooltip>{error}</Tooltip>}>
                <IoIosWarning size="2em" className="react-icon--inline"/>
              </OverlayTrigger>
            ) : (
              lastFetch
                ? `Last updated: ${moment(lastFetch).calendar() || ''}`
                : ""
            )}
          </small>
        )}
      </Col>
    </Row>
  );
}

export default Title;

// Rewrite EditOrganisation using hooks and new organisation form.
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchOrganisationWithId } from '../actions';
import { getOrganisation, getOrganisationState } from '../selectors';
import OrganisationPageLayout from '../components/OrganisationPageLayout';
import OrganisationForm from '../components/OrganisationForm';
import Title from '../../../components/Title';
import LoadingSpinner from '../../../components/LoadingSpinner';

function EditOrganisation() {
  const dispatch = useDispatch();
  const params = useParams();
  const id = parseInt(params.id);
  const organisationToEdit = useSelector(state => getOrganisation(state, id));
  const { loading, lastFetch, error } = useSelector(state => getOrganisationState(state, id)) || {};

  useEffect(() => {
    if(id && !organisationToEdit) {
      dispatch(fetchOrganisationWithId({ id }));
    }
  }, []);

  return (
    <OrganisationPageLayout>
      <Title
        title="Edit Organisation"
        loading={loading}
        lastFetch={lastFetch}
        error={error}
      />
      {organisationToEdit ? <OrganisationForm organisation={organisationToEdit} /> : <LoadingSpinner />}
    </OrganisationPageLayout>
  );
}

export default EditOrganisation;
import { ChangeEvent, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Toolbar from '../../../components/TableToolbar';
import './pageLayout.scss';

type SearchProps = {
  disabled?: boolean;
  placeholder?: string;
  onSearchText?: (value: string) => void;
};

function SearchBar({
  disabled=false,
  placeholder='',
  onSearchText,
}: SearchProps) {
  const [searchText, setSearchText] = useState('');
  const handleChangeSearchText = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    if(onSearchText) {
      onSearchText(e.target.value);
    }
  };

  return (
    <label className="search-label">
      <input
        type="text"
        className="form-control align-middle d-inline-block react-bootstrap-table2-search-header"
        disabled={disabled}
        placeholder={placeholder}
        value={searchText}
        onChange={handleChangeSearchText}
      />
    </label>
  );
}

function BillingToolbar({...props}) {
  return (
    <Toolbar
      buttons={[
        <LinkContainer key="plan" to="/billing/plan" exact>
          <Button variant="outline-secondary" className="bill__nav-button">Plan</Button>
        </LinkContainer>,
        <LinkContainer key="usage" to="/billing/usage" exact>
          <Button variant="outline-secondary" className="bill__nav-button">Usage</Button>
        </LinkContainer>,
        <LinkContainer key="transactions" to="/billing/transactions" exact>
          <Button variant="outline-secondary" className="bill__nav-button">Transactions</Button>
        </LinkContainer>,
        // <LinkContainer key="invoices" to="/billing/invoices" exact>
        //   <Button variant="outline-secondary" className="bill__nav-button">Invoices</Button>
        // </LinkContainer>
      ]}
      {...props}
    />
  );
}

type Props = {
  children: React.ReactNode;
  searchProps?: SearchProps & { hide?: boolean };
} & Record<string, any>;

function PageLayout({children, searchProps = {}, ...props}: Props) {
  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="flex-grow-0">
        <Container fluid>
          <BillingToolbar
            searchable
            renderSearchBar={() => {
              return searchProps.hide ? null : <SearchBar {...searchProps} />;
            }}
            {...props}
          />
        </Container>
      </div>
      <div className="d-flex flex-column flex-grow-1 position-relative">
        <Container fluid>
          {children}
        </Container>
      </div>
    </div>
  );
}

export default PageLayout;

import React, { Fragment, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { IoIosCopy, IoLogoLinkedin } from 'react-icons/io';
import copy from 'copy-to-clipboard';
import withNavigationUserProps from './withNavigationUserProps';
import { Link } from 'react-router-dom';

import './userPageLayout.scss';

import { InfoCard, InfoCardItem } from '../../../components/InfoCard';
import { addToast } from '../../../components/Toaster';

import  { fetchUserPreferences } from './../actions';

import {
  getUser,
  isAuthorised,
  getUserPreferenceUnitsOfMeasure,
  getUserOrganisations,
  getUserTypeOptions,
} from '../selectors';

function ContactLink({ type='email', children }) {
  return (
    <a
      className="contact-link--one-line"
      href={`${type === 'tel' ? 'tel' : 'mailto'}:${`${children}`.replace(/\s+/g, '')}`}
    >
      {children}
    </a>
  );
}

function UserSidebar({
  user = {},
  userIsAdmin,
  userUnitsOfMeasurePreference,
  organisations = [],
  fetchUserPreferences,
  userTypeOptions = {},
}) {

  useEffect(() => {
    if (userIsAdmin) {
      fetchUserPreferences(user);
    }
  }, [userIsAdmin, user && user.id]);

  const copyEmail = useCallback(() => {
    const result = copy(user.email, { message: 'Press #{key} to copy' });
    if (result) {
      addToast({ variant: 'success', header: 'Copied to clipboard' });
    }
  }, [user.email]);

  if (!user || !user.id) {
    return null;
  }

  return (
    <Fragment>
      <h5 className="montserrat" style={{marginTop: "10px", marginBottom: "15px"}}>
        {user.name}
      </h5>
      <InfoCard>
        <InfoCardItem
          heading={(
            <Fragment>
              <span>Email</span>
              {' '}
              <Button
                size="sm"
                variant="outline-secondary"
                className="btn-xs btn-muted info-card__inline-heading-button"
                onClick={copyEmail}
              >
                <IoIosCopy /> Copy
              </Button>
            </Fragment>
          )}
        >
          <ContactLink>{user.email}</ContactLink>
        </InfoCardItem>
        <InfoCardItem heading="Phone">
          {`${user.country_code || ''} ${user.mobile_phone || ''}`.trim().length > 0 && (
            <ContactLink type="tel">{`${user.country_code || ''} ${user.mobile_phone || ''}`}</ContactLink>
          )}
        </InfoCardItem>
        <InfoCardItem heading="User type">
          {userTypeOptions[user.user_type]?.display_name || user.user_type}
        </InfoCardItem>
        <InfoCardItem heading="Work Location">
          {user.location}
        </InfoCardItem>
        <InfoCardItem heading="Department">
          {user.department}
        </InfoCardItem>
        <InfoCardItem heading="Position">
          {user.position}
        </InfoCardItem>
        <InfoCardItem heading="LinkedIn">
          {user.linkedin && (
            <a href={user.linkedin} target="_blank" rel="noopener noreferrer">
              <IoLogoLinkedin fontSize="1.4em" style={{ verticalAlign: '-15%' }}/>
            </a>
          )}
        </InfoCardItem>
        <InfoCardItem
          heading="Organisations"
          info="Organisations that this user can log in to"
        >
          <ul className="mt-1 mb-0">
            {(organisations || []).map(({ organisation_id, id, organisation_name, name }) => (
              <li key={organisation_id || id}>
                <Link to={`/organisations/${organisation_id || id}/`}>
                  {organisation_name || name}
                </Link>
              </li>
            ))}
          </ul>
        </InfoCardItem>
      </InfoCard>
      {userIsAdmin && (
        <InfoCard collapsible heading="Preferences">
          <InfoCardItem heading="Units of Measure">
            {userUnitsOfMeasurePreference && (
              !userUnitsOfMeasurePreference.setByDefault
                ? userUnitsOfMeasurePreference.title
                : `${userUnitsOfMeasurePreference.title} (by\u00A0default)`
            )}
          </InfoCardItem>
          <InfoCardItem heading="Notifications">
            {user.receive_daily_email || user.receive_alert_email || user.receive_alert_sms ? (
              <ul className="mt-1 mb-0">
                {user.receive_daily_email && (<li>Receive daily email</li>)}
                {user.receive_alert_email && (<li>Receive alert email</li>)}
                {user.receive_alert_sms && (<li>Receive alert SMS</li>)}
              </ul>
            ) : 'None'}
          </InfoCardItem>
        </InfoCard>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state, { userId, userIsNew }) => {
  return userIsNew ? {} : {
    user: getUser(state, userId),
    userIsAdmin: isAuthorised(state, { minUserType: 'Admin' }),
    userUnitsOfMeasurePreference: getUserPreferenceUnitsOfMeasure(state, userId),
    organisations: getUserOrganisations(state, userId),
    userTypeOptions: getUserTypeOptions(state),
  };
};

export default withNavigationUserProps(
  connect(mapStateToProps, { fetchUserPreferences })(UserSidebar)
);

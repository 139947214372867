import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import './app.scss';

import { PersistGate } from 'redux-persist/lib/integration/react';
import LoadingSpinner from './components/LoadingSpinner';
import { persistor } from './store/configureStore';
import { appLoad } from './modules/app/actions';

import AuthProvider from './lib/providers/AuthProvider';
import BugSnagProvider from './lib/providers/BugSnagProvider';

import { Router } from 'react-router-dom';
import Routes from './RoutesWithTracking';
import history from './history';

import { dropCache } from './lib/utils';
import Toaster from './components/Toaster';
import AppStatus from './components/AppStatus';

function App({ appLoad }) {

  const handleLogout = useCallback(() => {
    dropCache();
  }, [dropCache]);

  return (
    <AppStatus>
      <PersistGate
        loading={<LoadingSpinner />}
        persistor={persistor}
        // triggered action after app is loaded (and persisted state is rehydrated)
        onBeforeLift={appLoad}
      >
        <BugSnagProvider>
          <AuthProvider onLogout={handleLogout}>
            <Router history={history}>
              <Routes />
            </Router>
            <Toaster />
          </AuthProvider>
        </BugSnagProvider>
      </PersistGate>
    </AppStatus>
  );
}

const mapDispatchToProps = { appLoad };

export default connect(null, mapDispatchToProps)(App);

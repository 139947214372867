import { useCallback } from 'react';
import ConfirmModal from '../../../components/ConfirmModal';
import useAlarmEvent from '../../alarm/hooks/useAlarmEvent';

function DeleteCommentButton({event, ...props}) {
  const { deleteEvent } = useAlarmEvent(event.alarm_id);

  const handleDeleteEvent = useCallback(() => {
    return deleteEvent(event.event_id);
  }, [event.event_id]);

  return (
    <ConfirmModal
      body="This can't be undone"
      confirmText="Delete comment"
    >
      <span
        className="btn btn-link btn-sm p-0" style={{cursor: 'pointer'}}
        {...props}
        onClick={handleDeleteEvent}
        aria-hidden="true">Delete
      </span>
    </ConfirmModal>
  );
}

export default DeleteCommentButton;
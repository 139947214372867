import { useGroupValidatedEventsReport } from '../../modules/organisation/hooks/useGroupEventsReport';
import HomeCard from './HomeCard';
import Table from '../Table';
import { equipmentNameLink, identifiedIssueBadge, validatedBy, latestComments } from './columns';

const columns = [
  equipmentNameLink,
  identifiedIssueBadge,
  validatedBy,
  latestComments,
];

function ValidatedEvents() {
  const groupValidatedEventsReport = useGroupValidatedEventsReport();
  if(!groupValidatedEventsReport) return null;

  const {_state_: { loading, error } = {}, items = []} = groupValidatedEventsReport || {};
  return (
    <HomeCard
      header="Validated events"
      headerLink={{to: '/events?status=valid', text: 'All valid events'}}
      loading={loading}
      error={error}
    >
      <Table
        data={items}
        keyField="alarm_id"
        loading={loading}
        columns={columns}
        condensed
      />
    </HomeCard>
  );
}

export default ValidatedEvents;
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';

import { fetchUsers } from '../../user/actions';
import {
  fetchGroup,
  addGroupUsers,
  deleteGroupUsers,
} from '../actions';

import TableListEdit, {
  included,
  sortByActiveThenNumber,
  sortByActiveThenString,
} from '../../../components/TableListEdit';

import { useColumnsWithVisibility } from '../../../components/table/utils';
import { addToast } from '../../../components/Toaster';
import {
  userName,
  userEmail,
  userTypeDisplayName,
  department,
  createdAt,
} from '../../user/columns';

import {
  getUsers,
  getOrganisationUsers,
  hasAdminRightsInActiveGroup,
} from '../../user/selectors';
import {
  getGroupUsers,
  isTopLevelGroupActive,
  isStandardOrganisation,
  getNearestGroupOrganisationAncestor,
  getNearestOrganisationAncestorId,
  getActiveGroupNodeInfo,
} from '../selectors';

const defaultSorted = [{
  dataField: userName.dataField,
  order: 'asc'
}];

const columns = [{
  ...userName,
  sortFunc: sortByActiveThenString,
}, {
  ...userEmail,
  sortFunc: sortByActiveThenString,
}, {
  ...userTypeDisplayName,
  sortFunc: sortByActiveThenNumber,
}, {
  ...department,
  sortFunc: sortByActiveThenString,
}, {
  ...createdAt,
  sortFunc: sortByActiveThenString,
}, {
  ...included,
  text: 'Include',
}];

const searchKeys = [
  userName.dataField,
  userEmail.dataField,
  department.dataField,
];

const returnTrue = () => true;

function EditGroupUsers({
  groupId,
  isNormalGroup,
  isOrganisationGroup,
  topLevelGroupActive,
  userCanViewGroups,
  userCanEditGroups,
  organisationIsStandard,
  nearestOrganisationGroup = {},
  nearestOrganisationId,
  groupUsers,
  availableUsers,
  fetchGroup,
  fetchUsers,
  addGroupUsers,
  deleteGroupUsers,
}) {

  // get users filtered to current org
  const filteredUsers = useMemo(() => {
    return (availableUsers || [])
      .filter(topLevelGroupActive ? Boolean : user => {
        return user &&
          user._embedded &&
          user._embedded.organisations &&
          user._embedded.organisations.find(({ organisation_id }) => {
            return organisation_id === nearestOrganisationId;
          });
      });
  }, [availableUsers, nearestOrganisationId, topLevelGroupActive]);

  const isItemIncluded = useMemo(() => {
    const groupUserIds = (groupUsers || []).map(({ id }) => id);
    return user => groupUserIds.includes(user.id);
  }, [groupUsers]);

  const [loadingUsers, setLoadingUsers] = useState({});
  // load users list
  useEffect(() => {
    const group = { id: groupId };
    // fetch group's users if needed
    // add already fetching state
    async function loadUsersLists() {
      const requestedAt = Date.now();
      setLoadingUsers({ requestedAt });
      try {
        // this logic should follow that of the selector logic
        // if on top level, fetch all users
        await Promise.all([
          // get available users
          fetchUsers(),
          // also get root list users if needed
          // if not viewing the top org (where all users are groupUsers)
          ...isNormalGroup ? [
            fetchUsers({ forOrg: true }),
          ] : [],
        ]);
        setLoadingUsers(state => {
          return state.requestedAt === requestedAt
            ? { succeededAt: new Date() }
            : state;
        });
      }
      catch (error) {
        setLoadingUsers(state => {
          return state.requestedAt === requestedAt
            ? { error: error.message || 'Error' }
            : state;
        });
      }
    }
    // execute async code
    if (group.id) {
      loadUsersLists();
    }
  }, [groupId, isNormalGroup]);

  const [loadingGroups, setLoadingGroups] = useState({});
  // load organisation detail
  useEffect(() => {
    const group = { id: groupId };
    // fetch group's users if needed
    // add already fetching state
    async function loadGroups() {
      const submittedAt = Date.now();
      setLoadingGroups({ submittedAt });
      try {
        await Promise.all([
          // get group details
          fetchGroup(group),
          // if viewing a sub organisation's group
          // fetch that organisation's details
          ...(!topLevelGroupActive && nearestOrganisationGroup.id) ? [
            fetchGroup({ id: nearestOrganisationGroup.id }),
          ] : [],
        ]);
        setLoadingGroups(state => {
          return state.submittedAt === submittedAt
            ? { succeededAt: new Date() }
            : state;
        });
      }
      catch (error) {
        setLoadingGroups(state => {
          return state.submittedAt === submittedAt
            ? { error: error.message || 'Error' }
            : state;
        });
      }
    }
    // execute async code
    if (group.id) {
      loadGroups();
    }
  }, [groupId, topLevelGroupActive, nearestOrganisationGroup.id]);

  const onSubmit = useCallback(async (includedUsers=[]) => {
    const group = { id: groupId };

    // get users list from current form state
    const newUserIds = includedUsers.map(d => d.id);
    const oldUserIds = (groupUsers || []).map(d => d.id);
    const addIds = newUserIds.filter(id => !oldUserIds.includes(id));
    const delIds = oldUserIds.filter(id => !newUserIds.includes(id));

    if (addIds.length || delIds.length) {
      await Promise.all([
        addIds.length && addGroupUsers(group, addIds),
        delIds.length && deleteGroupUsers(group, delIds),
      ]);
      addToast({ variant: 'success', header: 'Users updated successfully' });
      await fetchUsers(group);
    } else {
      addToast({ variant: 'warning', header: 'No changes made' });
    }
  }, [
    addGroupUsers,
    deleteGroupUsers,
    fetchUsers,
    groupId,
    groupUsers,
  ]);

  const visibleColumns = useColumnsWithVisibility(columns, {
    // apply hidden status to hide organisation field for standard orgs
    'organisation_name': !organisationIsStandard,
    // if at least one device is marked as archived, show the archive status column
    'archived': !!filteredUsers.find(({ archived }) => archived),
  });

  const loadingState = useMemo(() => {
    return {
      submittedAt: loadingUsers.submittedAt || loadingGroups.submittedAt,
      // only show last time users were fetched (not groups)
      succeededAt: loadingUsers.succeededAt,
      error: loadingUsers.error || loadingGroups.error,
    };
  }, [loadingUsers, loadingGroups]);

  if (!userCanViewGroups) {
    return null;
  }

  return (
    <TableListEdit
      before={organisationIsStandard ? null : (
        <Alert variant="warning">
          <p>
            You cannot modify child organisation groups as a parent organisation.
          </p>
          <p className="mb-0">
            Sign into the child organisation directly to modify a group.
          </p>
        </Alert>
      )}
      title="Group Users"
      columns={visibleColumns}
      defaultSorted={defaultSorted}
      loading={loadingState}
      noDataIndication={() => 'No Users'}
      list={filteredUsers}
      isItemIncluded={isOrganisationGroup ? returnTrue : isItemIncluded}
      isItemDisabled={isOrganisationGroup ? returnTrue : undefined}
      searchKeys={searchKeys}
      onSubmit={onSubmit}
      userCanEdit={!!userCanEditGroups && isNormalGroup}
    />
  );
}


const mapStateToProps = (state, { groupId }) => {
  const {
    isNormalGroup,
    isOrganisationGroup,
  } = getActiveGroupNodeInfo(state);
  const topLevelGroupActive = isTopLevelGroupActive(state);
  const userIsAdmin = !!hasAdminRightsInActiveGroup(state);
  const organisationIsStandard = !!isStandardOrganisation(state);
  const nearestOrganisationGroup = getNearestGroupOrganisationAncestor(state, groupId);
  const nearestOrganisationId = getNearestOrganisationAncestorId(state, groupId);
  return {
    isNormalGroup,
    isOrganisationGroup,
    topLevelGroupActive,
    userCanViewGroups: userIsAdmin,
    userCanEditGroups: userIsAdmin && organisationIsStandard,
    organisationIsStandard,
    nearestOrganisationGroup,
    nearestOrganisationId,
    groupUsers: topLevelGroupActive
      // at top level, all users are group users
      ? getUsers(state)
      // at other levels, display users from group membership
      : getGroupUsers(state, groupId),
    availableUsers: topLevelGroupActive
      // at top level, all users are group users
      ? getUsers(state)
      // else, show all the users available in the organisation
      : getOrganisationUsers(state, nearestOrganisationId)
  };
};
const mapDispatchToProps = {
  fetchUsers,
  fetchGroup,
  addGroupUsers,
  deleteGroupUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupUsers);

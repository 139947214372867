import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

export const DATE_RANGE = {
  ALL: 'all',
  LAST_WEEK: 'last_week',
  LAST_MONTH: 'last_month',
  LAST_YEAR: 'last_year',
};

function AlarmDateFilter({
  onDateChange,
  defaultDateRange,
  disabled,
}) {
  const [dateRange, setDateRange] = useState(defaultDateRange || DATE_RANGE.ALL);

  useEffect(() => {
    if(typeof onDateChange === 'function') {
      onDateChange(dateRange);
    }
  }, [dateRange]);

  return (
    <Form.Control
      as="select"
      onChange={(e) => setDateRange(e.target.value)}
      value={dateRange}
      disabled={disabled}
    >
      <option value={DATE_RANGE.ALL}>All</option>
      <option value={DATE_RANGE.LAST_WEEK}>Last week</option>
      <option value={DATE_RANGE.LAST_MONTH}>Last month</option>
      <option value={DATE_RANGE.LAST_YEAR}>Last year</option>
    </Form.Control>
  );
}

export default AlarmDateFilter;
import { useMemo } from 'react';
import HomeCard from './HomeCard';
import Table from '../Table';
import { useValidatedEventsManagementReport } from '../../modules/organisation/hooks/useGroupEventsReport';
import {
  equipmentNameLink,
  identifiedIssue,
  estHoursLost,
  estHoursLostAvoided,
  estEventCosts,
  estEventCostsAvoided,
  estEventCostsPrevented,
} from './columns';

function ValidatedEventsMgnt({ currency }) {
  const validatedEventsManagementReport = useValidatedEventsManagementReport();

  const columns = useMemo(() => [
    equipmentNameLink,
    identifiedIssue,
    estHoursLost,
    estHoursLostAvoided,
    estEventCosts(currency),
    estEventCostsAvoided(currency),
    estEventCostsPrevented(currency),
  ], [currency]);

  if(!validatedEventsManagementReport) return null;

  const {_state_: { loading, error } = {}, items = []} = validatedEventsManagementReport || {};

  return (
    <HomeCard
      header="Events validated"
      headerLink={{to: '/events?status=valid', text: 'All valid events'}}
      loading={loading}
      error={error}
    >
      <div className="smaller-font-size-table-container">
        <Table
          data={items}
          keyField="alarm_id"
          loading={loading}
          columns={columns}
          condensed
        />
      </div>
    </HomeCard>
  );
}

export default ValidatedEventsMgnt;
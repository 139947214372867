import { useCallback, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Table from "../../../components/Table";
import { ExpansionIndicator } from '../../../components/table/formatters';
import DeveloperCentreToolbar from "../components/DeveloperCentreToolbar";
import useWebhooks from "../hooks/useWebhooks";
import { endpoint } from "../columns";
import OrganisationWebhookActions from "../components/OrganisationWebhookActions";

const webhookActions = {
  dataField: '',
  text: 'Action',
  formatter: (value, row) => {
    return (
      <OrganisationWebhookActions endpoint={row} />
    );
  }
};

function OrganisationWebhooks() {

  const { loading, fetchWebhooks, endpointsData } = useWebhooks();

  useEffect(() => { fetchWebhooks(); }, []);

  const renderHeader = useCallback(props => {
    return (
      <DeveloperCentreToolbar
        tableProps={props}
        loading={loading}
        subTitle="View and manage endpoints."
        title="Webhooks"
      />
    );
  }, [loading]);

  return (
    <Container fluid>
      <Table
        renderHeader={renderHeader}
        data={endpointsData}
        columns={[endpoint, webhookActions]}
        keyField="endpoint"
        showExpandColumn
        striped={false}
        loading={loading}
        expandRow={{
          renderer: row => {
            return (
              <div style={{ width: '95%', marginLeft: '54px', padding: '.75rem' }}>
                <Row className="d-flex mb-2">
                  <Col sm="2" className="text-secondary">Destination</Col>
                  <Col sm="10">{row.data_format || 'None'}</Col>
                </Row>
                <Row className="d-flex">
                  <Col sm="2" className="text-secondary">Events</Col>
                  <Col sm="10">
                    {row.webhook.map((wh, index) => (
                      <div
                        className={"mb-2" + (index === row.webhook.length - 1 ? "" : " border-bottom")}
                        key={wh.key}
                      >
                        {wh.description} - {wh.short_name}
                      </div>
                    ))}
                  </Col>
                </Row>
                {/* <div>
                  <span>Headers</span>
                </div>
                <div>
                  <span>Parameters</span>
                </div> */}
              </div>
            );
          },
          showExpandColumn: true,
          expandByColumnOnly: true,
          expandColumnRenderer: ({expanded}) => {
            return (
              <div style={{width: '30px'}}>
                <ExpansionIndicator expanded={expanded} />
              </div>
            );
          }
        }}
      />
    </Container>
  );
}

export default OrganisationWebhooks;
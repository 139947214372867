import { useGroupAcknowledgedEventsReport } from '../../modules/organisation/hooks/useGroupEventsReport';
import HomeCard from './HomeCard';
import Table from '../Table';
import { equipmentNameLink, initialAlarmBadge, acknowledgedBy, latestComments } from './columns';

const columns = [
  equipmentNameLink,
  initialAlarmBadge,
  acknowledgedBy,
  latestComments,
];

function AcknowledgedEvents() {
  const groupAcknowledgedEventsReport = useGroupAcknowledgedEventsReport();
  if(!groupAcknowledgedEventsReport) return null;

  const { _state_: { loading, error } = {}, items = [] } = groupAcknowledgedEventsReport || {};
  return (
    <HomeCard
      header="Acknowledged events"
      headerLink={{to: '/events?status=acknowledged', text: 'All acknowledged events'}}
      loading={loading}
      error={error}
    >
      <Table
        data={items}
        keyField="alarm_id"
        loading={loading}
        columns={columns}
        condensed
      />
    </HomeCard>
  );
}

export default AcknowledgedEvents;
import HomeCard from './HomeCard';
import { useGroupTemperatureOrder } from '../../modules/organisation/hooks/useGroupStandardData';
import Table from '../Table';
import { equipmentNameLink, lastDataProcessed, temperature, utilisation } from './columns';
import useDeviceStatusFilter from '../../modules/equipment/hooks/useDeviceStatusFilter';

const columns = [
  lastDataProcessed,
  equipmentNameLink,
  temperature,
  utilisation,
];

function HighestTemperatureEquipment() {
  const groupTemperatureOrder = useGroupTemperatureOrder();
  const { filterAll } = useDeviceStatusFilter();

  if(!groupTemperatureOrder) return null;

  const {_state_: {loading, error} = {}, items = []} = groupTemperatureOrder || {};
  return (
    <HomeCard
      header="Highest temperature equipment"
      headerLink={{to: '/', text: 'All equipment', onClick: filterAll }}
      loading={loading}
      error={error}
    >
      <Table
        data={items}
        keyField="device_id"
        loading={loading}
        columns={columns}
        condensed
      />
    </HomeCard>
  );
}

export default HighestTemperatureEquipment;
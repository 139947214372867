import React, { useCallback, useState, memo } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Badge, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IoIosContact } from 'react-icons/io';
import QuillEditor from '../../../components/form/QuillEditor';
import moment from 'moment';

import LoadingSpinner from '../../../components/LoadingSpinner';
import UserPicture from '../../user/components/UserPicture';
import { DateOverlay } from '../../../components/table/formatters';
import DeviceAlarmEventsTableView from './DeviceAlarmEventsTableView';

import {
  createAlarmEvent,
  fetchAlarmEvents,
} from '../../alarm/actions';

import { getAlarm, getAlarmDeviceId, getAlarmEvents } from "../../alarm/selectors";
import { getDeviceHasProductCode } from '../selectors';
import useDeviceWatchers from '../hooks/useDeviceWatchers';
import useActivityView from '../hooks/useActivityView';
import EditCommentButton from './EditCommentButton';
import DeleteCommentButton from './DeleteCommentButton';

function commentDateFormatter(date) {
  return date ? `${
    moment(date).format('llll')
  } (${
    moment(date).fromNow()
  })` : 'N/A';
}

function UserPictureLink({ userId }) {
  return userId ? (
    <Link to={`/users/${userId}`}>
      <UserPicture userId={userId} size="3.2em" style={styles.eventSourceIcon} />
    </Link>
  ) : (
    <IoIosContact size="2.2em" style={styles.eventSourceIcon} />
  );
}

function UserNameLink({ userId, userName }) {
  return userId ? (
    <Link to={`/users/${userId}`} style={styles.eventSourceLink}>
      {userName}
    </Link>
  ) : (
    userName
  );
}

function EventRow({ className, event, description, children }) {

  return (
    <div
      className={[
        `m-0 py-3 px-2 border-top`,
        className,
      ].filter(Boolean).join(' ')}
    >
      <Row>
        <Col
          xs="auto"
          className="d-flex align-items-center justify-content-center pr-0"
          style={styles.eventSourceColumn}
        >
          {event.event_type === 'user' && (event.user_id ? (
            <UserPictureLink userId={event.user_id} size="3.2em" />
          ) : (
            <IoIosContact size="2.2em" />
          ))}
        </Col>
        <Col xs="auto" className="flex-grow-1">
          <Row>
            <Col xs="auto" className="d-flex align-items-center text-muted border-right">
              {event.event_type === 'user' ? (
                <UserNameLink
                  userId={event.user_id}
                  userName={event.user_name || 'User Event'}
                />
              ) : event.event_type === 'system' ? (
                'System Event'
              ) : (
                'Event'
              )}
            </Col>
            <Col xs="auto" className="d-flex align-items-center text-muted">
              <DateOverlay
                date={event.event_time}
                formatter={commentDateFormatter}
              />
            </Col>
            {(event.can_edit || event.can_delete) && (
              <Col xs="auto" className="d-flex align-items-center ml-auto">
                <Dropdown>
                  <Dropdown.Toggle variant="light" className="py-0 px-2" />
                  <Dropdown.Menu>
                    {event.can_edit &&
                      <Dropdown.Item>
                        <EditCommentButton event={event} className="d-block" />
                      </Dropdown.Item>}
                    {event.can_delete &&
                      <Dropdown.Item>
                        <DeleteCommentButton event={event} className="d-block" />
                      </Dropdown.Item> }
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            )}
          </Row>
          {description && (
            <Row>
              <Col xs="auto" className="">
                <span>{description}</span>
              </Col>
            </Row>
          )}
          {event.comment && (
            <Row>
              <Col xs="auto" className="flex-grow-1 bg-white mt-2 mx-3 py-2 px-3 rounded">
                <span>
                  {event.comment.split(/(?:\r\n|\r|\n)/).map((line, index) => (
                    // space paragraphs with padding to preserve empty line spaces
                    <p key={index} className={[
                      'mb-0',
                      index && 'pt-2',
                    ].filter(Boolean).join(' ')}>
                      {line}
                    </p>
                  ))}
                </span>
              </Col>
            </Row>
          )}
        </Col>
        {children && (
          <Col xs="auto" className="d-flex align-items-center ml-auto">
            {children}
          </Col>
        )}
      </Row>
    </div>
  );
}

export const AddCommentRow = memo(function AddCommentRow({ postEvent, confirming, alarm }) {

  const [comment, setComment] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [editorRef, setEditorRef] = useState(null);
  const { watchers, loading: loadingWatchers } = useDeviceWatchers(alarm.device_id);

  const readCommentsFromEditor = (editorRef) => {
    const editor = editorRef && editorRef.getEditor && editorRef.getEditor();
    const contents = editor && editor.getContents && editor.getContents();
    if(!contents) return '';
    const commentText = (contents.ops || []).reduce((acc, { insert }) => {
      return insert ? `${acc}${insert.mention ? `${insert.mention.denotationChar}${insert.mention.value}` : insert}` : acc;}, '');
    return commentText.trim();
  };

  const handleSubmit = useCallback(async () => {
    const comment = readCommentsFromEditor(editorRef);
    setComment(comment);
    if (comment) {
      setSubmitting(true);
      try {
        await postEvent({ comment });
        // reset the form on submission
        setComment('');
        setSubmitting(false);
      }
      catch {
        setSubmitting(false);
      }
    }
  }, [editorRef, postEvent, setSubmitting, setComment, readCommentsFromEditor]);

  return (
    <div className="mb-2">
      <div className="mb-1">
        <strong>Add comment</strong>
        <span> - type @ to see who you can mention.</span>
      </div>
      <Row>
        <Col
          xs="auto"
          className="d-flex flex-grow-1"
        >
          <QuillEditor
            mentionsList={watchers}
            readOnly={confirming || submitting || loadingWatchers}
            ref={el => setEditorRef(el)}
            defaultValue={comment}
          />
        </Col>
        <Col xs="auto" className="d-flex align-items-center ml-auto mb-2 pl-0">
          {submitting && <LoadingSpinner inline size={1} />}
          <Button
            variant="primary"
            className={['mb-0', submitting && 'ml-2'].filter(Boolean).join(' ')}
            // disable if comment is being saved or is empty
            disabled={confirming || loadingWatchers}
            onClick={handleSubmit}
          >
            Add comment
          </Button>
        </Col>
      </Row>
    </div>
  );
});

function EventBadges({ event }) {
  switch(event && event.alarm_status) {
    case 'new': return <Badge variant="secondary">{event.event_type === 'user' ? 'Manual Alarm' : 'Alarm triggered'}</Badge>;
    case 'acknowledged': return <Badge variant="warning">Alarm acknowledged</Badge>;
    case 'valid': return (
      <>
        <Badge variant="success">
          Alarm marked as valid
        </Badge> <Badge pill variant="info">
          {event.reason}
        </Badge>
      </>
    );
    case 'invalid': return (
      <>
        <Badge variant="danger">
          Alarm marked as invalid
        </Badge> <Badge pill variant="info">
          {event.reason}
        </Badge>
      </>
    );
    case 'scheduled': return <Badge variant="info">Maintenance scheduled {event.due_date && `for ${moment(event.due_date).format('DD MMM YYYY')}`}</Badge>;
    case 'in_progress': return <Badge variant="info">Maintenance in progress</Badge>;
    case 'resolved': return <Badge variant="success">Alarm resolved</Badge>;
    case 'closed': return <Badge variant="dark">Alarm marked as closed</Badge>;
    default: return null;
  }
}

function DeviceAlarmEventsListView({
  alarmId,
  createAlarmEvent,
  fetchAlarmEvents,
  alarm,
  events = [],
  canEditEventFeedback,
}) {

  const [confirming, setConfirming] = useState(false);
  const postEvent = useCallback(async payload => {
    if (alarmId) {
      setConfirming(true);
      try {
        await createAlarmEvent({ id: alarmId }, payload);
        // get new values into redux
        await Promise.all([
          fetchAlarmEvents({ id: alarmId }),
        ]);
        setConfirming(false);
      }
      catch {
        setConfirming(false);
      }
    }
  }, [alarmId, fetchAlarmEvents, createAlarmEvent]);

  return (
    <div className="p-3">
      {canEditEventFeedback && alarm && alarm.alarm_status !== 'closed' && (
        <AddCommentRow
          postEvent={postEvent}
          confirming={confirming}
          alarm={alarm}
        />
      )}
      <div className="mb-1">
        <strong>Comments</strong>
      </div>
      {events.map(event => {
        return (
          <EventRow
            key={event.event_id}
            event={event}
            description={<EventBadges event={event} />}
            postEvent={postEvent}
            confirming={confirming}
          />
        );
      })}
    </div>
  );
}

// fetching of alarm and events should be done by higher components for now
const mapStateToProps = (state, { alarmId }) => {
  const deviceId = getAlarmDeviceId(state, alarmId);
  return {
    alarmId,
    alarm: getAlarm(state, alarmId),
    events: getAlarmEvents(state, alarmId),
    canEditEventFeedback: getDeviceHasProductCode(state, deviceId, 'event_feedback'),
  };
};
const mapDispatchToProps = {
  createAlarmEvent,
  fetchAlarmEvents,
};

const ConnectedDeviceAlarmEventsListView = connect(mapStateToProps, mapDispatchToProps)(DeviceAlarmEventsListView);

const styles = {
  eventSourceColumn: { width: '4.2em' },
  eventSourceIcon: { color: '#333' },
  eventSourceLink: { color: '#4777ab' },
};

function DeviceAlarmEvents({ alarmId }) {
  const view = useActivityView();
  return (
    <>
      {view === 'alarms' ? <ConnectedDeviceAlarmEventsListView alarmId={alarmId} /> :
        view === 'events' ? <DeviceAlarmEventsTableView alarmId={alarmId} /> : null
      }
    </>
  );
}

export default DeviceAlarmEvents;
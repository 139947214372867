
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Nav, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Icon } from '@blueprintjs/core';
import withNavigationGatewayProps from './withNavigationGatewayProps';

// Other
import { getGateway } from '../selectors';
import Private from '../../../components/Private';
import { IoIosCreate } from 'react-icons/io';

export function EditGatewayLink({ gateway }) {
  return (
    <Private minUserType="Admin">
      <LinkContainer to={`/gateways/${gateway.id}/edit`}>
        <Button size="md" variant="outline-secondary">
          <IoIosCreate size="1.2em" /> <span>Edit</span>
        </Button>
      </LinkContainer>
    </Private>
  );
}

function GatewayNav({ gateway={} }) {
  return (
    <Nav as="ul" className="flex-grow-1">
      <Row className="small-gutters flex-grow-1">
        <Col xs="auto">
          {gateway.id && (
            <Row className="small-gutters">
              <Col xs="auto" className="mb-1">
                <LinkContainer to={`/gateways/${gateway.id}`} exact>
                  <Button size="md" variant="outline-secondary">
                    <Icon
                      iconSize="1.1em"
                      icon="graph"
                      title="Network"
                      style={{ width: '1.1em', verticalAlign: '-12%' }}
                    /> <span>Network</span>
                  </Button>
                </LinkContainer>
              </Col>
            </Row>
          )}
        </Col>
        <Col xs="auto" className="mb-1 ml-auto">
          <EditGatewayLink gateway={gateway} />
        </Col>
      </Row>
    </Nav>
  );
}

const mapStateToProps = (state, { gatewayId }) => {
  return {
    gateway: getGateway(state, gatewayId),
  };
};

export default withNavigationGatewayProps(
  connect(mapStateToProps)(GatewayNav)
);


import React, { Fragment } from 'react';
import { useParams, useHistory } from 'react-router';

import AICalendar from '../../../components/charts/AICalendar';
import AIChart from '../../../components/charts/AIChart';
import MeasuredDataChart from '../../../components/charts/MeasuredDataChart';
import DeviceSamplesContext from '../../../components/charts/DeviceSamplesContext';
import { isFitPower } from '../utils';

import EquipmentPageLayout from '../components/EquipmentPageLayout';
import useDevice from '../hooks/useDevice';

export function NoSampleData() {
  return (
    <>
      <p>
        Sample data is not currently available for archived devices.
      </p>
    </>
  );
}

export function DeviceNotFound() {
  return (
    <p>The device you requested is not found within the organisation or group you're at.</p>
  );
}

function EquipmentCharts() {
  const { id } = useParams();
  const { device } = useDevice(parseInt(id));
  const history = useHistory();

  if(!device) {
    return (
      <div className="my-4">
        <DeviceNotFound />
      </div>
    );
  }

  // return archived status message
  if (device.archived) {
    return (
      <div className="my-4">
        <NoSampleData />
      </div>
    );
  }

  if(isFitPower(device)) {
    history.replace(`/equipment/${device.id}/fitpower`);
    return null;
  }

  return (
    <DeviceSamplesContext deviceId={device.id}>
      {chartProps => (
        <Fragment>
          <AICalendar deviceId={device.id} {...chartProps} />
          <AIChart deviceId={device.id} {...chartProps} />
          <MeasuredDataChart deviceId={device.id} {...chartProps} />
        </Fragment>
      )}
    </DeviceSamplesContext>
  );
}

export default function EquipmentDetail() {
  return (
    <EquipmentPageLayout>
      <div className="mb-chat-widget">
        <EquipmentCharts />
      </div>
    </EquipmentPageLayout>
  );
}

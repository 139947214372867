import { useCallback } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FormModal from '../../../components/FormModal';
import useAlarmImpactSettings from '../hooks/useAlarmImpactSettings';

function AlarmImpactSettingsModal({ config, deviceId, groupId, disabled, children }) {
  const editMode = !!config;
  const {
    addImpactSetting,
    updateImpactSetting,
    loading,
    group,
    device,
  } = useAlarmImpactSettings({ groupId, deviceId });

  const { register, handleSubmit, reset, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      event_category: config?.event_category || '',
      description: config?.description || '',
      units: config?.units || '',
      rate: config?.rate || '',
    }
  });

  const resetForm = useCallback(() => {
    reset({
      event_category: config?.event_category || '',
      description: config?.description || '',
      units: config?.units || '',
      rate: config?.rate || 1,
    });
  }, [config, reset]);

  const handleShowModal = useCallback(() => {
    resetForm();
  }, [config, resetForm]);

  const onSubmit = useCallback((data) => {
    if(editMode) {
      updateImpactSetting({id: config.id}, data);
    } else {
      addImpactSetting(data);
    }
  }, [device, group, config, updateImpactSetting, addImpactSetting]);

  const renderForm = () => {
    return (
      <Form
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col xs={12}>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Rate Name
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  {...register("event_category", {
                    required: true,
                  })}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Description
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  {...register("description", {
                    required: true,
                  })}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Units
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  {...register("units", {
                    required: true,
                  })} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Unit Rate
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  {...register("rate", {
                    required: true,
                    min: 1,
                    valueAsNumber: true,
                    validate: value => +value > 0 && value*100 === Math.floor(value*100),
                    // Make sure the input is a number and it has two decimal digits.
                  })}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    );
  };

  const renderFormHeader = () => {
    return (
      <>
        <h3>{editMode ? 'Edit Rate' : 'Add Rate'}</h3>
        <div>{device?.equipment_name} {group?.group_name}</div>
      </>
    );
  };


  return (
    <FormModal
      size="md"
      header={renderFormHeader()}
      form={renderForm()}
      valid={(formState.isValid && formState.isDirty) && !loading}
      onShow={handleShowModal}
    >
      <Button size="md" variant={editMode ? "outline-secondary" : "primary"} disabled={disabled}>
        {children}
      </Button>
    </FormModal>
  );
}

export default AlarmImpactSettingsModal;
import { useEffect, useMemo, useCallback } from "react";
import { Form } from "react-bootstrap";
import { MdExpandMore } from 'react-icons/md';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useForm } from "react-hook-form";
import Title from "../../../components/Title";
import { fetchUserSubscriptions } from '../actions';
import { getUserSubscriptions, getUserSubscriptionsState } from '../selectors';
import { getOrganisation } from "../../organisation/selectors";
import Table from '../../../components/Table';
import { Overlay } from '../../../components/table/formatters';

function UserAlarmSubscriptions({ user }) {
  // "user" is an optional property.
  // If it's empty, this component is about setting subscriptions for current user.
  // If it's given a user object, it's about setting subscriptions for current user, if user id equals to current user id or for a particular user, if not equal.
  const dispatch = useDispatch();
  const subscriptions = useSelector(state => getUserSubscriptions(state, user?.id), shallowEqual) || [];
  const { loading } = useSelector(state => getUserSubscriptionsState(state, user?.id)) || {};
  const organisation = useSelector(getOrganisation);

  const mainChannels = useMemo(() => {  // Filter out the main channels(escalated = false) from subscriptions
    return subscriptions.filter(sub => sub.escalation === false);
  }, [subscriptions]);

  const getEscalatedChannel = useCallback((channel) => {  // Given a channel or sub-channel, find out its escalated counterpart.
    const escalatedChannels = subscriptions.filter(sub => sub.channel_id === channel.channel_id && sub.escalation === true);
    const escalatedChannel = escalatedChannels && escalatedChannels[0]; // We assume one channel has only one corresponding escalated channel.
    if(!escalatedChannel) return null;
    if(channel.sub_channel_id) {
      return escalatedChannel.sub_channels.find(sub => sub.sub_channel_id === channel.sub_channel_id);
    }
    return escalatedChannel;
  }, [subscriptions, mainChannels]);

  const getChannelName = useCallback((channel) => { // Given a channel and concatenate its channel id, sub-channel id and escalation into a string that is used for form checkbox name.
    // The name has a format such that: 'channel-{channel_id}.{sub_channel_id}-escalation-{0|1}'
    // {sub_channel_id} is 0 if the channel is a main channel.
    // {0|1} indicates if the channel is escalatable: 0 - No, 1 - Yes.
    if(!channel) return '';
    return `channel-${channel.channel_id}.${channel.sub_channel_id || 0}-escalation-${+channel.escalation}`;
  }, []);

  const { register, watch } = useForm({});
  const watchMainChannels = watch(mainChannels.map(getChannelName));
  const watchMainEscalationChannels = watch(mainChannels.map(getEscalatedChannel).map(getChannelName));

  const ChannelCheckBox = useCallback(({channel = {}, ...props}) => {
    if(Object.keys(channel).length === 0) return null;
    return (
      <Form.Group
        key={`key-${getChannelName(channel)}`}
        controlId={`key-${getChannelName(channel)}`}
        className="mb-0"
      >
        <Form.Check
          type="checkbox"
          {...register(getChannelName(channel))}
          defaultChecked={channel.subscribed}
          {...props}
        />
      </Form.Group>
    );
  }, []);

  const columns = [
    {
      dataField: 'name',
      text: 'Channel Name',
      classes: (cell, row) => {
        if(row.sub_channel_id) return 'pl-4'; // indent sub channels.
      }
    },
    {
      dataField: 'subscribe',
      text: 'Subscribe',
      align: 'center',
      headerAlign: 'center',
      formatter: (cell, channel, index, { watchMainChannels }) => {
        const mainChannelIndex = mainChannels.findIndex(ch => ch.channel_id === channel.channel_id);
        return <ChannelCheckBox channel={channel} disabled={channel.sub_channel_id && watchMainChannels[mainChannelIndex]} />;
      },
      formatExtraData: {
        watchMainChannels,
      }
    },
    {
      dataField: 'subscribe_escalation',
      text: 'Subscribe to Escalation',
      align: 'center',
      headerAlign: 'center',
      formatter: (cell, channel, index, { watchMainEscalationChannels }) => { // Channel can be a main channel or sub channel and it's an unescalated channel.
        const escalatedChannel = getEscalatedChannel(channel) || {};  // Find out one channel's escalated counterparts, consider both the case of main channel and subchannel.
        const escalationChannelIndex = mainChannels.findIndex(ch => ch.channel_id === escalatedChannel.channel_id);
        return <ChannelCheckBox channel={escalatedChannel} disabled={channel.sub_channel_id && watchMainEscalationChannels[escalationChannelIndex]} />;
      },
      formatExtraData: {
        watchMainEscalationChannels,
      }
    }
  ];

  useEffect(() => {
    dispatch(fetchUserSubscriptions(user));
  }, []);

  const renderExpansionIcon = useCallback((expanded) => {
    return (
      <Overlay tooltip={expanded ? 'Hide sub-channels' : 'View sub-channels'}>
        <MdExpandMore style={{
          transform: `${expanded ? '' : 'rotate(-90deg)'}`,
          transition: 'transform, .3s',
          cursor: 'pointer',
          verticalAlign: 'middle',
        }} />
      </Overlay>
    );
  }, []);

  return (
    <div className="mb-3">
      <Title title="Alarm channel Subscriptions" />
      <Table
        keyField="channel_id"
        striped={false}
        loading={loading}
        columns={columns}
        data={mainChannels}
        classes="table-fixed-width min-width-500"
        expandRow={organisation.alarm_subchannels ? {
          // If sub-channels are disabled on organisation level, no need to show the expansion icon.
          renderer: (row) => {
            return <Table
              keyField="sub_channel_id"
              data={row.sub_channels}
              columns={[{dataField: '', text: '', style: {width: '30px'}}, ...columns]}
              striped={false}
              headerClasses="header-class"
              classes="table-fixed-width"
            />;
          },
          showExpandColumn: true,
          expandByColumnOnly: true,
          expandColumnRenderer: ({ expanded }) => renderExpansionIcon(expanded),
          expandHeaderColumnRenderer: () => null,
        } : undefined}
      />
    </div>
  );
}

export default UserAlarmSubscriptions;
import { useGroupRecentEventsReport } from '../../modules/organisation/hooks/useGroupEventsReport';
import HomeCard from './HomeCard';
import Table from '../Table';
import { equipmentNameLink, initialAlarmLink, alarmTypeBadge, eventDetails } from './columns';

const columns = [
  initialAlarmLink,
  equipmentNameLink,
  alarmTypeBadge,
  eventDetails,
];

function RecentEvents() {
  const groupRecentEventsReport = useGroupRecentEventsReport();
  if(!groupRecentEventsReport) return null;

  const {_state_: { loading, error } = {}, items = []} = groupRecentEventsReport || {};
  return (
    <HomeCard
      header="Most recent events"
      headerLink={{to: '/events', text: 'All recent events'}}
      loading={loading}
      error={error}
    >
      <Table
        data={items}
        keyField="alarm_id"
        loading={loading}
        columns={columns}
        condensed
      />
    </HomeCard>
  );
}

export default RecentEvents;
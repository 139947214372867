import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import BasicModal from '../../../components/BasicModal';
import StoreButtons from '../../../components/StoreButtons';
import ContactSupportLink from '../../../components/ContactSupportLink';
import { AspectRatioImage } from '../../../components/AspectRatio';

import appImage from '../../../images/preview-app.png';
import dashImage from '../../../images/preview-dashboard.png';
import fmImage from '../../../images/fmPlaceholder.png';

function DisplayImage({ children }) {
  return (
    <AspectRatioImage className="py-4 px-2" ratio={4/3}>
      {children}
    </AspectRatioImage>
  );
}

function Body() {
  return (
    <Row className="text-center">
      <Col lg={8}>
        <h3><small>I have FitMachines to onboard...</small></h3>
        <Row>
          <Col xs={6}>
            <DisplayImage>
              <img src={appImage} alt="MOVUS mobile app"/>
            </DisplayImage>
            <p className="font-weight-bold">Use the app</p>
            <StoreButtons />
            <br/>
            <p>
              <a
                href="https://learn.movus.com.au/knowledge/getting-started"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </p>
          </Col>
          <Col xs={6}>
            <DisplayImage>
              <img src={dashImage} alt="MOVUS dashboard"/>
            </DisplayImage>
            <p className="font-weight-bold">Onboard online + place later</p>
            <p className="text-secondary">Coming soon</p>
            <p>
              <a
                href="https://portal.productboard.com/ghmeyjn8uc2jeqwugnvwkagc/c/28-onboard-fitmachine-via-the-web-place-later"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </p>
          </Col>
        </Row>
      </Col>
      <Col lg={4}>
        <h3><small>I need more FitMachines...</small></h3>
        <Row>
          <Col xs={{ span: 6, offset: 3 }} lg={{ span: 12, offset: 0 }}>
            <DisplayImage>
              <img src={fmImage} alt="MOVUS FitMachine"/>
            </DisplayImage>
          </Col>
        </Row>
        <ContactSupportLink
          Component={Button}
          variant="primary"
          subject="I need more FitMachines"
        >
          Request more FitMachines
        </ContactSupportLink>
      </Col>
    </Row>
  );
}

export default function AddFitMachineModal({ children }) {
  return (
    <BasicModal
      size="xl"
      className="modal-with-borders modal-with-borders--xl"
      header="Adding FitMachines is easy"
      body={Body}
    >
      {children}
    </BasicModal>
  );
}

import { useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import { useGroupConditionTrend } from '../../modules/organisation/hooks/useGroupStats';
import HomeCard from './HomeCard';
import { getChartLabelColor } from '../lib/utils';
import useElementWidth from '../../hooks/useElementWidth';
import useDeviceStatusFilter from '../../modules/equipment/hooks/useDeviceStatusFilter';

const SM_BREAKDOWN = 510;
const XS_BREAKDOWN = 428;

function ConditionTrend() {
  const conditionTrend = useGroupConditionTrend();
  const cardRef = useRef(null);
  const width = useElementWidth(cardRef.current);
  const { filterAll } = useDeviceStatusFilter();

  const chartOptions = {
    style: width <= XS_BREAKDOWN ? {
      height: '385px'
    } : {},
    legend: width <= SM_BREAKDOWN ? {
      textStyle: {
        fontSize: 10,
      },
      bottom: -5,
    } : {
      textStyle: {
        fontSize: 12,
      },
      bottom: -5,
    },
    gridBottom: width <= XS_BREAKDOWN ? '30%' : '25%',
  };

  if(!conditionTrend) return null;

  const { items = [], _state_: {loading, error} = {} } = conditionTrend || {};
  const itemsSortedByDate = items.sort((a, b) => {
    return a.date < b.date ? -1 : 1;
  });

  return (
    <div ref={cardRef}>
      <HomeCard
        header="Equipment condition trend"
        headerLink={{ to: '/equipment', text: 'All Equipment', onClick: filterAll }}
        loading={loading}
        error={error}
      >
        <ReactEcharts
          style={chartOptions.style}
          option={{
            tooltip: {
              trigger: 'axis',
            },
            legend: chartOptions.legend,
            xAxis: [{
              data: itemsSortedByDate.map(i => moment(i.date).format('MMM-YY'))
            }],
            yAxis: [{
              type: 'value'
            }],
            grid: {
              top: 10,
              left: 10,
              right: 5,
              bottom: chartOptions.gridBottom,
              containLabel: true,
            },
            color: ['green', 'yellow', 'orange', 'red'].map(color => getChartLabelColor(color)),
            series: [{
              name: 'Healthy Equipment',
              type: 'bar',
              stack: 'one',
              data: itemsSortedByDate.map(i => i.healthy),
            }, {
              name: 'Equipment with valid events',
              type: 'bar',
              stack: 'one',
              data: itemsSortedByDate.map(i => i.with_valid),
            }, {
              name: 'Equipment with acknowledged events',
              type: 'bar',
              stack: 'one',
              data: itemsSortedByDate.map(i => i.with_ack),
            }, {
              name: 'Equipment with new events',
              type: 'bar',
              stack: 'one',
              data: itemsSortedByDate.map(i => i.with_new),
            }]
          }}
        />
      </HomeCard>
    </div>
  );
}

export default ConditionTrend;
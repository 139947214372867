import { useCallback, useMemo, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Row, Col, Form, Button } from 'react-bootstrap';
import { FaSignal } from 'react-icons/fa';
import { IoIosCog, IoIosArrowRoundForward, } from 'react-icons/io';
import moment from 'moment';
import useBaseChart, { defaultOptions, getSymbolOfIcon } from "./BaseChart";
import { convertToTimestamp } from './BaseChartToolbar';
import BaseChartEditToolbar from './BaseChartEditToolbar';
import ConfirmModal from '../ConfirmModal';
import { formatNumber } from '../lib/utils';
import {
  fetchDeviceNotificationThresholds,
  saveDeviceNotificationThreshold,
  deleteDeviceNotificationThreshold,
} from '../../modules/equipment/actions';
import { getDeviceNotificationThresholds } from '../../modules/equipment/selectors';
import { getCurrentOrganisationHasProductCode } from '../../modules/organisation/selectors';
import {
  StatusBadge,
  iconSize,
  clipValue,
  whiteNormalPointBackground,
  whiteAlarmPointBackground,
  thresholdNormalLine,
  thresholdAlarmLine,
  thresholdNormalArea,
  thresholdAlarmArea,
  thresholdNormalPoint,
  thresholdAlarmPoint,
  thresholdAlarmLineDisabled,
  thresholdAlarmPointDisabled,
  thresholdHighAlarmLine,
  thresholdHighAlarmPoint,
} from './MeasuredDataChart';
import { getFormattedValue } from '../values/utils/displayUtils.js';
import useDeviceAxisOptions from '../../modules/equipment/hooks/useDeviceAxisOptions';
import { FPAxisOptionsForm } from '../../modules/equipment/components/AxisOptionsForm';

const IoIosArrowRoundForwardPath = getSymbolOfIcon(IoIosArrowRoundForward);
const colours = {
  black: '#434348', // black
  blue: '#7cb5ec', // blue
};

function getInitialOptions(options = defaultOptions) {
  return {
    ...options,
    grid: {
      ...options.grid,
      right: 50,
    },
    tooltip: {
      ...options.tooltip,
      formatter: function(series) {
        const current = series.find(({ seriesIndex }) => seriesIndex === 0);
        const sensorValue = series.find(({ seriesIndex }) => seriesIndex === 1);
        return (
          `${moment(series[0].value[0]).format('LLLL')}${
            '<hr style="margin:2px 0 5px;border-top-color: #ccc;"/>'
          } ${current?.value[1] !== undefined ? `Current:  ${formatNumber(current.value[1])} A<br />` : '' }
          ${sensorValue ? `Sensor Value: ${formatNumber(sensorValue.value[1])}` : ''}
          `
        );
      }
    },
    yAxis: [
      {
        id: 'current',
        type: 'value',
        name: 'Current (A)',
        nameLocation: 'center',
        nameRotate: 90,
        nameGap: 35,
        nameTextStyle: {
          color: colours.black,
        }
      },
      {
        id: 'relcurrent',
        type: 'value',
        name: 'Sensor Value',
        nameLocation: 'center',
        nameRotate: 90,
        nameGap: 20,
        nameTextStyle: {
          color: colours.blue,
        },
        axisLabel: {
          show: false,
        }
      }
    ],
    series: [
      {
        id: 'current',
        yAxisId: 'current',
        name: 'Current',
        type: 'line',
        smooth: 0.25,
        showSymbol: false,
        lineStyle: { width: 1.5, color: colours.black },
        markPoint: {
          symbol: IoIosArrowRoundForwardPath,
          // rotate the icon as needed dynamically
          symbolRotate: 0,
          symbolSize: iconSize,
          itemStyle: {
            color: colours.red,
          },
        },
      },
      {
        id: 'relcurrent',
        yAxisId: 'relcurrent',
        name: 'Sensor Value',
        type: 'line',
        smooth: 0.25,
        showSymbol: false,
        lineStyle: { width: 1.5, color: colours.blue },
        markPoint: {
          symbol: IoIosArrowRoundForwardPath,
          // rotate the icon as needed dynamically
          symbolRotate: 0,
          symbolSize: iconSize,
          itemStyle: {
            color: colours.red,
          },
        },
      }
    ],
    color: [colours.black, colours.blue],
  };
}

// FitPower Measured Data chart.
function FitpowerMeasuredData({
  deviceId,
  samples = [],
  measuredDataDateRange: dateRange,
  setMeasuredDataDateRange: setDateRange,
  ...props
}) {
  const dispatch = useDispatch();
  const [BaseChart, { getChart, useChartUpdateEffect, useChartUpdateOnCustomEvent }] = useBaseChart(getInitialOptions);
  const deviceNotificationThresholds = useSelector(state => getDeviceNotificationThresholds(state, deviceId)) || [];
  const hasHighAlarm = useSelector(state => getCurrentOrganisationHasProductCode(state, 'highalarm'));
  const [editNotificationThresholdValue, setEditNotificationThresholdValue] = useState();
  const [editNotificationThresholdMode, setEditNotificationThresholdMode] = useState(false);
  const exitNotificationThresholdMode = useCallback(() => setEditNotificationThresholdMode(false), []);
  const fitPowerDeviceNotificationThresholds = useMemo(() => {
    return deviceNotificationThresholds.filter(({attribute}) => attribute === 'current' || attribute === 'relcurrent');
  }, [deviceNotificationThresholds]);

  useEffect(exitNotificationThresholdMode, [deviceId]);
  useEffect(() => {
    setEditNotificationThresholdMode(Boolean(editNotificationThresholdValue));
  }, [editNotificationThresholdValue]);

  useEffect(() => {
    if(!editNotificationThresholdMode) {
      setEditNotificationThresholdValue();
    }
  }, [editNotificationThresholdMode]);

  useEffect(() => {
    setEditNotificationThresholdValue(threshold => {
      const { alarm_value, normal_value } = threshold || {};
      if(alarm_value < 0 || normal_value < 0) {
        return {
          ...threshold,
          alarm_value: alarm_value > 0 ? alarm_value : 0,
          normal_value: normal_value !== undefined ? (normal_value > 0 ? normal_value : 0) : undefined,
        };
      }
      return threshold;
    });
  }, [editNotificationThresholdValue]);

  useEffect(() => {
    if(deviceId) dispatch(fetchDeviceNotificationThresholds({ id: deviceId }));
  }, [deviceId]);

  const [axisOptions, setAxisOptions] = useState({});
  const hasCustomAxisOptions = Object.keys(axisOptions).length > 0;
  const [axisOptionsMode, setAxisOptionsMode] = useState(false);
  const toggleAxisOptionsMode = useCallback(() => setAxisOptionsMode(!axisOptionsMode), [setAxisOptionsMode, axisOptionsMode]);
  const exitSetAxisOptionsMode = useCallback(() => setAxisOptionsMode(false), []);
  const { axisOptions: userAxisOptions } = useDeviceAxisOptions(deviceId, { fetch: true });
  useEffect(() => {
    if(userAxisOptions) {
      setAxisOptions(userAxisOptions);
    }
  }, [userAxisOptions]);

  const thresholdExtents = useMemo(() => {
    const { attribute, alarm_value, normal_value } = editNotificationThresholdValue || {};
    return !attribute ? {} : {
      [attribute]: [
        // 10% either way (works for negative or positive values)
        alarm_value * 0.9,
        alarm_value * 1.1,
        normal_value * 0.9,
        normal_value * 1.1,
      ].filter(v => !isNaN(v))
    }; // filter out NaN values
  }, [editNotificationThresholdValue]);

  const minCurrent = useMemo(() => {
    return isNaN(axisOptions.minCurrent) ? 0 : axisOptions.minCurrent;
  }, [axisOptions.minCurrent]);

  const maxCurrent = useMemo(() => {
    if(!isNaN(axisOptions.maxCurrent)) return axisOptions.maxCurrent;
    const current = Math.max(
      ...samples.map(s => s.measured_current),
      ...thresholdExtents['current'] || [],
      ...fitPowerDeviceNotificationThresholds
        .filter(({ attribute }) => attribute === 'current')
        .map(({ alarm_value }) => alarm_value)
    );
    return Math.ceil(current);
  }, [samples, thresholdExtents, axisOptions.maxCurrent]);
  const hasCurrentData = !isNaN(maxCurrent) && isFinite(maxCurrent);

  const minRelativeCurrent = 0;
  const maxRelativeCurrent = useMemo(() => {
    const current = Math.max(
      ...samples.map(s => s.relative_current || 0),
      ...thresholdExtents['relcurrent'] || [],
      ...fitPowerDeviceNotificationThresholds
        .filter(({ attribute }) => attribute === 'relcurrent')
        .map(({ alarm_value }) => alarm_value)
    );
    return Math.ceil(current);
  }, [samples, thresholdExtents, fitPowerDeviceNotificationThresholds]);

  const updateSamples = useCallback(() => {
    return {
      legend: {
        data: hasCurrentData ? [{
          name: 'Current'
        }, {
          name: 'Sensor Value'
        }] : [{
          name: 'Sensor Value'
        }],
        selected: {
          'Current': hasCurrentData,
        },
        show: !!samples.length,
      },
      yAxis: [
        {
          id: 'current',
          min: minCurrent,
          max: maxCurrent,
          show: !!samples.length && hasCurrentData,
        },
        {
          id: 'relcurrent',
          min: minRelativeCurrent,
          max: maxRelativeCurrent,
          show: !!samples.length,
        }
      ],
      series: [
        {
          id: 'current',
          yAxisId: 'current',
          data: samples.map(sample => [
            convertToTimestamp(sample.timestamp),
            sample.measured_current,
          ])
        },
        {
          id: 'relcurrent',
          yAxisId: 'relcurrent',
          data: samples.map(sample => [
            convertToTimestamp(sample.timestamp),
            sample.relative_current,
          ])
        }
      ],
      dataZoom: {
        realtime: false,
        throttle: 0,
      }
    };
  }, [
    samples,
    minCurrent,
    maxCurrent,
    minRelativeCurrent,
    maxRelativeCurrent,
    hasCurrentData
  ]);
  useChartUpdateEffect(updateSamples);

  const getXMinPoint = (chart=getChart()) => {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        return grid.left;
      }
    }
  };

  const getXMaxPoint = (chart=getChart()) => {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        return chart.getWidth() - grid.right;
      }
    }
  };

  const attributeSeriesIdsByAttribute = useMemo(() => {
    return {
      'current': ['current'],
      'relcurrent': ['relcurrent'],
    };
  }, []);

  const getXMidPoint = (chart=getChart()) => {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        // position x directly in the middle of the chart (average of left and right pixel)
        return (grid.left + chart.getWidth() - grid.right) / 2;
      }
    }
  };

  const getYFromData = (dataY, seriesId='running_cutoff', chart=getChart()) => {
    if (chart) {
      // position y as the current device Running Cutoff
      return chart.convertToPixel({ seriesId }, [0, dataY])[1];
    }
  };

  const convertNotificationThresholdFromPixel = (pixel, seriesId, chart = getChart()) => {
    if (chart) {
      const point = chart.convertFromPixel({ seriesId }, pixel);
      // round the number a bit
      const value = Math.round(1000 * point[1])/1000;

      if(seriesId === 'current') {
        if(!isNaN(axisOptions.maxCurrent) && value > axisOptions.maxCurrent) return axisOptions.maxCurrent;
        if(!isNaN(axisOptions.minCurrent) && value < axisOptions.minCurrent) return axisOptions.minCurrent;
      }
      return value;
    }
  };

  const updateThresholdSeries = useCallback((additions={}) => {
    const {
      attribute,
      alarm_value,
      // if normal value is undefined, set to alarm value
      normal_value = alarm_value,
      alarm_comparison = 'gt',
      normal_comparison = alarm_comparison === 'gt' ? 'lt' : 'gt',
    } = { ...editNotificationThresholdValue, ...additions };

    const allSeriesIds = Object.values(attributeSeriesIdsByAttribute).flat();
    const theseSeriesIds = attributeSeriesIdsByAttribute[attribute] || [];
    const otherSeriesIds = allSeriesIds.filter(v => !theseSeriesIds.includes(v));

    return {
      series: [
        // clear other attributes
        ...otherSeriesIds.map(id => ({
          id,
          markArea: { data: [] },
          markLine: { data: [] },
          markPoint: { data: [] },
        })),
        // draw this attribute
        ...theseSeriesIds.map(id => ({
          id,
          markArea: {
            silent: true,
            data: [
              // draw normal area
              normal_comparison === 'gt' ? [
                // mark above a line
                { yAxis: normal_value, ...thresholdNormalArea },
                { yAxis: Infinity },
              ] : [
                // mark below a line
                { yAxis: -Infinity, ...thresholdNormalArea },
                { yAxis: normal_value },
              ],
              // draw alarm area
              alarm_comparison === 'gt' ? [
                // mark above a line
                { yAxis: alarm_value, ...thresholdAlarmArea },
                { yAxis: Infinity },
              ] : [
                // mark below a line
                { yAxis: -Infinity, ...thresholdAlarmArea },
                { yAxis: alarm_value },
              ],
            ],
          },
          markLine: {
            silent: true,
            data: [[
              // draw normal line
              { x: getXMinPoint(), yAxis: normal_value, ...thresholdNormalLine },
              { x: getXMaxPoint(), yAxis: normal_value, ...thresholdNormalLine },
            ], [
              // draw alarm line
              { x: getXMinPoint(), yAxis: alarm_value, ...thresholdAlarmLine },
              { x: getXMaxPoint(), yAxis: alarm_value, ...thresholdAlarmLine },
            ]],
          },
          markPoint: {
            silent: true,
            data: [
              // draw normal point
              { x: getXMidPoint() + iconSize, yAxis: normal_value, ...whiteNormalPointBackground },
              { x: getXMidPoint() + iconSize, yAxis: normal_value,
                symbolRotate: normal_comparison === 'gt' ? 90 : -90,
                symbolOffset: [0, 0],
                ...thresholdNormalPoint,
              },
              // draw alarm point
              { x: getXMidPoint() - iconSize, yAxis: alarm_value, ...whiteAlarmPointBackground },
              { x: getXMidPoint() - iconSize, yAxis: alarm_value,
                symbolRotate: alarm_comparison === 'gt' ? 90 : -90,
                symbolOffset: [0, 0],
                ...thresholdAlarmPoint,
              },
            ],
          },
        })),
      ],
    };
  }, [
    editNotificationThresholdValue,
    attributeSeriesIdsByAttribute,
  ]);
  useChartUpdateEffect(updateThresholdSeries);

  const updateDraggablePoints = useCallback(() => {
    const normalIsCustomised = editNotificationThresholdValue && editNotificationThresholdValue.normal_value;
    const {
      attribute,
      alarm_value,
      normal_value = alarm_value,
      alarm_comparison,
      normal_comparison,
    } = {...editNotificationThresholdValue};
    const [attributeSeriesId] = attributeSeriesIdsByAttribute[attribute] || [];

    return {
      graphic: {
        elements: [
          editNotificationThresholdValue ? {
            id: 'new_notification_threshold_normal',
            type: 'circle',
            position: [
              getXMidPoint() + iconSize,
              getYFromData(normal_value, attributeSeriesId),
            ],
            shape: { cx: 0, cy: 0, r: iconSize },
            z: 100,
            invisible: true,
            draggable: true,
            ondrag: ({ offsetX, offsetY }) => {
              const chart = getChart();
              // only update if the drag point is still on the graph
              if (chart) {
                const newNotificationThresholdValue = clipValue(
                  convertNotificationThresholdFromPixel([offsetX, offsetY], attributeSeriesId),
                  // clip to above or below alarm
                  normal_comparison === 'gt'
                    ? alarm_value
                      ? [alarm_value]
                      : []
                    : alarm_value
                      ? [undefined, alarm_value]
                      : [],
                );
                chart.setOption(updateThresholdSeries({ normal_value: newNotificationThresholdValue }));
              }
            },
            ondragend: ({ offsetX, offsetY }) => {
              const newNotificationThresholdValue = clipValue(
                convertNotificationThresholdFromPixel([offsetX, offsetY], attributeSeriesId),
                // clip to above or below alarm
                normal_comparison === 'gt'
                  ? alarm_value
                    ? [alarm_value]
                    : []
                  : alarm_value
                    ? [undefined, alarm_value]
                    : [],
              );
              setEditNotificationThresholdValue(threshold => {
                return {
                  ...threshold,
                  // move thresholds together if values are identical
                  normal_value: newNotificationThresholdValue !== threshold.alarm_value
                    ? newNotificationThresholdValue
                    : undefined,
                };
              });
            },
          } : {
            id: 'new_notification_threshold_normal',
            $action: 'remove',
          },
          editNotificationThresholdValue ?
            {
              id: 'new_notification_threshold_alarm',
              type: 'circle',
              position: [
                getXMidPoint() - iconSize,
                getYFromData(alarm_value, attributeSeriesId),
              ],
              shape: { cx: 0, cy: 0, r: iconSize },
              z: 100,
              invisible: true,
              draggable: true,
              ondrag: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // only update if the drag point is still on the graph
                if (chart) {
                  const newNotificationThresholdValue = clipValue(
                    convertNotificationThresholdFromPixel([offsetX, offsetY], attributeSeriesId),
                    // clip to above or below normal
                    normalIsCustomised
                      ? alarm_comparison === 'gt' ? [normal_value] : [undefined, normal_value]
                      : [],
                  );
                  chart.setOption(updateThresholdSeries({ alarm_value: newNotificationThresholdValue }));
                }
              },
              ondragend: ({ offsetX, offsetY }) => {
                const newNotificationThresholdValue = clipValue(
                  convertNotificationThresholdFromPixel([offsetX, offsetY], attributeSeriesId),
                  // clip to above or below normal
                  normalIsCustomised
                    ? alarm_comparison === 'gt' ? [normal_value] : [undefined, normal_value]
                    : [],
                );
                setEditNotificationThresholdValue(threshold => {
                  return {
                    ...threshold,
                    alarm_value: newNotificationThresholdValue,
                    // move thresholds together if values are identical
                    normal_value: threshold.normal_value !== newNotificationThresholdValue
                      ? threshold.normal_value
                      : undefined,
                  };
                });
              },
            } :
            {
              // or remove
              id: 'new_notification_threshold_alarm',
              $action: 'remove',
            },
        ]
      }
    };
  }, [
    editNotificationThresholdValue,
    attributeSeriesIdsByAttribute,
    updateThresholdSeries,
    attributeSeriesIdsByAttribute,
  ]);
  useChartUpdateEffect(updateDraggablePoints);
  // useChartUpdateEffect(updateDraggablePoints, [samples]);

  const updateThresholdMarkerLines = useCallback(() => {
    if (
      !editNotificationThresholdValue
    ) {
      return {
        series: [
          ...attributeSeriesIdsByAttribute['current'].map(id => ({
            id,
            // mark alarm threshold indication line on axes
            markLine: {
              silent: true,
              data: deviceNotificationThresholds
                .filter(({ attribute }) => attribute === 'current')
                .map(({ alarm_value, enabled, high_alarm }) => {
                  const style = enabled ? (high_alarm ? thresholdHighAlarmLine : thresholdAlarmLine) : thresholdAlarmLineDisabled;
                  return [
                    // mark left of the right axes
                    { yAxis: alarm_value, x: getXMinPoint() + 25, ...style },
                    { yAxis: alarm_value, x: getXMinPoint() - 5, ...style },
                  ];
                }),
            },
            // mark alarm threshold indication arrow direction on axes
            markPoint: {
              silent: true,
              data: deviceNotificationThresholds
                .filter(({ attribute }) => attribute === 'current')
                .map(({ alarm_value, alarm_comparison, enabled, high_alarm }) => {
                  const style = enabled ? (high_alarm ? thresholdHighAlarmPoint : thresholdAlarmPoint) : thresholdAlarmPointDisabled;
                  // mark left of the right axes
                  const gt = alarm_comparison === 'gt';
                  return {
                    yAxis: alarm_value,
                    x: getXMinPoint() + 10,
                    ...style,
                    // point arrow in the right direction
                    symbolRotate: gt ? 90 : -90,
                    // nudge arrow a little in the right direction
                    symbolOffset: [0, gt ? -2.5 : 2.5],
                  };
                }),
            },
          })),
          ...attributeSeriesIdsByAttribute['relcurrent'].map(id => ({
            id,
            // mark alarm threshold indication line on axes
            markLine: {
              silent: true,
              data: deviceNotificationThresholds
                .filter(({ attribute }) => attribute === 'relcurrent')
                .map(({ alarm_value, enabled, high_alarm }) => {
                  const style = enabled ? (high_alarm ? thresholdHighAlarmLine : thresholdAlarmLine) : thresholdAlarmLineDisabled;
                  return [
                    { yAxis: alarm_value, x: getXMaxPoint() - 25, ...style },
                    { yAxis: alarm_value, x: getXMaxPoint() + 5, ...style },
                  ];
                }),
            },
            // mark alarm threshold indication arrow direction on axes
            markPoint: {
              silent: true,
              data: deviceNotificationThresholds
                .filter(({ attribute }) => attribute === 'relcurrent')
                .map(({ alarm_value, alarm_comparison, enabled, high_alarm }) => {
                  const style = enabled ? (high_alarm ? thresholdHighAlarmPoint : thresholdAlarmPoint) : thresholdAlarmPointDisabled;
                  // mark left of the right axes
                  const gt = alarm_comparison === 'gt';
                  return {
                    yAxis: alarm_value,
                    x: getXMaxPoint() - 10,
                    ...style,
                    // point arrow in the right direction
                    symbolRotate: gt ? 90 : -90,
                    // nudge arrow a little in the right direction
                    symbolOffset: [0, gt ? -2.5 : 2.5],
                  };
                }),
            },
          })),
        ],
      };
    }
  }, [
    deviceNotificationThresholds,
    editNotificationThresholdValue,
    attributeSeriesIdsByAttribute,
  ]);
  useChartUpdateEffect(updateThresholdMarkerLines);
  useChartUpdateOnCustomEvent('resize', updateThresholdMarkerLines);

  const saveThreshold = useCallback(() => {
    const {
      id,
      enabled,
      attribute,
      // default to greater than
      alarm_comparison = 'gt',
      alarm_value,
      // default to opposite of alarm comparison
      normal_comparison = alarm_comparison === 'gt' ? 'lt' : 'gt',
      normal_value,
      high_alarm,
    } = editNotificationThresholdValue || {};

    const payload = {
      id,
      enabled,
      attribute,
      alarm_comparison,
      alarm_value,
      high_alarm,
      // only set normal values if they are not the alarm value
      // this will allow the user to move both values together
      // which seems expected when presented with this
      ...normal_value !== alarm_value && {
        normal_comparison,
        normal_value,
      },
    };

    dispatch(saveDeviceNotificationThreshold({id: deviceId}, payload))
      .then(() => Promise.all([
        dispatch(fetchDeviceNotificationThresholds({id: deviceId}))
      ]))
      .then(exitNotificationThresholdMode);
  }, [
    deviceId,
    editNotificationThresholdValue,
    exitNotificationThresholdMode,
  ]);

  const deleteThreshold = useCallback(() => {
    dispatch(deleteDeviceNotificationThreshold({id: deviceId}, editNotificationThresholdValue))
      .then(() => Promise.all([
        dispatch(fetchDeviceNotificationThresholds({id: deviceId}))
      ]))
      .then(exitNotificationThresholdMode);
  }, [
    deviceId,
    editNotificationThresholdValue,
    exitNotificationThresholdMode,
  ]);

  const toolbarButtons = useMemo(() => [
    <Dropdown>
      <Dropdown.Toggle
        variant="outline-dark"
        size="lg"
        disabled={!samples.length}
      >
        <IoIosCog size="1.3em" title="Edit" />
      </Dropdown.Toggle>
      <Dropdown.Menu
        align="right"
      >
        {editNotificationThresholdMode ?
          <Dropdown.Item onClick={exitNotificationThresholdMode}>Cancel alarm threshold</Dropdown.Item> :
          axisOptionsMode ? <Dropdown.Item onClick={exitSetAxisOptionsMode}>Cancel edit current axis</Dropdown.Item> :
            <>
              {hasCurrentData && <Dropdown.Item
                onClick={() => {
                  setEditNotificationThresholdValue({
                    enabled: true,
                    attribute: 'current',
                    alarm_comparison: 'gt',
                    alarm_value: (minCurrent + 2 * maxCurrent) / 3,
                    high_alarm: false,
                  });
                }}
              >
                Add current alarm threshold
              </Dropdown.Item>}
              <Dropdown.Item
                onClick={() => {
                  setEditNotificationThresholdValue({
                    enabled: true,
                    attribute: 'relcurrent',
                    alarm_comparison: 'gt',
                    alarm_value: (minRelativeCurrent + 2 * maxRelativeCurrent) / 3,
                    high_alarm: false,
                  });
                }}
              >
                Add sensor value alarm threshold
              </Dropdown.Item>
              {hasCurrentData && <Dropdown.Item onClick={toggleAxisOptionsMode}>
                Edit current axis
              </Dropdown.Item>}
            </>}
      </Dropdown.Menu>
    </Dropdown>
  ], [
    editNotificationThresholdValue,
    editNotificationThresholdMode,
    maxCurrent,
    maxRelativeCurrent,
    samples.length,
    axisOptionsMode,
  ]);

  const thresholdSettings = useCallback(() => {
    return (
      <>
        {!editNotificationThresholdMode && !axisOptionsMode && fitPowerDeviceNotificationThresholds.length > 0 && <BaseChartEditToolbar className="pb-2">
          {fitPowerDeviceNotificationThresholds.sort((a = {}, b = {}) => {
            return (
              // sort by attribute ascending
              `${a.attribute}`.localeCompare(`${b.attribute}`)
            ) || (
              // then by alarm value ascending
              Number(a.alarm_value) - Number(b.alarm_value)
            ) || (
              // then by normal value ascending
              Number(a.alarm_value) - Number(b.alarm_value)
            );
          }).map((threshold={}) => (
            <Row className="small-gutters d-flex" key={threshold.id}>
              <Col className="mb-1" xs="auto">
                <StatusBadge alarmState={threshold.alarm_state} />
                <span className={threshold.high_alarm && hasHighAlarm ? 'font-weight-bold' : ''}>
                  {threshold.attribute === 'relcurrent' ? 'Relative' : 'Measured'}&nbsp;
                  {threshold.alarm_comparison === 'lt' ? `Low${threshold.high_alarm && hasHighAlarm ? '-Low ' : ' '}` : `High${threshold.high_alarm && hasHighAlarm ? '-High ' : ' '}`}
                  Current alarm {threshold.enabled !== true ? '(disabled) ' : ''}
                </span>
              </Col>
              <Col className="ml-auto" xs="auto">
                {threshold.attribute !== 'relcurrent' && <>
                  alarm {
                    threshold.alarm_comparison === 'gt' ? '>' : '<'
                  } {getFormattedValue(threshold.alarm_value, { units: ' A' })},&nbsp;
                  {threshold.normal_value === threshold.alarm_value ? 'no return to normal alert' : (
                    'normal ' +
                    (threshold.normal_comparison === 'gt' ? '> ' : '< ') + getFormattedValue(threshold.normal_value, { units: ' A' })
                  )}&nbsp;
                </>}
                <Button
                  size="xs"
                  onClick={() => {
                    // pick parts from threshold (don't use '_links' or 'title')
                    const {
                      id,
                      enabled,
                      attribute,
                      alarm_comparison,
                      alarm_value,
                      normal_comparison,
                      normal_value,
                      high_alarm,
                    } = threshold;

                    const payload = {
                      id,
                      enabled,
                      attribute,
                      alarm_comparison,
                      alarm_value,
                      // only set normal values if they are not the alarm value
                      // this will allow the user to move both values together
                      // which seems expected when presented with this
                      ...normal_value !== alarm_value && {
                        normal_comparison,
                        normal_value,
                      },
                      high_alarm,
                    };

                    // start editing a clone of this threshold
                    setEditNotificationThresholdValue(payload);
                  }}
                >Edit</Button>
              </Col>
            </Row>
          ))}
        </BaseChartEditToolbar>}
        {!editNotificationThresholdMode && !axisOptionsMode && hasCustomAxisOptions &&
          <BaseChartEditToolbar variant="danger">
            <p className="font-weight-bold">
              Your settings to current axis
            </p>
            <Row>
              <Col sm="6">
                <div>Current axis options</div>
                <div>Maximum: <span className="font-weight-bold">{axisOptions.maxCurrent === undefined ? 'auto' : axisOptions.maxCurrent + ' A'}</span></div>
                <div>Minimum: <span className="font-weight-bold">{axisOptions.minCurrent === undefined ? 'auto' : axisOptions.minCurrent + ' A'}</span></div>
              </Col>
            </Row>
          </BaseChartEditToolbar>
        }
        {editNotificationThresholdValue && (
          <>
            <BaseChartEditToolbar variant="danger" className="pb-2">
              <Row className="small-gutters d-flex">
                <Col className="mb-1">
                  <Row className="small-gutters d-flex">
                    <Col className="mb-1" xs="auto">
                      { editNotificationThresholdValue.attribute === 'relcurrent' ? 'Sensor value' : 'Current' } alarm notification
                    </Col>
                    <Col className="mb-1" xs="auto">
                      Alarm notifications will be sent to users that normally
                      receive alert emails for this equipment.
                      <p>
                        <strong>
                          When the alarm and normal settings are the same, no alert will be sent when the monitored value returns to normal.
                        </strong>
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs="auto" className="ml-auto">
                  <Form.Group className="mb-0">
                    <Form.Check>
                      <Form.Check.Input
                        type="checkbox"
                        id="alarm-notification-enable"
                        checked={editNotificationThresholdValue.enabled}
                        onChange={() => {
                          setEditNotificationThresholdValue(({ enabled, ...threshold }) => {
                            return {
                              ...threshold,
                              enabled: !enabled,
                            };
                          });
                        }}
                      >
                      </Form.Check.Input>
                      <Form.Check.Label htmlFor="alarm-notification-enable">enabled</Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                </Col>
              </Row>
            </BaseChartEditToolbar>
            {editNotificationThresholdValue && hasHighAlarm && (
              <BaseChartEditToolbar variant="dark" className="pb-2">
                <Row className="small-gutters d-flex">
                  <Col className="mb-1">
                    <div className="mb-1">{editNotificationThresholdValue.alarm_comparison === 'lt' ? 'Low-Low' : 'High-high'} alarm notification</div>
                    <div className="mb-1">{editNotificationThresholdValue.alarm_comparison === 'lt' ? 'Low-Low' : 'High-high'} alarm notifications
                      will be sent to all users subscribed to the alarm channel and those subscribed to escalations.
                      You can <a
                      href={`/devices/${deviceId}/users`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >check this list</a> to edit these permissions.
                    </div>
                    <p>
                      <strong>
                        This alarm is optional and should only be activated if a machine is anticipated to degrade rapidly and a delayed response time could be fatal.
                      </strong>
                    </p>
                  </Col>
                  <Col className="mb-1" xs="auto">
                    <Form.Group className="mb-0">
                      <Form.Check
                        id="alarm-notification-emergency"
                        type="checkbox"
                        label="enabled"
                        checked={editNotificationThresholdValue.high_alarm}
                        onChange={() => {
                          setEditNotificationThresholdValue(({ high_alarm, ...threshold }) => {
                            return {
                              ...threshold,
                              high_alarm: !high_alarm,
                            };
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </BaseChartEditToolbar>
            )}
            <BaseChartEditToolbar className="pb-2">
              <Row className="small-gutters d-flex pb-2">
                <Col className="mb-1">
                  Drag the red slider to adjust when alarm notifications are triggered
                </Col>
                <Col className="ml-auto" xs="auto">
                  {editNotificationThresholdValue.attribute !== 'relcurrent' && <div className="text-right">
                    {editNotificationThresholdValue.alarm_comparison === 'gt' ? '>' : '<'}
                    {getFormattedValue(editNotificationThresholdValue.alarm_value, {units: ' A'})}
                  </div>}
                  <div className="text-right">
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => setEditNotificationThresholdValue(threshold => {
                        const alarm_comparison = threshold.alarm_comparison === 'gt'
                          ? 'lt'
                          : 'gt';
                        const normal_comparison = alarm_comparison === 'gt'
                          ? 'lt'
                          : 'gt';
                        return {
                          ...threshold,
                          alarm_comparison,
                          alarm_value: threshold.normal_value !== undefined
                            ? threshold.normal_value
                            : threshold.alarm_value,
                          normal_comparison,
                          normal_value: threshold.normal_value !== undefined
                            ? threshold.alarm_value
                            : undefined,
                        };
                      })}
                    >
                      Switch direction
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="small-gutters d-flex pb-2">
                <Col className="mb-1">
                  {editNotificationThresholdValue.normal_value ?
                    'Drag the blue slide to adjust when \'back to normal\' notifications are triggered.' :
                    '\'Back to normal\' notifications are triggered at:'
                  }
                </Col>
                <Col className="ml-auto" xs="auto">
                  {editNotificationThresholdValue.attribute !== 'relcurrent' && <div className="text-right">
                    {
                      editNotificationThresholdValue.normal_value === undefined ?
                        editNotificationThresholdValue.alarm_comparison === 'gt' ? '<' : '>' :
                        editNotificationThresholdValue.normal_comparison === 'gt' ? '>' : '<'
                    }
                    {getFormattedValue(editNotificationThresholdValue.normal_value || editNotificationThresholdValue.alarm_value, {units: ' A'})}
                  </div>}
                  <div className="text-right">
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => setEditNotificationThresholdValue(threshold => {
                        // remove normal
                        if (threshold.normal_value !== undefined) {
                          return {
                            ...threshold,
                            normal_comparison: undefined,
                            normal_value: undefined,
                          };
                        }
                        // create normal less than
                        else if (threshold.alarm_comparison === 'gt') {
                          return {
                            ...threshold,
                            normal_comparison: 'lt',
                            normal_value: threshold.alarm_value > 0
                              ? threshold.alarm_value / 1.1
                              : threshold.alarm_value * 1.1,
                          };
                        }
                        // create normal greater than
                        else if (threshold.alarm_comparison === 'lt') {
                          return {
                            ...threshold,
                            normal_comparison: 'gt',
                            normal_value: threshold.alarm_value > 0
                              ? threshold.alarm_value * 1.1
                              : threshold.alarm_value / 1.1,
                          };
                        }
                        return threshold;
                      })}
                    >{editNotificationThresholdValue.normal_value === undefined ? 'Custom' : 'Reset'} normal notification</Button>
                  </div>
                </Col>
              </Row>
              <Row className="small-gutters d-flex align-items-center justify-content-end">
                <Col xs="auto" className="ml-auto">
                  <Button
                    size="sm"
                    onClick={saveThreshold}
                  >Save</Button>
                  {editNotificationThresholdValue.id &&
                    <ConfirmModal
                      body="Alternatively if you plan to use it again later, you can just disable (not delete) an alarm notification"
                      confirmText="Delete alarm notification"
                    >
                      <Button
                        variant="danger"
                        size="sm"
                        className="ml-1"
                        onClick={deleteThreshold}
                      >Delete</Button>
                    </ConfirmModal>
                  }
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    className="ml-1"
                    onClick={exitNotificationThresholdMode}
                  >Cancel</Button>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          </>
        )}
        {axisOptionsMode && (
          <>
            <BaseChartEditToolbar variant="danger">
              <p className="font-weight-bold">Edit Current axis</p>
              <p>Changing the axis settings will fix the scale to the values set in the fields below.&nbsp;
                <span className="font-weight-bold">Auto</span> indicates that the axis value is set to scale automatically.
              </p>
              <p className="font-weight-bold">When the axis settings are manually set, the value will remain fixed irrespective of the values of
                the data which may lead to values being off the chart.
              </p>
            </BaseChartEditToolbar>
            <BaseChartEditToolbar>
              <FPAxisOptionsForm
                axisOptions={axisOptions}
                onUpdateAxisOptions={data => setAxisOptions(data)}
                onCancel={exitSetAxisOptionsMode}
                deviceId={deviceId}
              />
            </BaseChartEditToolbar>
          </>
        )}
      </>
    );
  }, [
    deviceId,
    deviceNotificationThresholds,
    editNotificationThresholdValue,
    editNotificationThresholdMode,
    saveThreshold,
    deleteThreshold,
    axisOptionsMode,
    axisOptions,
  ]);

  const maximumViewedDateRange = useMemo(() => {
    // Use the samples boundary, might be changed.
    return {
      startTime: Date.parse(samples[0]?.timestamp || 0),
      endTime: Date.parse(samples[samples.length - 1]?.timestamp || 0),
    };
  }, [samples.length]);

  return (
    <>
      <BaseChart
        header={<><FaSignal /> Measured Data</>}
        namespace="fitpower-measured-data"
        samples={samples}
        deviceId={deviceId}
        {...props}
        dateRange={dateRange}
        setDateRange={setDateRange}
        toolbarButtons = {toolbarButtons}
        AboveChart={thresholdSettings}
        maximumViewedDateRange={maximumViewedDateRange}
      />
    </>
  );
}

export default FitpowerMeasuredData;
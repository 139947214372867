import { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { fetchDeviceImpactSummary } from '../actions';
import { getDeviceImpactSummary, getDeviceImpactSummaryState } from '../selectors';
import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';
import EquipmentPageLayout from '../components/EquipmentPageLayout';
import { alarmTime, reason, lostHours, avoidedHours, getEquipmentEventCostsColumn, getEquipmentAvoidedCostsColumn, getEquipmentTotalReductionColumn } from '../columns';
import useLocale from '../../../hooks/useLocale';

export default function EquipmentImpactReport() {
  const { id } = useParams();
  const deviceId = Number(id);
  const dispatch = useDispatch();
  const { loading, error } = useSelector(state => getDeviceImpactSummaryState(state, deviceId) || {});
  const impactSummary = useSelector(state => getDeviceImpactSummary(state, deviceId) || []);
  const { currency } = useLocale({ deviceId });

  const columns = useMemo(() => [
    alarmTime,
    reason,
    lostHours,
    avoidedHours,
    getEquipmentEventCostsColumn(currency),
    getEquipmentAvoidedCostsColumn(currency),
    getEquipmentTotalReductionColumn(currency)
  ], [currency]);

  useEffect(() => {
    dispatch(fetchDeviceImpactSummary({id: deviceId}));
  }, [deviceId]);

  const renderHeader = useCallback(props => {
    return (
      <TableToolbar
        title="Event Impact Summary"
        loading={loading}
        error={error}
        tableProps={props}
      />
    );
  }, [loading, error]);

  return (
    <EquipmentPageLayout>
      <div className="my-4">
        <Table
          keyField="alarm_id"
          renderHeader={renderHeader}
          noDataIndication={() => 'No event impact summary data.'}
          data={impactSummary}
          loading={loading}
          columns={columns}
          defaultSorted={[{
            dataField: 'alarm_time',
            order: 'desc'
          }]}
        />
      </div>
    </EquipmentPageLayout>
  );
}
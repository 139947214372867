
import React, { useState, useEffect, useCallback } from 'react';
import Map from '../Map';

const defaultZoom = [16];

export default function LocationInput(props) {

  const {
    latitude,
    longitude,
    latitudeName = 'latitude',
    longitudeName = 'longitude',
    onChangeLocation,
  } = props;

  // start value from defaultValues
  const [state, setState] = useState({ latitude, longitude });

  // update value if new defaultValues are given
  useEffect(() => {
    if(latitude > -90 && latitude < 90 && longitude > -180 && longitude < 180) {
      setState({ latitude, longitude });
    }
  }, [latitude, longitude]);

  // set value if changed by the user
  const onChange = useCallback((map, e) => {
    e.preventDefault();
    setState({
      latitude: e.lngLat.lat,
      longitude: e.lngLat.lng,
    });
    if(typeof onChangeLocation === 'function') {
      onChangeLocation(e.lngLat.lat, e.lngLat.lng);
    }
  }, [setState]);

  return state.latitude !== undefined && state.longitude !== undefined ? (
    <div>
      <div style={{ height: 400 }}>
        <Map
          points={[state]}
          zoom={defaultZoom}
          onClick={onChange}
        />
      </div>
      <div className="mt-1">
        You can set the location by clicking on the above map.
      </div>
      <input type="hidden" name={latitudeName} value={state.latitude} />
      <input type="hidden" name={longitudeName} value={state.longitude} />
    </div>
  ) : null;
}

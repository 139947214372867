import { useCallback } from 'react';
import copy from 'copy-to-clipboard';
import { Button } from 'react-bootstrap';
import { IoIosCopy } from 'react-icons/io';
import { addToast } from '../../../components/Toaster';

function CopyDeviceSerialButton({ device }) {

  const copySerial = useCallback(() => {
    const result = copy(device.serial, { message: 'Press #{key} to copy' });
    if (result) {
      addToast({ variant: 'success', header: 'Copied to clipboard' });
    }
  }, [device.serial]);

  if(!device?.serial) return null;

  return (
    <Button
      size="sm"
      variant="outline-secondary"
      className="btn-xs btn-muted"
      onClick={copySerial}
    >
      <IoIosCopy /> Copy
    </Button>
  );
}

export default CopyDeviceSerialButton;
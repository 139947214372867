
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Card, Col, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@blueprintjs/core';

import Bar from '../../../components/Bar';
import VolumeImage from '../../alarm/VolumeImage';
import ToneImage from '../../alarm/ToneImage';
import TemperatureImage from '../../alarm/TemperatureImage';
import AlarmFFTModal from '../../alarm/components/AlarmFFTModal';
import AlarmSoundButton from '../../alarm/components/AlarmSoundButton';
import { FitMachineActivityCard } from '../components/DeviceCard';

import DeviceAlarmStatus from './DeviceAlarmStatus'; // - New event status workflow.
import DeviceAlarmEvents from './DeviceAlarmEvents';
import ConfirmModal from '../../../components/ConfirmModal';

import { fetchDeviceAlarms } from '../actions';
import { fetchAlarmEvents, deleteAlarm, fetchAlarms } from '../../alarm/actions';
import { getAlarm, getAlarmDevice } from '../../alarm/selectors';
import { getUserId, isAdmin } from '../../user/selectors';
import { getDeviceHasProductCode } from '../selectors';
import { useTrackDispatch } from '../../app/hooks';
import { getGroupTree } from '../../organisation/selectors';
import { selectActiveGroup } from '../../organisation/actions';
import { getSensorName } from '../utils';
import './deviceAlarmCard.scss';

function AlarmImpactButton({ alarmId }) {
  if(!alarmId) return null;
  return <Link to={`/alarms/${alarmId}/impact`} className="btn btn-primary">Event Impact</Link>;
}

function FitMachineConditionAlarmCard({ alarm={}, device={} }) {
  const isAlarmImpactAvailable = (alarm.was_current || alarm.is_current || alarm.is_legacy) && alarm.alarm_status !== 'new';
  const trackDispatch = useTrackDispatch();
  const sensorName = getSensorName(device);
  return (
    <FitMachineActivityCard
      alarm={alarm}
      deviceId={alarm.device_id}
      className={`fitmachine-alarm-card no-footer-padding`}
      beforeFooter={<DeviceAlarmStatus alarmId={alarm.id} />}
      footer={<DeviceAlarmEvents alarmId={alarm.id} />}
      sideButton={isAlarmImpactAvailable && <AlarmImpactButton alarmId={alarm.id} />}
    >
      <Card.Text as="div">
        <Row>
          {alarm.alarm_type === 'red' && (
            // red alarm
            <Col xs="auto" className="flow-col-md flex-grow-1 mb-3">
              <p className="mb-1">
                {sensorName} has detected that <Link to={`/equipment/${alarm.device_id}`}>
                  {device.equipment_name}
                </Link> is showing a major deviation from its normal operating behaviour.
                We recommend you review the details of this deviation on the MachineCloud
                and inspect the equipment.
              </p>
            </Col>
          )}
          {alarm.alarm_type === 'yellow' && (
            // yellow alarm
            <Col xs="auto" className="flow-col-md flex-grow-1 mb-3">
              <p className="mb-1">
                {sensorName} has detected that <Link to={`/equipment/${alarm.device_id}`}>
                  {device.equipment_name}
                </Link> is showing a minor deviation from its normal operating behaviour.
                We recommend you review the details of this deviation on the MachineCloud.
              </p>
            </Col>
          )}
          <Col xs="auto" className="flow-col-lg flex-grow-1">
            <Row>
              <Col xs="auto" className="flow-col-xs flex-grow-1 mb-3">
                <p className="title mb-0">Condition</p>
                <div className="mt-2 mb-1">
                  <Bar conditionValue={alarm.condition_overall} />
                </div>
              </Col>
              <Col xs="auto" className="flow-col-sm flex-grow-1">
                <Row className="small-gutters">
                  <Col xs="auto" className="flow-col-xxs mb-3">
                    <p className="title mb-0">AI Vol</p>
                    <div className="mb-1">
                      <VolumeImage rms_value={alarm.rms} isoClass={alarm.equipment_iso_class} />
                    </div>
                  </Col>
                  <Col xs="auto" className="flow-col-xxs mb-3">
                    <p className="title mb-0">AI Tone</p>
                    <div className="mb-1">
                      <ToneImage vibration_condition={alarm.condition_vibration} />
                    </div>
                  </Col>
                  <Col xs="auto" className="flow-col-xxs mb-3">
                    <p className="title mb-0">AI Temp</p>
                    <div className="mb-1">
                      <TemperatureImage temperature_condition={alarm.condition_temperature} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="small-gutters">
          <Col xs="auto" className="mb-1" >
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>View the FFT for this alarm</Tooltip>
              )}
            >
              {triggerProps => (
                // set overlay trigger on button not entire modal
                <AlarmFFTModal alarmId={alarm.id} onShow={() => { trackDispatch({location: 'Activity', alarm_id: alarm.id, action: 'fftsmudge'}); }} >
                  <Button
                    {...triggerProps}
                    variant="outline-secondary"
                    className="mb-0"
                  >
                    <Icon
                      iconSize="1.2em"
                      icon="timeline-area-chart"
                      title="FFT"
                    /> <span>FFT</span>
                  </Button>
                </AlarmFFTModal>
              )}
            </OverlayTrigger>
          </Col>
          <Col xs="auto" className="mb-1">
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>Play an audio recreation of this alarm</Tooltip>
              )}
            >
              <AlarmSoundButton
                variant="outline-secondary"
                alarmId={alarm.id}
                soundType="alarm"
                className="mb-0"
                actionType="alarmaudio"
              >
                <span>Alarm</span>
              </AlarmSoundButton>
            </OverlayTrigger>
          </Col>
          <Col xs="auto" className="mb-1">
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>Play an audio recreation of this equipment's learning period</Tooltip>
              )}
            >
              <AlarmSoundButton
                variant="outline-secondary"
                alarmId={alarm.id}
                soundType="baseline"
                className="mb-0"
                actionType="learningaudio"
              >
                <span>Learning period</span>
              </AlarmSoundButton>
            </OverlayTrigger>
          </Col>
        </Row>
      </Card.Text>
    </FitMachineActivityCard>
  );
}

function FitMachineThresholdAlarmCard({ alarm={}, device={} }) {
  const isAlarmImpactAvailable = (alarm.was_current || alarm.is_current || alarm.is_legacy) && alarm.alarm_status !== 'new';
  const sensorName = getSensorName(device);
  return (
    <FitMachineActivityCard
      alarm={alarm}
      deviceId={alarm.device_id}
      className={`fitmachine-alarm-card no-footer-padding`}
      beforeFooter={<DeviceAlarmStatus alarmId={alarm.id} />}
      footer={<DeviceAlarmEvents alarmId={alarm.id} />}
      sideButton={isAlarmImpactAvailable && <AlarmImpactButton alarmId={alarm.id} />}
    >
      <Card.Text as="div">
        <Row>
          {alarm.alarm_description && (
            <Col xs="auto" className="flow-col-md flex-grow-1 mb-3">
              {alarm.alarm_type === 'normal' ?  // If alarm type is normal, show 'normal' messages.
                <p className="mb-1">
                  {sensorName} has detected that a previous {alarm.alarm_description} event on <Link to={`/equipment/${alarm.device_id}`}>
                    {device.equipment_name}
                  </Link> has returned to normal.
                </p> :  // Otherwise, show messges for alarm. The flip side of 'normal' is just 'alarm'.
                <p className="mb-1">
                  {sensorName} has detected that <Link to={`/equipment/${alarm.device_id}`}>
                    {device.equipment_name}
                  </Link> has experienced a {alarm.alarm_description} event.
                  We recommend you review the details of this deviation on the MachineCloud
                  and inspect the equipment.
                </p>
              }
            </Col>
          )}
        </Row>
        <Row>
          {alarm.trigger_description && (
            <Col xs="auto" className="flow-col-md flex-grow-1 mb-1">
              <p className="mb-1">
                Trigger: {alarm.trigger_description}
              </p>
            </Col>
          )}
        </Row>

      </Card.Text>
    </FitMachineActivityCard>
  );
}

function FitMachineUserAlarmCard({ alarm={}, deleteAlarm, userId, isAdmin, userCanDeleteAlarm }) {
  const location = useLocation();
  const history = useHistory();
  const [deleting, setDeleting] = useState(false);
  const isAlarmImpactAvailable = (alarm.was_current || alarm.is_current || alarm.is_legacy) && alarm.alarm_status !== 'new';

  const handleDeleteAlarm = useCallback(async () => {
    const isAtAlarmPage = /\/alarms\/\d+/.test(location.pathname);
    setDeleting(true);
    try {
      await deleteAlarm(alarm);
      if(isAtAlarmPage) {
        history.push('/alarms/list/user');
      }
    } catch(e) {
    } finally {
      setDeleting(false);
    }
  }, [alarm, deleteAlarm]);

  return (
    <FitMachineActivityCard
      alarm={alarm}
      deviceId={alarm.device_id}
      className={`fitmachine-alarm-card no-footer-padding`}
      beforeFooter={<DeviceAlarmStatus alarmId={alarm.id} />}
      footer={<DeviceAlarmEvents alarmId={alarm.id} />}
      sideButton={isAlarmImpactAvailable && <AlarmImpactButton alarmId={alarm.id} />}
    >
      <Card.Text as="div">
        <Row>
          {alarm.alarm_description && (
            <Col xs="auto" className="flow-col-md flex-grow-1 mb-2">
              <div className="font-weight-bold">
                {alarm.alarm_description}
              </div>
            </Col>
          )}
        </Row>
        <Row>
          {alarm.trigger_description && (
            <Col xs="auto" className="flow-col-md flex-grow-1 mb-2">
              <div>
                {alarm.trigger_description}
              </div>
            </Col>
          )}
        </Row>
        {(userCanDeleteAlarm &&
          alarm.alarm_status === 'new' &&
          (userId === alarm.created_by_id || isAdmin )) &&
          <div className="d-flex justify-content-end">
            <ConfirmModal
              header="Delete the manual alarm?"
              confirmText="Delete"
            >
              <Button
                variant="danger"
                onClick={handleDeleteAlarm}
                className="mr-1"
                disabled={deleting}
              >Delete</Button>
            </ConfirmModal>
          </div>
        }
      </Card.Text>
    </FitMachineActivityCard>
  );
}

// render the correct alarm card type
function FitMachineAlarmCard({
  alarmId,
  alarm,
  device={},
  fetchAlarmEvents,
  deleteAlarm,
  userId,
  isAdmin,
  userCanDeleteAlarm,
  groupTree,
  selectActiveGroup,
}) {

  // fetch alarm events, they will be needed further down in the cards
  useEffect(() => {
    if (alarmId) {
      fetchAlarmEvents({ id: alarmId });
    }
  }, [alarmId]);

  if(!alarm &&
    groupTree &&
    groupTree.id) {
    selectActiveGroup(groupTree.id);
  }

  return alarm && alarm.alarm_source ? (
    alarm.alarm_source === 'threshold' ? (
      <FitMachineThresholdAlarmCard alarm={alarm} device={device} />
    ) :
      alarm.alarm_source === 'user' ? (
        <FitMachineUserAlarmCard
          alarm={alarm}
          device={device}
          deleteAlarm={deleteAlarm}
          userId={userId}
          isAdmin={isAdmin}
          userCanDeleteAlarm={userCanDeleteAlarm}
        />
      ) :
        (
          <FitMachineConditionAlarmCard alarm={alarm} device={device} />
        )
  ) : null;
}

const mapStateToProps = (state, { alarmId }) => {
  return {
    alarmId,
    device: getAlarmDevice(state, alarmId),
    alarm: getAlarm(state, alarmId),
    userId: getUserId(state),
    isAdmin: isAdmin(state),
    userCanDeleteAlarm: getDeviceHasProductCode(state, getAlarmDevice(state, alarmId)?.id, 'user_alarms'),
    groupTree: getGroupTree(state),
  };
};
const mapDispatchToProps = {
  fetchAlarms,
  fetchDeviceAlarms,
  fetchAlarmEvents,
  deleteAlarm,
  selectActiveGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(FitMachineAlarmCard);

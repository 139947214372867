import React from 'react';
import { Link } from 'react-router-dom';

import UserLink from './components/UserLink';

import {
  headerFormatter,
  textFormatter,
  absoluteDateFormatter,
  absoluteDateFilter,
  relativeDateFormatterCreator,
  relativeDateOrNeverFilter,
} from '../../components/table/formatters';

import {
  numericSortFunc,
  stringSortFunc,
  timeSortFunc,
  stringSortValueWithHumanNumbersFunc,
} from '../../components/table/utils';

import { userTypes } from './selectors';

export const userName = {
  dataField: 'name',
  text: 'Name',
  headerFormatter,
  formatter: (name, { id }) => id ? <UserLink userId={id} userName={name} /> : "N/A",
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

export const userEmail = {
  dataField: 'email',
  text: 'Email',
  headerFormatter,
  formatter: textFormatter,
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

export const userType = {
  dataField: 'user_type',
  text: 'User type',
  headerFormatter,
  formatter: textFormatter,
  filterValue: textFormatter,
  sort: true,
  // sort by user type privilege level
  sortValue: value => userTypes.indexOf(value),
  sortFunc: numericSortFunc,
};

export const userTypeDisplayName = {
  dataField: 'user_type_display_name',
  text: 'User type',
  headerFormatter,
  formatter: textFormatter,
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const department = {
  dataField: 'department',
  text: 'Department',
  headerFormatter,
  formatter: textFormatter,
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const embeddedOrganisations = {
  dataField: '_embedded.organisations',
  text: 'Organisation',
  headerFormatter,
  formatter: (organisations=[], { user_type }) => {
    return organisations.length > 0
      ? organisations
        .map(({ organisation_id, organisation_name }, index) => {
          // return on separate lines, and add comma if item is not last item
          return (
            <div key={organisation_id}>
              <Link to={`/organisations/${organisation_id}`} onClick={(e) => e.stopPropagation()}>{organisation_name}</Link>{index < organisations.length - 1 ? ',' : ''}
            </div>
          );
        })
      : user_type === 'Super Admin'
        ? 'All organisations'
        : 'No organisations';
  },
  filterValue: (organisations=[]) => organisations
    .map(({ organisation_name }) => organisation_name),
  sort: true,
  sortValue: (organisations=[]) => {
    // return a string, or undefined
    return `${organisations.map(({ organisation_name }) => organisation_name)}` || undefined;
  },
  sortFunc: stringSortFunc,
};

// calculate prefix and suffix value depending on the cell value, and pass them through here
const lastActiveDateFormatter = relativeDateFormatterCreator('Never', {
  prefix: (date, { prefix }) => prefix,
  suffix: (date, { suffix }) => suffix,
});

export const embeddedOrganisationsLastActive = {
  dataField: '_embedded.organisations_last_active',
  text: 'Last active',
  headerFormatter,
  formatter: (organisations=[], { user_type }) => {
    return organisations.length > 0
      ? organisations
        .map(({ organisation_id, organisation_name, last_active }, index) => {
          // return on separate lines, and add comma if item is not last item
          return (
            <div key={organisation_id}>
              {lastActiveDateFormatter(last_active, {
                prefix: organisations.length > 1 ? `Last active on ${organisation_name}: ` : '',
                suffix: index < organisations.length - 1 ? ',' : '',
              })}
            </div>
          );
        })
      : user_type === 'Super Admin'
        ? 'N/A'
        : 'No organisations';
  },
  filterValue: (organisations=[]) => organisations
    .map(({ last_active }) => relativeDateOrNeverFilter(last_active)),
  // sort by latest active organisation timestamp
  sort: true,
  sortValue: (organisations=[], { user_type }) => {

    // sort super admins N/A as least active
    if (user_type === 'Super Admin') {
      return undefined;
    }

    const activeOrganisationTimes = organisations
      .map(({ last_active }) => last_active)
      .filter(v => v);

    // sort 'never logged in' as second least active
    if (activeOrganisationTimes.length === 0) {
      return '';
    }

    // sort by date string (starting from ~2016)
    return activeOrganisationTimes
      .sort((a, b) => a === b ? 0 : a > b ? 1 : -1) // sort ascending
      .pop();
  },
  sortFunc: stringSortFunc,
};

export const createdAt = {
  dataField: 'created_at',
  text: 'Date created',
  headerFormatter,
  formatter: absoluteDateFormatter,
  filterValue: absoluteDateFilter,
  sort: true,
  sortFunc: timeSortFunc,
};

import React, { forwardRef } from 'react';
import ReactQuill from 'react-quill';
import 'quill-mention';

import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.css";
import "./editor.scss";

const QuillEditor = forwardRef(({ mentionsList = [], readOnly, defaultValue }, ref) => {

  const atValues = mentionsList
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .map((value, index) => ({
      id: index,
      value: `${value.mention_name}`,
      name: value.name,
      mention_name: value.mention_name,
    }));

  const modules = {
    mention: mentionsList && mentionsList.length > 0 && {
      allowedChars: /^[A-Za-z\s]*$/,
      mentionDenotationChars: ["@"],
      source: function(searchTerm, renderList, mentionChar) {
        const values = mentionChar === '@' ? atValues : [];
        if(searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = values.filter(value => value.name.toLowerCase().includes(searchTerm.toLowerCase()));
          renderList(matches, searchTerm);
        }
      },
      renderItem: function(item) {
        return `@<b>${item.mention_name}</b> ${item.name} `;
      },
      mentionListClass: "editor-mention-list",
      mentionContainerClass: "editor-mention-list-container",
    },
  };

  return (
    <ReactQuill
      style={{ width: '100%' }}
      ref={ref}
      theme="snow"
      value={defaultValue || ''}
      modules={modules}
      readOnly={!!readOnly}
    >
      <div className="editor" />
    </ReactQuill>
  );
});

export default QuillEditor;
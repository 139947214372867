import { WIFI_ONLY_FIT_MACHINES, SENSOR_TYPE } from './constants';

import moment from 'moment';

// Images
import fmPlaceholder from '../../images/fmPlaceholder.png';
import exPlaceholder from '../../images/fmExPlaceholder.png';
import fpPlaceholder from '../../images/fpPlaceholder.png';

export function getDraftFromProps({ navigation, devices }) {
  return { ...devices.drafts[navigation.state.params.draftIndex], draftIndex: navigation.state.params.draftIndex };
}

// allow a helper to fetch from the device object
export function getPlaceholderImageForDevice(device) {
  if(device?.sensor_type === 'fitpower') return fpPlaceholder;
  return device && `${device.fitmachine_type}`.match(/\bEX\b/)
    ? exPlaceholder
    // return regular as default
    : fmPlaceholder;
}

export function cleanTemplate(device) {
  const template = { ...device };
  delete template.images;
  delete template.installation_date;
  delete template.note;
  delete template.serial;
  delete template.firmwareVersion;
  delete template.wifiProfiles;
  delete template.wifiConnectivity;
  return template;
}

export function formatDeviceName(device) {
  const { site_name, sub_area_name, equipment_name } = device || {};

  const location = `${site_name || ''}${sub_area_name ? ', ' : ''}${sub_area_name || ''}`;
  const name = equipment_name || 'Unnamed device';

  return `${location || ''}${location ? ': ' : ''}${name}`;
}

/**
 * Maps a device from the Platform API structure to
 * the form the app expects.
 * @param {object} device The device to map
 */
export function mapDeviceFromApi(device) {
  return {
    id: device.id,
    archived: device.archived,
    serial: device.fitmachine_serial,
    bt_address: device.bt_address,
    latitude: device.latitude,
    longitude: device.longitude,
    site_name: device.site_name,
    sub_area_name: device.site_subarea_name,
    installation_date: !!device.fitmachine_install_date && moment(device.fitmachine_install_date),
    calibration_start: !!device.fitmachine_onboard_date && moment(device.fitmachine_onboard_date),
    note: device.note,
    iso_class: device.equipment_iso_class,
    equipment_name: device.equipment_name,
    equipment_number: device.equipment_number,
    equipment_brand: device.equipment_brand,
    equipment_model: device.equipment_model,
    equipment_capacity: device.equipment_capacity,
    max_rpm: !!device.equipment_max_rpm && Number(device.equipment_max_rpm),
    power_rating: device.equipment_power_rating,
    running: device.equipment_running,
    running_cutoff: device.rms_running_cutoff,
    confined_space: device.confined_space,
    variable_speed_drive: device.variable_speed_drive,
    equipment_type: device.equipment_type,
    organisation_name: device.organisation_name,
    fitmachine_type: device.fitmachine_type,

    // dashboard only parameters
    created_at: device.created_at,
    organisation_id: device.organisation_id,
    onboarded_by: device.onboarded_by,
    organisation_sub_domain: device.organisation_sub_domain,
    onboarded_name: device.onboarded_name,
    mute_advisory_for: device.mute_advisory_for,
    battery_voltage: device.battery_voltage,
    battery_score: device.battery_score,
    wifi_signal: device.wifi_signal,
    wifi_score: device.wifi_score,
    rms: device.rms,
    rms2: device.rms2,
    baseline_temperature: device.baseline_temperature,
    temperature: device.temperature,
    calibration: device.calibration,
    condition_indicated: device.condition_indicated,
    condition_overall: device.condition_overall,
    condition_vibration: device.condition_vibration,
    condition_temperature: device.condition_temperature,
    utilisation_month: device.utilisation_month,
    power_usage_month: device.power_usage_month,
    rate_overall: device.rate_overall,
    fitmachine_last_heard: device.fitmachine_last_heard,
    fitmachine_last_sample_date: device.fitmachine_last_sample_date,
    is_calibrating: device.is_calibrating,
    tags: { ...device.tags },
    product_codes: device.product_codes,
    status_summary: device.status_summary,
    status_caption: device.status_summary?.caption || '',
    fmconfig: device.fmconfig,
    role: device.role,
    state: device.state,
    sensor_type: device.sensor_type,
    current: device.current,
    related_ids: device.related_ids,
  };
}

/**
 * This function takes a WiFi MAC address and returns whether it's
 * found in the hardcoded list of WiFi-only FitMachines
 * @param {string} serial The WiFi MAC address in question
 * @returns {boolean}
 */
export function isWifiOnlyFitMachine(serial) {
  const firstThreeOctets = serial.substring(0, 8);
  const fourthOctet = serial.substring(9, 11);
  const lastTwoOctets = serial.substring(12);

  const page = WIFI_ONLY_FIT_MACHINES[firstThreeOctets];
  if (page) {
    const section = page[fourthOctet];
    if (section) {
      return !!section.find(o => o === lastTwoOctets);
    }
  }
  return false;
}

/**
 * Return the sensor name.
 * If sensor type is 'fitpower' return 'FitPower',
 * If sensor type is 'fitmachine' return 'FitMachine',
 * Otherwise, just return 'sensor'.
 * @param {object} device
 * @returns {string}
 */
export function getSensorName(device) {
  return device?.sensor_type === 'fitpower' ? 'FitPower' : device.sensor_type === 'fitmachine' ? 'FitMachine' : 'Sensor';
}

export function isFitPower(device) {
  return device?.sensor_type === SENSOR_TYPE.FITPOWER;
}

export function isFitMachine(device) {
  return device?.sensor_type === SENSOR_TYPE.FITMACHINE;
}
import { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import HomeCard from './HomeCard';
import { useEventImpactTrendData } from '../../modules/organisation/hooks/useGroupEventsReport';
import { getChartLabelColor, alignTwoYAxises } from '../lib/utils';

function EventImpactTrend() {
  const eventImpactTrend = useEventImpactTrendData();

  const {_state_: { loading, error } = {}, items = []} = eventImpactTrend || {};
  const itemsSortedByDate = items.sort((a, b) => a.date < b.date ? -1 : 1);
  const avoidedCosts = useMemo(() => itemsSortedByDate.map(i => i.avoided_costs), [items]);
  const eventCosts = useMemo(() => itemsSortedByDate.map(i => i.event_costs === 0 ? 0 : i.event_costs * -1), [items]);
  const equipmentCount = useMemo(() => itemsSortedByDate.map(i => i.equipment_cnt), [items]);
  const preventedCosts = useMemo(() => itemsSortedByDate.map(i => i.total_prevented), [items]);
  const monitoringCosts = useMemo(() => itemsSortedByDate.map(i => i.monitoring_costs * -1), [items]);
  const sumEventMonitoringCosts = useMemo(() => monitoringCosts.map((c, i) => c + eventCosts[i]), [eventCosts, monitoringCosts]); // The sum of values in eventCosts and monitoringCosts.
  const [interval1, interval2, positiveTicks, negativeTicks] = useMemo(() => alignTwoYAxises([...sumEventMonitoringCosts, ...avoidedCosts, ...preventedCosts], equipmentCount), [items]);

  if(!eventImpactTrend) return null;

  return (
    <HomeCard
      header="Event impact trend"
      headerLink={{to: '/group/impact_summary', text: 'All Areas'}}
      loading={loading}
      error={error}
    >
      <ReactEcharts
        option = {{
          grid: {
            top: 10,
            bottom: 60,
            left: 85,
            right: 60,
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            textStyle: {
              fontSize: 10
            },
            bottom: -5,
            data: ['Monitoring Costs', 'Avoided Costs', 'Event Costs', 'Total Prevented Costs', 'Assets Monitored']
          },
          color: ['orange', 'green', 'red', 'grey', 'blue'].map(color => getChartLabelColor(color)),
          xAxis: {
            type: 'category',
            data: itemsSortedByDate.map(i => moment(i.date).format('MMM-YY'))
          },
          yAxis: [
            {
              type: 'value',
              name: 'Costs Incurred / Prevented',
              nameLocation: 'middle',
              position: 'left',
              nameTextStyle: {
                padding: [0, 0, 55, 0]
              },
              max: interval1 * positiveTicks,
              interval: interval1,
              min: interval1 * negativeTicks,
            },
            { type: 'value' },
            {
              type: 'value',
              name: 'Qty of Asserts Monitored',
              nameLocation: 'middle',
              position: 'right',
              nameTextStyle: {
                padding: [30, 0, 0, 0]
              },
              axisLabel: {
                formatter: function(value) {
                  return value < 0 ? '' : value;
                }
              },
              max:  interval2 * positiveTicks,
              interval:  interval2,
              min: interval2 * negativeTicks,
            }
          ],
          series: [{
            name: 'Monitoring Costs',
            type: 'bar',
            stack: 'one',
            data: monitoringCosts,
          }, {
            name: 'Avoided Costs',
            type: 'bar',
            stack: 'one',
            data: avoidedCosts,
          }, {
            name: 'Event Costs',
            type: 'bar',
            stack: 'one',
            data: eventCosts,
          }, {
            name: 'Assets Monitored',
            type: 'line',
            data: equipmentCount,
            yAxisIndex: 2,
          }, {
            name: 'Total Prevented Costs',
            type: 'line',
            data: preventedCosts,
          }]
        }}
      />
    </HomeCard>
  );
}

export default EventImpactTrend;
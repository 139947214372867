
import { useParams } from 'react-router-dom';
import AlarmImpactSettings from "../../alarm/components/AlarmImpactSettings";
import EquipmentPageLayout from '../components/EquipmentPageLayout';

export default function EditEquipmentImpact() {
  const { id } = useParams();
  const deviceId = Number(id);

  return (
    <EquipmentPageLayout>
      <div className="my-4">
        <AlarmImpactSettings deviceId={deviceId} />
      </div>
    </EquipmentPageLayout>
  );
};
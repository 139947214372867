import { useCallback } from 'react';
import { Dropdown, Form, Row, Col, Button } from 'react-bootstrap';
import { IoIosLink } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import FormModal from '../../../components/FormModal';
import useDeviceLinks from '../hooks/useDeviceLinks';
import { isValidMacAddress } from '../../../lib/utils';

function AddLinkModal({ device, header, as='dropdown' }) {
  const { register, handleSubmit, reset, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      serial: '',
    }
  });
  const { loading: submitting, updateLinks } = useDeviceLinks(device?.id);


  const onSubmit = useCallback(async (data) => {
    const { serial } = data;
    await updateLinks({
      links: [{
        serial, linked: true,
      }]
    });
    reset();
  }, [updateLinks]);

  const renderForm = useCallback(() => {
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row}>
          <Form.Label column sm="3">MAC Address</Form.Label>
          <Col sm="9">
            <Form.Control
              {...register('serial', {
                required: true,
                validate: value =>{
                  return isValidMacAddress(value) && device.serial.toLowerCase() !== value.toLowerCase();
                }
              })} />
            <Form.Text muted>Input the MAC Address of the sensor you want to link.</Form.Text>
          </Col>
        </Form.Group>
      </Form>
    );
  }, [onSubmit, formState]);

  return (
    <FormModal
      header={header}
      form={renderForm()}
      confirmText="Link"
      size="md"
      valid={!submitting && formState.isValid}
    >
      {as === 'dropdown' ?
        <Dropdown.Item>
          <IoIosLink size="1.2em" /> <span>{header}</span>
        </Dropdown.Item> :
        <Button size="sm" variant="outline-secondary">
          <IoIosLink size="1.2em" /> <span>{header}</span>
        </Button>
      }
    </FormModal>
  );
}

export default AddLinkModal;
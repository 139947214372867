import { useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import { useGroupMonitoringTrend } from '../../modules/organisation/hooks/useGroupStandardData';
import HomeCard from './HomeCard';
import { getChartLabelColor, roundNumber } from '../lib/utils';
import useDeviceStatusFilter from '../../modules/equipment/hooks/useDeviceStatusFilter';

function HoursMonitored() {
  const monitoringTrend = useGroupMonitoringTrend();

  const {_state_: {loading, error} = {}, items = []} = monitoringTrend || {};
  const itemsSortedByDate = items.sort((a, b) => a.date < b.date ? -1 : 1);
  const hoursMonitored = useMemo(() => itemsSortedByDate.map(i => i.monitored_hrs), [items]);
  const equipmentCount = useMemo(() => itemsSortedByDate.map(i => i.equipment_cnt), [items]);
  const maxHoursMonitored = roundNumber(Math.max(...hoursMonitored));
  const maxEquipmentCount = roundNumber(Math.max(...equipmentCount));
  const { filterAll } = useDeviceStatusFilter();

  if(!monitoringTrend) return null;

  return (
    <HomeCard
      header="Hours and equipment monitored"
      headerLink={{to: '/', text: 'All equipment', onClick: filterAll }}
      loading={loading}
      error={error}
    >
      <ReactECharts
        option={{
          grid: {
            top: 10,
            left: 90,
            right: 90,
          },
          color: ['green', 'orange'].map(color => getChartLabelColor(color)),
          legend: {
            data: ['Hours Monitored', 'Devices'],
            bottom: 0
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: [{
            type: 'category',
            data: itemsSortedByDate.map(i => moment(i.date).format('MMM-YY'))
          }],
          yAxis: [{
            type: 'value',
            name: 'Total Hours Monitored',
            nameLocation: 'middle',
            nameTextStyle: {
              padding: [0, 0, 50, 0],
            },
            position: 'left',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#333'
              }
            },
            min: 0,
            max: maxHoursMonitored,
            interval: maxHoursMonitored / 5,
          }, {
            type: 'value',
            name: 'Total Devices Monitored',
            nameLocation: 'middle',
            nameTextStyle: {
              padding: [40, 0, 0, 0],
            },
            position: 'right',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#333'
              }
            },
            min: 0,
            max: maxEquipmentCount,
            interval: maxEquipmentCount / 5,
          }],
          series: [
            {
              name: 'Hours Monitored',
              type: 'bar',
              data: hoursMonitored,
            }, {
              name: 'Devices',
              type: 'line',
              data: equipmentCount,
              yAxisIndex: 1,
            }
          ]
        }}
      />
    </HomeCard>
  );
}

export default HoursMonitored;
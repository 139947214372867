import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { updateGroup } from '../actions';
import GroupPageLayout from '../components/GroupPageLayout';
import Title from '../../../components/Title';
import useGroup from '../hooks/useGroup';

function EditGroupConfig() {
  const dispatch = useDispatch();
  const { group, state: groupState } = useGroup();
  const { register, unregister, handleSubmit, watch, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      group_name: group.group_name,
      enable_alarm_channel: group.enable_alarm_channel,
      alarm_channel_name: group.alarm_channel_name,
      alarm_escalate: group.alarm_escalate,
      alarm_escalate_timeout: group.alarm_escalate_timeout,
    }
  });
  const watchEnableAlarmChannel = watch('enable_alarm_channel');
  const watchAlarmEscalate = watch('alarm_escalate');
  useEffect(() => {
    unregister('alarm_channel_name');
  }, [watchEnableAlarmChannel]);
  useEffect(() => {
    unregister('alarm_escalate_timeout');
  }, [watchAlarmEscalate]);

  useEffect(() => {
    if(Object.keys(group).length === 0) return;
    setValue("group_name", group.group_name);
    setValue("enable_alarm_channel", group.enable_alarm_channel);
    setValue("alarm_channel_name", group.alarm_channel_name);
    setValue("alarm_escalate", !!group.alarm_escalate);
    setValue("alarm_escalate_timeout", group.alarm_escalate_timeout);
  }, [group.id, groupState.lastFetch]);

  const onSubmit = useCallback((formValues) => {
    dispatch(updateGroup(group, formValues));
  }, [group, updateGroup]);

  return (
    <GroupPageLayout>
      <Container fluid className="px-0">
        <Title
          title={`Editing group: ${group.group_name || ''}`}
          loading={groupState.loading}
          lastFetch={groupState.lastFetch}
          error={groupState.error}
        ></Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row} controlId="form__edit_group--group_name">
            <Form.Label column lg="3" sm="4">
              Name
            </Form.Label>
            <Col lg="9" sm="8">
              <Form.Control
                type="text"
                {...register("group_name", {
                  required: true,
                })}
              />
            </Col>
          </Form.Group>
          {(group.group_type === 'device' || group.group_type === 'group') && <><Form.Group as={Row} controlId="form__edit_group--alarm_channel_name">
            <Form.Label column lg="3" sm="4">Separate Alarm Channel</Form.Label>
            <Col lg="9" sm="8" className="d-flex align-items-center">
              <Form.Check
                type="checkbox"
                {...register("enable_alarm_channel")}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column lg="3" sm="4">
              Alarm Channel Name
            </Form.Label>
            <Col lg="9" sm="8">
              <Form.Control
                type="text"
                {...register("alarm_channel_name", {
                  required: true,
                  disabled: !watchEnableAlarmChannel
                })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="form__edit_organisation--alarm_escalate">
            <Form.Label column lg="3" sm="4">Alarm Escalations</Form.Label>
            <Col lg="9" sm="8" className="d-flex align-items-center">
              <Form.Check
                type="checkbox"
                {...register("alarm_escalate", {
                  disabled: !watchEnableAlarmChannel
                })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column lg="3" sm="4">
              Escalation Timeout (hours)
            </Form.Label>
            <Col lg="9" sm="8" className="d-flex align-items-center">
              <Form.Control
                type="number"
                min={1}
                {...register("alarm_escalate_timeout", {
                  required: true,
                  valueAsNumber: true,
                  min: 1,
                  disabled: !watchAlarmEscalate
                })}
                style={{width: '5em'}}
              />
            </Col>
          </Form.Group></>}
          <Button
            variant="success"
            size="lg"
            className="float-right mb-chat-widget"
            type="submit"
            disabled={!formState.isValid}
          >
            Update
          </Button>
        </form>
      </Container>
    </GroupPageLayout>
  );
}

export default EditGroupConfig;
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import { IoIosCreate, IoIosTrash, IoIosAddCircleOutline } from 'react-icons/io';
import { fetchAlarm } from '../actions';
import { fetchDeviceInfo } from '../../equipment/actions';
import { getAlarm, getAlarmDeviceId, getAlarmDevice } from '../selectors';
import Title from '../../../components/Title';
import { FitMachineActivityCard } from '../../equipment/components/DeviceCard';
import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';
import { formatCurrency, formatNumber } from '../../../components/lib/utils';
import ConfirmModal from '../../../components/ConfirmModal';
import AlarmImpactSettingsModal from '../components/AlarmImpactSettingsModal';
import AlarmImpactModal from '../components/AlarmImpactModal';
import useAlarmImpact from '../hooks/useAlarmImpact';
import useLocale from '../../../hooks/useLocale';

function DeleteAlarmButton({ alarmId, impactId }) {
  const { deleteImpact } = useAlarmImpact(alarmId);

  return (
    <ConfirmModal
      header="Delete the item?"
      confirmText="Delete"
    >
      <Button size="md" variant="outline-secondary" onClick={() => { deleteImpact(impactId); }}>
        <IoIosTrash size="1.2em" />
        <span>Delete</span>
      </Button>
    </ConfirmModal>
  );
}

function AlarmImpact() {
  const dispatch = useDispatch();
  const { id: alarmId } = useParams();
  const alarm = useSelector(state => getAlarm(state, alarmId));
  const deviceId = useSelector(state => getAlarmDeviceId(state, alarmId));
  const device = useSelector(state => getAlarmDevice(state, alarmId));
  const {
    alarmImpact,
    alarmImpactSummary,
    loading,
    lastFetch,
    error,
    fetchImpact,
    fetchImpactSummary,
  } = useAlarmImpact(alarmId);
  const { currency } = useLocale({ deviceId });

  const columns = useMemo(() => {
    return [
      {
        dataField: 'event_category',
        text: 'Item',
        footer: '',
        footerFormatter: () => (
          <AlarmImpactSettingsModal deviceId={device?.id}>
            <IoIosAddCircleOutline size="1.4em" />{' '}
            <span>Add Rate</span>
          </AlarmImpactSettingsModal>
        )
      },
      {
        dataField: 'cost_category',
        text: 'Category',
        classes: 'text-capitalize',
        footer: '',
      },
      {
        dataField: 'description',
        text: 'Description',
        footer: '',
      },
      {
        dataField: 'qty',
        text: 'Qty',
        align: 'right',
        headerAlign: 'right',
        formatter: cell => formatNumber(cell),
        footer: '',
      },
      {
        dataField: 'units',
        text: 'Units',
        footer: '',
        footerFormatter: () => {
          return <>
            {Object.entries(alarmImpactSummary || {}).map(([key, data]) => (
              <div className="d-flex justify-content-between" key={key}>
                <div className="flex-grow-1 mr-1">{data.description}</div>
                <div className={data.amount < 0 ? 'text-danger': ''}>{formatCurrency(data.amount, currency)}</div>
              </div>
            ))}
          </>;
        },
        footerAttrs: { colSpan: 3 }
      },
      {
        dataField: 'rate',
        text: 'Rate',
        align: 'right',
        headerAlign: 'right',
        formatter: value => formatCurrency(value || 0, currency),
      },
      {
        dataField: 'amount',
        text: 'Amount',
        align: 'right',
        headerAlign: 'right',
        formatter: value => {
          return (
            <span className={((value < 0) ? 'text-danger' : '') + ' text-nowrap'}>
              {formatCurrency(value || 0, currency)}
            </span>
          );
        },
      },
      {
        dataField: '',
        text: 'Actions',
        align: 'right',
        headerAlign: 'right',
        formatter: (value, impactRow) => {
          return (
            <div>
              <ButtonGroup>
                <AlarmImpactModal variant="outline-secondary" impact={impactRow} alarm={alarm} currency={currency}>
                  <IoIosCreate size="1.2em" /><span>Edit</span>
                </AlarmImpactModal>
                <DeleteAlarmButton alarmId={alarm?.id} impactId={impactRow?.id} />
              </ButtonGroup>
            </div>
          );
        },
        formatExtraData: {
          alarm
        },
        footer: ''
      }
    ];
  }, [alarmId, alarm]);

  useEffect(() => {
    if(alarmId) {
      if(!alarm) dispatch(fetchAlarm({ id: alarmId }));
      fetchImpact();
      fetchImpactSummary();
    }
  }, [alarmId]);

  useEffect(() => {
    if(deviceId) {
      dispatch(fetchDeviceInfo({ id: deviceId }));
    }
  }, [deviceId]);

  const renderHeader = useCallback(props => {
    return (
      <TableToolbar
        searchable
        title="Event Impact"
        loading={loading}
        lastFetch={lastFetch}
        error={error}
        tableProps={props}
        buttons={
          [<AlarmImpactModal key="add" className="primary" alarm={alarm} currency={currency}>
            <IoIosAddCircleOutline size="1.4em" /> <span>Add Item</span>
          </AlarmImpactModal>]
        }
      />
    );
  }, [loading, lastFetch, error]);

  return (
    <div className="container-fluid">
      <Row>
        <Col xs={12}>
          <Title title="Alarm" />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FitMachineActivityCard
            deviceId={alarm?.device_id}
            timestamp={alarm?.alarm_timestamp}
            alarmType={alarm?.alarm_type}
            timestampLinkTo={`/alarms/${alarm?.id}`}
            classNme="fitmachine-alarm-card"
          />
        </Col>
      </Row>
      <Table
        keyField="id"
        renderHeader={renderHeader}
        data={alarmImpact}
        columns={columns}
        noDataIndication={() => 'No event impact rates.'}
        loading={loading}
      />
    </div>
  );
}

export default AlarmImpact;
import { createSelector } from 'reselect';
import { getUserSubDomain } from '../user/selectors';

// add vars for more readable time diff logic
const milliseconds = 1;
const seconds = 1000 * milliseconds;
const minutes = 60 * seconds;

export function getOrganisationListState(state) {
  const { organisation: { loading, lastFetch, error }={} } = state;
  return { loading, lastFetch, error };
}

export function getOrganisationsList(state) {
  return state && state.organisation && state.organisation.organisations;
}

export function getOrganisations(state) {
  const organisationList = getOrganisationsList(state);
  if (isTopLevelGroupActive(state)) {
    return organisationList;
  }
  else if (organisationList) {
    const groupId = getActiveGroupId(state);
    // filter organisations to the organisation this group is a part of
    // find nearest organisation id
    const organisationId = getNearestOrganisationAncestorId(state, groupId);
    // filter to organisations that match or are underneath this organisation
    return organisationId ? organisationList.filter(organisation => {
      return (
        (organisationId === organisation.id) ||
        (organisationId === organisation.parent_organisation_id)
      );
    }) : [];
  }
}

export const getGroups = createSelector(
  state => state,
  state => state.organisation?.groups
);

export function getGroupLoadingState(state, groupId) {
  return state &&
    state.organisation &&
    state.organisation.groupRequestsById &&
    state.organisation.groupRequestsById[groupId];
}

export function getActiveGroupId(state) {
  return state && state.organisation && state.organisation.activeGroupId;
}

// return active group id, if that group is not the top-level organisation group
export function getActiveSubGroupId(state) {
  return !isTopLevelGroupActive(state)
    ? getActiveGroupId(state)
    : undefined;
}

// importantly this defaults to true if no group is yet selected
export function isTopLevelGroupActive(state) {
  // compare id of top level group and active group
  const activeGroupId = getActiveGroupId(state);
  // only fetch org group if necessary
  return !activeGroupId || (activeGroupId === (getOrganisationGroup(state) || {}).id);
}

// return active group, if that group is not the top-level organisation group
export function getActiveSubGroup(state) {
  return !isTopLevelGroupActive(state)
    ? getGroup(state, getActiveGroupId(state))
    : undefined;
}

// return active group, of any type
export function getActiveGroup(state) {
  return !isTopLevelGroupActive(state)
    ? getGroup(state, getActiveGroupId(state))
    : getOrganisationGroup(state);
}

// return active group node of groups tree
// (which has a different format than groups form the groups list)
// this is for selectors needing selected group props synchronously
// these groups *should* not require a fetch call to ensure having the group data
// this is the data of the groups tree loaded upon login"
export function getActiveGroupNode(state, {
  // allow precomputed objects to be passed
  isTopLevel = isTopLevelGroupActive(state),
}={}) {
  return !isTopLevel
    ? getGroupTreeNode(state, getActiveGroupId(state))
    : getGroupTree(state);
}

export function getActiveGroupType(state) {
  const group = getActiveGroupNode(state);
  return group && group.type;
}

export function getActiveGroupNodeInfo(state) {
  const isTopLevel = isTopLevelGroupActive(state);
  const node = getActiveGroupNode(state, { isTopLevel }) || {};
  const isUserGroup = node.type === 'user';
  return {
    isRoot: isTopLevel,
    id: node.id,
    type: node.type,
    isUserGroup,
    isNormalGroup: (
      node.type === 'device' ||
      node.type === 'group' ||
      node.type === 'user'
    ),
    isOrganisationGroup: node.type === 'organisation',
    organisationId: node.type === 'organisation'
      ? node.proxy
      : undefined,
  };
}

function getCurrentOrganisation(state) {
  const userSubDomain = getUserSubDomain(state);
  const organisations = getOrganisationsList(state);

  // user has multiple organisations
  if (organisations && organisations.length > 1) {
    // get the sub_domain that the user logged in with
    // note: this is from the Login page field, any may be any string which passes API validation
    // which includes surround spacing and any form of capitalisation
    const userSubDomainParts = `${userSubDomain}`.toLowerCase()
      .split('.') // get subdomains
      .map(s => s.trim()) // remove surrounding spaces
      .filter(v => v); // remove empty strings

    return organisations.find(({ sub_domain='' }) => {
      // match full subdomain
      return `${sub_domain}.`.toLowerCase().startsWith(`${userSubDomainParts.join('.')}.`.toLowerCase());
    }) || organisations.find(({ sub_domain='' }) => {
      // match by first subdomain
      return `${sub_domain}.`.toLowerCase().startsWith(`${userSubDomainParts[0]}.`.toLowerCase());
    })
    // could not match organisation by subdomain
    // try to find MOVUS
    || organisations.find(({ sub_domain='' }) => sub_domain.startsWith('movus.'))
    // try to find a parent org
    || organisations.find(({ is_parent }) => is_parent)
    // or return the first organisation
    || organisations[0];
  }
  // user has one organisation
  else if (organisations && organisations.length > 0) {
    return organisations[0]; // return the only organisation
  }
}

export function getOrganisation(state, organisationId) {
  const currentOrg = getCurrentOrganisation(state);
  return organisationId === undefined || organisationId === (currentOrg && currentOrg.id)
    ? currentOrg || undefined
    : (getOrganisationsList(state) || []).find(({ id }) => id === organisationId);
}

export function getOrganisationState(state, organisationId) {
  const organisation = getOrganisation(state, organisationId);
  return organisation && organisation._state_;
}

export function getOrganisationStaleState(state, organisationId) {
  const organisationState = getOrganisationState(state, organisationId);
  // flag no object or state as being 'stale'
  return !organisationState || (
    // if information is loading then it will not be stale soon (hopefully)
    !organisationState.loading && (
      // not updated more recently than 5 minutes ago
      new Date(organisationState.lastFetch) <= new Date(Date.now() - 5 * minutes)
    )
  );
}

export function getOrganisationId(state, organisationId) {
  const organisation = getOrganisation(state, organisationId);
  return organisation && organisation.id;
}

export function getOrganisationLogo(state, organisationId) {
  const organisation = getOrganisation(state, organisationId);
  return organisation && ((
    // get embedded logo on organisation (has more detail)
    organisation._embedded && organisation._embedded.logo
  ) || (
    // get logo on organisation without status detail
    organisation.logo_url && { url: organisation.logo_url }
  ));
}

export function getOrganisationGroup(state) {
  return getGroupTree(state);
}

export function getGroupTree(state) {
  const organisation = getOrganisation(state);
  return organisation && organisation._embedded && organisation._embedded.groups;
}

export function getGroupTreeNode(state, groupId) {
  if (groupId) {
    const groupTreePath = getGroupTreePath(state, groupId);
    // return group found
    return groupTreePath && groupTreePath.pop();
  }
  else if (isTopLevelGroupActive(state)) {
    return getGroupTree(state);
  }
}

// allow recursive searching of the group tree to find group path
function findGroupPathById(id) {
  return function findGroupPath(path=[], group) {
    // return found path immediately
    if (path.length) {
      return path;
    }
    // add end of path
    else if (group.id === id) {
      return [group];
    }
    // collect each path part, if a match was found
    else if (group.members && group.members.length) {
      const foundPath = group.members.reduce(findGroupPath, []);
      if (foundPath) {
        return [group, ...foundPath];
      }
    }
  };
}

export function getGroupTreePath(state, groupId) {
  // recursively find the correct group, and return its path
  const groupTree = getOrganisationGroup(state);
  if (groupTree) {
    return [groupTree].reduce(findGroupPathById(groupId), []);
  }
}

export function getNearestGroupOrganisationAncestor(state, groupId) {
  const groupTreePath = groupId && getGroupTreePath(state, groupId);
  return groupTreePath && groupTreePath.reverse().find(({ type }) => {
    return type === 'organisation';
  });
}

export function getNearestOrganisationAncestorId(state, groupId) {
  // filter organisations to the organisation this group is a part of
  const nearestOrganisationNode = groupId && getNearestGroupOrganisationAncestor(state, groupId);
  // find organisation group proxy_for_id to get organisation id
  // for this to work, the nearest organisation group must be fetch
  // todo: redo if the 'proxy_for_id' prop is available on the GET/groups tree
  // see commit 619818b0604de792c63dfb1010c09b7df2eb3aed
  const nearestGroup = nearestOrganisationNode && getGroup(state, nearestOrganisationNode.id);
  return nearestGroup && nearestGroup.proxy_for_id;
}

export function getGroupTreeNamePath(state, groupId) {
  const groupTreePath = groupId && getGroupTreePath(state, groupId);
  return groupTreePath && groupTreePath.map(({ name }) => name).join(' \u00A0→\u00A0 ');
}

export function getGroupTreeNodeParent(state, groupId) {
  // recursively find the correct group, and return its path
  const groupPath = getGroupTreePath(state, groupId);
  // remove found group
  groupPath && groupPath.pop();
  // return parent group
  return groupPath && groupPath.pop();
}
export const getGroup = createSelector(
  state => state,
  (state, groupId) => groupId,
  (state, groupId) => {
    const groups = getGroups(state);
    return groupId && groups && groups.find(group => group.id === groupId);
  }
);

export function getActiveGroupLoadingState(state) {
  return getGroupLoadingState(state, getActiveGroupId(state));
}

export function getGroupDevices(state, groupId) {
  const group = getGroup(state, groupId);
  return group && group._embedded && group._embedded.devices;
}

export function getGroupDevicesAvailable(state, groupId) {
  const group = getGroup(state, groupId);
  return group && group._embedded && group._embedded.devices_available;
}

export function getGroupUsers(state, groupId) {
  const group = getGroup(state, groupId);
  return group && group._embedded && group._embedded.users;
}

export function getGroupAccess(state, groupId) {
  const group = getGroup(state, groupId);
  return group && group._embedded && group._embedded.access;
}

export function getGroupAccessGroups(state, groupId) {
  const access = getGroupAccess(state, groupId);
  return access && access.groups;
}

export function getGroupAccessUsers(state, groupId) {
  const access = getGroupAccess(state, groupId);
  return access && access.users;
}

export function getGroupRuntime(state, groupId) {
  const group = getGroup(state, groupId);
  return group && group._embedded && group._embedded.runtime;
}

export function getGroupRuntimeTimezone(state, groupId) {
  const group = getGroup(state, groupId);
  return group &&
    group._embedded &&
    group._embedded.runtimeMetadata &&
    group._embedded.runtimeMetadata.timezone;
}

export function getOrganisationRuntime(state, organisationId) {
  const organisation = getOrganisation(state, organisationId);
  return organisation && organisation._embedded && organisation._embedded.runtime;
}

export function getOrganisationRuntimeTimezone(state, organisationId) {
  const organisation = getOrganisation(state, organisationId);
  return organisation &&
    organisation._embedded &&
    organisation._embedded.runtimeMetadata &&
    organisation._embedded.runtimeMetadata.timezone;
}

export function getOrganisationTags(state, organisationId) {
  const organisation = getOrganisation(state, organisationId);
  return organisation && organisation._embedded && organisation._embedded.tags;
}

export function getOrganisationProductCodes(state, organisationId) {
  const organisation = getOrganisation(state, organisationId);
  return organisation?.product_codes;
}

export function getOrganisationHasProductCode(state, organisationId, productCode) {
  const productCodes = getOrganisationProductCodes(state, organisationId);
  return productCodes?.includes(productCode);
}

export function getCurrentOrganisationHasProductCode(state, productCode) {
  const organisationId = getOrganisationId(state);
  return getOrganisationHasProductCode(state, organisationId, productCode);
}

export function getOrganisationTokens(state, organisationId) {
  const organisation = getOrganisation(state, organisationId);
  return organisation &&
    organisation._embedded &&
    organisation._embedded.tokens;
}

export function getStreamingConfigurations(state, organisationId) {
  const organisation = getOrganisation(state, organisationId);
  return organisation &&
    organisation._embedded &&
    organisation._embedded.streams;
}

export function getStreamingConfiguration(state, organisationId, streamId) {
  const configs = getStreamingConfigurations(state, organisationId);
  return configs && configs.find(({ id }) => id === streamId);
}

export function getOrganisationAccountPaymentStatus(state, organisationId) {
  const tags = getOrganisationTags(state, organisationId);
  // the account payment status flag should always only be behind the
  // "_readonly" section, as it is written only by admins, for users to read
  return tags && tags._readonly && tags._readonly.account_payment_status;
}

export function getOrganisationUserColumns(state, organisationId) {
  const tags = getOrganisationTags(state, organisationId);
  return tags?._readonly?.user_columns || [];
}

function getOrganisationPreference(state, preferenceKey, organisationId) {
  const tags = getOrganisationTags(state, organisationId);
  // filter to tags that start with 'preferences'
  return tags && tags[`preferences:${preferenceKey}`];
}

const preferenceModels = [
  {
    key: 'rms_display',
    title: 'Vibration',
    options: [
      {
        value: 'rms_only',
        title: 'RMS',
        isDefaultOption: true,
      },
      {
        value: 'mm2_only',
        title: 'RMS - filtered',
      },
      {
        value: 'mm2_available',
        title: 'RMS and RMS - filtered',
      },
    ],
  },
];

// collect all the default options here (could easily get exported later)
// note: this returns the default option marked with 'setByDefault'
// which assumes that this option was 'selected by default' for the user
const defaultPreferenceOptionByKey = preferenceModels
  .reduce((acc, { key, options=[] }) => {
    return {
      ...acc,
      // add the default preference set by key
      [key]: {
        ...options.find(({ isDefaultOption }) => isDefaultOption),
        // tag this option as being set on the user as default
        setByDefault: true,
      },
    };
  }, {});

// collect all RMS display options as being marked as default
// this may be the case for users logging into different organisations
const defaultRmsPreferenceOptionByKey = preferenceModels
  .find(({ key }) => key === 'rms_display').options
  .reduce((acc, option) => {
    return {
      ...acc,
      // add the default preference set by key
      [option.value]: {
        ...option,
        // tag this option as being set on the user as default
        setByDefault: true,
      },
    };
  }, {});

function getPreferenceOption(key, value) {
  const model = preferenceModels.find(model => model.key === key);
  // model exists
  if (model && model.options && model.options.length > 0) {
    const option = model.options.find(option => option.value === value);
    // return found option or the default option
    return option || defaultPreferenceOptionByKey(key);
  }
}

export function getOrganisationRmsDisplayPreferenceOption(state, organisationId) {
  const organisation = getOrganisation(state, organisationId);

  // note: this is a hard-coded organisation id, because child orgs can't read their parent's info
  // note: re-evaluate this after groups & views in the platform
  const isMovus = !!organisation && organisation.id === 28;
  const isAls = !!organisation && organisation.id === 71;
  const isAlsCustomer = !!organisation && organisation.parent_organisation_id === 71;
  const hasMm2Feature = getOrganisationHasProductCode(state, organisationId, 'mm2');

  if (isMovus || isAls) {
    // ignore saved preferences
    return defaultRmsPreferenceOptionByKey['mm2_available'];
  }
  else if (isAlsCustomer || hasMm2Feature) {
    // allow saved preferences of 'mm2_available'
    const savedPreference = getOrganisationPreference(state, 'rms_display', organisationId);
    switch (savedPreference) {
      // whitelist some preferences
      // (returned without the 'setByDefault' attribute)
      case 'mm2_available':
      case 'mm2_only':
        return getPreferenceOption('rms_display', savedPreference);
      // or return default
      default:
        return defaultRmsPreferenceOptionByKey['rms_only'];
    }
  }
  else {
    // ignore saved preferences
    return defaultPreferenceOptionByKey['rms_display'];
  }
}

export function getOrganisationRmsAvailablePreference(state, organisationId) {
  const preference = getOrganisationRmsDisplayPreferenceOption(state, organisationId).value;
  switch (preference) {
    case 'rms_only':
    case 'mm2_available':
      return true;
    case 'mm2_only':
    default:
      return false;
  }
}

export function getOrganisationMm2AvailablePreference(state, organisationId) {
  const preference = getOrganisationRmsDisplayPreferenceOption(state, organisationId).value;
  switch (preference) {
    case 'mm2_only':
    case 'mm2_available':
      return true;
    case 'rms_only':
    default:
      return false;
  }
}

export function getOrganisationRmsTitle(state, organisationId) {
  const rmsAvailable = getOrganisationRmsAvailablePreference(state, organisationId);
  return rmsAvailable ? 'RMS' : undefined;
}
export function getOrganisationMm2Title(state, organisationId) {
  const rmsAvailable = getOrganisationRmsAvailablePreference(state, organisationId);
  const mm2Available = getOrganisationMm2AvailablePreference(state, organisationId);
  return mm2Available ? (rmsAvailable ? 'RMS - filtered' : 'RMS') : undefined;
}

// check if user has multiple organisations
export function hasMultipleOrganisations(state) {
  const organisations = getOrganisations(state);
  return !!(organisations && organisations.length > 1);
}

// checkout if the user is logged into a particular organisation
export function isLoggedIntoOrganisation(state, organisationId) {
  const currentOrganisation = getCurrentOrganisation(state);
  return !!currentOrganisation && currentOrganisation.id === organisationId;
}

// defines ordered level of org types
const orgTypes = ['Standard', 'Partner', 'MOVUS'];

function isAuthorised(state, { minOrgType }) {
  const orgType = getOrganisationType(state);
  return !!(orgTypes.indexOf(orgType) >= orgTypes.indexOf(minOrgType));
}

export function getOrganisationType(state) {
  // get the sub_domain that the user logged in with
  const organisation = getOrganisation(state);
  if (!organisation) {
    return undefined;
  }
  return organisation.sub_domain && organisation.sub_domain.startsWith('movus.')
    ? 'MOVUS'
    : organisation.is_parent
      ? 'Partner'
      : 'Standard';
}

export function getRootGroupId(state) {
  const organisation = getCurrentOrganisation(state);
  return organisation?._embedded?.groups?.id;
}

// quick selectors for different admins
export function isStandardOrganisation(state) {
  return getOrganisationType(state) === 'Standard';
}
export function isPartnerOrganisation(state) {
  return getOrganisationType(state) === 'Partner';
}
export function isMovusOrganisation(state) {
  return getOrganisationType(state) === 'MOVUS';
}
export function isAtLeastStandardOrganisation(state) {
  return isAuthorised(state, { minOrgType: 'Standard' });
}
export function isAtLeastPartnerOrganisation(state) {
  return isAuthorised(state, { minOrgType: 'Partner' });
}
export function isAtLeastMovusOrganisation(state) {
  return isAuthorised(state, { minOrgType: 'MOVUS' });
}

export const getGroupImpactConfigValues = createSelector(
  getGroup,
  group => group?._embedded?.impact_config?.items
);

export const getGroupImpactConfigState = createSelector(
  getGroup,
  group => group?._embedded?.impact_config?._state_
);

export const getGroupImpactSummary = createSelector(
  getGroup,
  group => group?._embedded?.impact_summary?.items
);

export const getGroupImpactSummaryState = createSelector(
  getGroup,
  group => group?._embedded?.impact_summary?._state_
);

export const getGroupLocale = createSelector(
  getGroup,
  group => group?._embedded?.locale,
);

export const getOrganisationLocale = createSelector(
  getOrganisation,
  organisation => organisation?._embedded?.locale,
);

export const getGroupEmbeddedDataByKey = createSelector(
  state => state,
  (state, groupId, key) => ({ groupId, key }),
  (state, { groupId, key }) => {
    const group = getGroup(state, groupId);
    if(group?._embedded) {
      return group._embedded[key];
    }
  },
);

export const getGroupTags = createSelector(
  getGroup,
  group => group?._embedded?.tags,
);

export const getOrganisationWebhooks = createSelector(
  getOrganisation,
  organisation => organisation?._embedded?.webhooks
);
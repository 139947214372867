import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { submitOrganisationDetails } from '../actions';
import { getOrganisation, getOrganisationState } from '../selectors';
import { getPathParamIntegerFromProps } from '../../selectors';
import OrganisationPageLayout from '../components/OrganisationPageLayout';
import Title from  '../../../components/Title';

function EditOrganisationPreferences(props) {
  const dispatch = useDispatch();
  const organisationId = useSelector(() => getPathParamIntegerFromProps(props, 'id'));
  const organisation = useSelector(state => getOrganisation(state, organisationId), shallowEqual) || {};
  const organisationState = useSelector(state => getOrganisationState(state, organisationId), shallowEqual) || {};
  const { register, unregister, handleSubmit, watch, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      alarm_escalate_timeout: organisation.alarm_escalate_timeout,
    }
  });
  const watchAlarmEscalate = watch('alarm_escalate');
  useEffect(() => {
    // This is used to disable form validation on escalate timeout when alarm escalate is uncheked and enable validation when checked.
    unregister('alarm_escalate_timeout');
  }, [watchAlarmEscalate]);

  const onSubmit = useCallback((formValues) => {
    dispatch(submitOrganisationDetails(organisation, formValues));
  }, [organisation, submitOrganisationDetails]);

  return (
    <OrganisationPageLayout>
      <Title
        title="Alarm channel preferences"
        loading={organisationState.loading}
        lastFetch={organisationState.lastFetch}
        error={organisationState.error}
      ></Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row}>
          <Form.Label column sm="4">
            Escalation Timeout (hours)
          </Form.Label>
          <Col sm="8" className="d-flex align-items-center">
            <Form.Control
              type="number"
              min={0}
              {...register('alarm_escalate_timeout', {
                required: true,
                valueAsNumber: true,
                min: 0,
              })}
              style={{width: '5em'}}
            />
          </Col>
        </Form.Group>
        <Button
          variant="success"
          size="lg"
          className="float-right mb-chat-widget"
          type="submit"
          disabled={!formState.isValid}
        >
          Update
        </Button>
      </form>
    </OrganisationPageLayout>
  );
};

export default EditOrganisationPreferences;
import { useCallback, useMemo } from 'react';
import { Button, Form, Row, Col} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FormModal from '../../../components/FormModal';
import useAlarmImpactSettings from '../hooks/useAlarmImpactSettings';
import useAlarmImpact from '../hooks/useAlarmImpact';
import useDomain from '../../domain/hooks/useDomain';
import { formatCurrency } from '../../../components/lib/utils';

function AlarmImpactModal({impact, alarm = {}, children, currency}) {
  const editMode = !!impact;
  const { register, handleSubmit, reset, formState, watch, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      setting_id: impact?.setting_id || '',
      cost_category: impact?.cost_category || '',
      qty: impact?.qty || 1,
    },
  });
  const formValues = watch();

  const {domainData, fetchImpactCostCategories } = useDomain({ key: 'impactCostCategories', fetch: false, cache: false });
  const impactCostCategoriesItems = domainData || {};
  const impactCostCategories = Object.keys(impactCostCategoriesItems);
  const {
    impactSettings,
    fetchImpactSettings,
  } = useAlarmImpactSettings({ deviceId: alarm.device_id });
  const {
    addImpact,
    updateImpact,
  } = useAlarmImpact(alarm.id);

  const currentImpactSetting = useMemo(() => {
    return impactSettings.find(setting => setting.id === formValues.setting_id);
  }, [formValues.setting_id, impactSettings]);

  const renderEventCategoryHelperText = useMemo(() => {
    const impactSetting = impactSettings.find(setting => setting.id === formValues.setting_id);
    return impactSetting ?
      <>Rate description: {impactSetting.description}. Its unit rate is {formatCurrency(impactSetting.rate, currency)} per {impactSetting.units}.</>
      : <>Please select a rate</>;
  }, [formValues.setting_id]);

  const renderCostCategoryHelperText = useMemo(() => {
    const costCategory = impactCostCategoriesItems[formValues.cost_category];
    return formValues.cost_category && costCategory ?
      <>{costCategory.description} ({costCategory.type})</>
      : <>Please select a category</>;
  }, [formValues.cost_category]);


  const resetForm = useCallback(() => {
    reset({
      setting_id: impact?.setting_id || '',
      cost_category: impact?.cost_category || '',
      qty: impact?.qty || 1,
    });
  }, [impact, reset]);

  const handleShowModal = useCallback(() => {
    resetForm();
    fetchImpactCostCategories().then(() => !editMode && resetForm());
    fetchImpactSettings().then(() => !editMode && resetForm());
  }, [resetForm, fetchImpactCostCategories, fetchImpactSettings]);

  const onSubmit = useCallback(data => {
    const submitImpactData = {
      setting_id: data.setting_id,
      cost_category: data.cost_category,
      qty: data.qty,
    };
    if(editMode) {
      updateImpact(impact.id, submitImpactData);
    } else {
      addImpact(submitImpactData);
    }
  }, [impactSettings, alarm, updateImpact, addImpact]);

  const renderFormHeader = useCallback(() => {
    return (
      <>
        <h3>{editMode ? 'Edit Item' : 'Add Item'}</h3>
      </>
    );
  }, [editMode]);

  const renderForm = useCallback(() => {
    return (
      <Form
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form.Group as={Row}>
          <Form.Label column sm="4">
            Rate
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as="select"
              {...register("setting_id", {
                required: true,
                valueAsNumber: true
              })}
            >
              {impactSettings.map(setting => {
                return (
                  <option key={setting.id} value={setting.id}>
                    {setting.event_category}
                  </option>
                );
              })}
            </Form.Control>
            <Form.Text className="text-muted">{renderEventCategoryHelperText}</Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="4">
            Category
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as="select"
              {...register("cost_category", {
                required: true
              })}
            >
              {impactCostCategories.map((category, index) => {
                return <option key={index} className="text-capitalize" value={category}>{category}</option>;
              })}
            </Form.Control>
            <Form.Text className="text-muted">{renderCostCategoryHelperText}</Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="4">Quantity</Form.Label>
          <Col sm="8">
            <Form.Control
              defaultValue={editMode ? impact.qty : 1}
              {...register("qty", {
                required: true,
                min: 1,
                valueAsNumber: true,
                validate: value => +value > 0 && value*100 === Math.floor(value*100),
              })}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="4">Units</Form.Label>
          <Col sm="8">
            <Form.Control readOnly plaintext value={currentImpactSetting?.units} />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="4">Unit Rate</Form.Label>
          <Col sm="8">
            <Form.Control readOnly plaintext value={formatCurrency(currentImpactSetting?.rate || 0, currency)} />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="4">Total Amount</Form.Label>
          <Col sm="8">
            <Form.Control readOnly plaintext value={formatCurrency((getValues('qty') || 0) * (currentImpactSetting?.rate || 0), currency)} />
          </Col>
        </Form.Group>
      </Form>
    );
  }, [onSubmit, impactSettings, editMode, formValues.setting_id, formValues.cost_category]);

  return (
    <FormModal
      size="md"
      header={renderFormHeader()}
      form={renderForm()}
      onShow={handleShowModal}
      valid={(formState.isValid && formState.isDirty)}
    >
      <Button size="md" variant={editMode ? "outline-secondary" : "primary"} >{children}</Button>
    </FormModal>
  );
}

export default AlarmImpactModal;

import React, { useEffect } from 'react';

import OrganisationPageLayout from '../components/OrganisationPageLayout';
import { fetchOrganisationWithId } from '../actions';
import { connect } from 'react-redux';
import withNavigationOrganisationProps from '../components/withNavigationOrganisationProps';

import { InfoCard, InfoCardItem } from '../../../components/InfoCard';

import {
  absoluteDateFormatter,
} from '../../../components/table/formatters';

import {
  getOrganisation,
} from '../selectors';

function ActivityPage({
  organisationId,
  organisation = {},
  fetchOrganisationWithId,
}) {

  // get up to date user info
  useEffect(() => {
    if (organisationId && Object.keys(organisation).length === 0) {
      fetchOrganisationWithId({ id: organisationId });
    }
  }, [organisationId, fetchOrganisationWithId]);

  return (
    <OrganisationPageLayout>
      <InfoCard heading="Organisation Created">
        <InfoCardItem>
          {absoluteDateFormatter(organisation.created_at)}
        </InfoCardItem>
      </InfoCard>
    </OrganisationPageLayout>
  );
}

const mapStateToProps = (state, { organisationId }) => {
  return {
    organisation: organisationId && getOrganisation(state, organisationId),
  };
};
const mapDispatchToProps = {
  fetchOrganisationWithId,
};

export default withNavigationOrganisationProps(
  connect(mapStateToProps, mapDispatchToProps)(ActivityPage)
);

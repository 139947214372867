import { memo } from 'react';
import HomeCard from './HomeCard';
import { useGroupNonReporting } from '../../modules/organisation/hooks/useGroupStandardData';
import Table from '../Table';
import { lastDataHeard, equipmentNameLink } from './columns';
import { batteryScore, wifiScore } from '../../modules/equipment/columns';
import useDeviceStatusFilter from '../../modules/equipment/hooks/useDeviceStatusFilter';
import { SENSOR_TYPE } from '../../modules/equipment/constants';

const columns = [lastDataHeard, equipmentNameLink, batteryScore, wifiScore];

function NonReportingDevice({ sensorType }) {
  const groupNonReporting = useGroupNonReporting();
  const { filter } = useDeviceStatusFilter();
  const sensorName = sensorType === SENSOR_TYPE.FITPOWER ? "FitPowers" : "FitMachines";

  if(!groupNonReporting) return null;
  const { _state_: {loading, error} = {}, items = []} = groupNonReporting;
  return (
    <HomeCard
      header={`Non reporting ${sensorName}`}
      headerLink={{to: `/equipment/list?type=${sensorType}`, text: `All non reporting ${sensorName}`, onClick: () => filter(['Non-reporting'])}}
      loading={loading}
      error={error}
    >
      <Table
        data={items}
        keyField="device_id"
        loading={loading}
        columns={columns}
        condensed
      />
    </HomeCard>
  );
}

export default memo(NonReportingDevice);

import React, { useEffect } from 'react';
import { IoIosChatboxes } from 'react-icons/io';
import { Alert } from 'react-bootstrap';

import UserPageLayout from '../components/UserPageLayout';
import { fetchUser, fetchUserWithId } from '../actions';
import { connect } from 'react-redux';
import withNavigationUserProps from '../components/withNavigationUserProps';

import { InfoCard, InfoCardItem } from '../../../components/InfoCard';

import {
  absoluteDateFormatter,
} from '../../../components/table/formatters';

import { getOrganisationId } from '../../organisation/selectors';
import {
  getUser,
  getUserOrganisation,
  getUserLastActiveAnywhere,
} from '../selectors';

function ActivityPage({
  userId,
  user = {},
  userOrganisation = {},
  userIsSelf,
  userLastActive,
  fetchUser,
  fetchUserWithId,
}) {

  // get up to date user info
  useEffect(() => {
    if (userIsSelf) {
      fetchUser();
    }
    else if (userId) {
      fetchUserWithId({ id: userId });
    }
  }, [userId, userIsSelf, fetchUser, fetchUserWithId]);

  return (
    // a user can't always see info about their own last activity
    // users, partner admins, and super admins will not have this info
    <UserPageLayout>
      {!userIsSelf && !userLastActive && (
        <Alert variant="info">
          <IoIosChatboxes size="1.4em"/> This user has not logged in yet
        </Alert>
      )}
      {!userIsSelf && userOrganisation && userOrganisation.last_active && (
        <InfoCard heading={`Last Active in ${userOrganisation.organisation_name}`}>
          <InfoCardItem>
            {absoluteDateFormatter(userOrganisation.last_active)}
          </InfoCardItem>
        </InfoCard>
      )}
      <InfoCard heading="Profile Created">
        <InfoCardItem>
          {absoluteDateFormatter(user.created_at)}
        </InfoCardItem>
      </InfoCard>
    </UserPageLayout>
  );
}

const mapStateToProps = (state, { userId, userIsSelf }) => {
  const organisationId = getOrganisationId(state);
  return {
    user: getUser(state, userId),
    userLastActive: getUserLastActiveAnywhere(state, userId),
    userId,
    userIsSelf,
    userOrganisation: organisationId && getUserOrganisation(state, organisationId, userId),
  };
};
const mapDispatchToProps = {
  fetchUser,
  fetchUserWithId,
};

export default withNavigationUserProps(
  connect(mapStateToProps, mapDispatchToProps)(ActivityPage)
);

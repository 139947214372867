import { useEffect, useMemo } from 'react';
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { Button, Form, Row, Col } from 'react-bootstrap';
import EquipmentPageLayout from "../components/EquipmentPageLayout";
import Title from '../../../components/Title';
import useDeviceSettings from "../hooks/useDeviceSettings";

function EquipmentSettings() {
  const { id } = useParams();
  const deviceId = Number(id);

  const { loading, lastFetch, error, settings = {}, updateSettings } = useDeviceSettings(deviceId, { fetch: true });

  const defaultValues = useMemo(() => {
    const values = {};
    for(const key of Object.keys(settings)) {
      values[key] = settings[key].value;
    }
    return values;
  }, [settings]);

  const { register, handleSubmit, reset } = useForm({
    mode: 'onSubmit',
    defaultValues
  });

  useEffect(() => {
    if(defaultValues && Object.keys(defaultValues).length > 0) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const onSubmit = (data) => {
    updateSettings(data);
  };

  return (
    <EquipmentPageLayout>
      <div className="my-4 mx-3">
        <Title title="Advanced Configuration Utility" loading={loading} lastFetch={lastFetch} error={error} />
        <Form onSubmit={handleSubmit(onSubmit)}>
          {
            Object.keys(settings).map(key => {
              const {label, description, value_type, new_section } = settings[key];
              if(value_type === 'heading1') {
                return (
                  <div key={key}>
                    <h4>{label}</h4>
                    {description && <p className="text-muted">{description}</p>}
                  </div>
                );
              }
              return (
                <div key={key}>
                  {new_section && <div className="border-bottom border-secondary mb-3"></div>}
                  <Form.Group as={Row} controlId={key}>
                    <Form.Label column sm="3">
                      {label}
                    </Form.Label>
                    <Col sm="9">
                      {value_type === 'bool' ?
                        <Form.Check type="checkbox" {...register(key)} /> :
                        value_type === 'number' ?
                          <Form.Control
                            type="number"
                            step="any"
                            {...register(key, {valueAsNumber: true})}
                            style={{width: '25%', minWidth: '8em'}}
                          /> :
                          <Form.Control
                            type="text"
                            {...register(key)}
                          />
                      }
                      <Form.Text className="text-muted">{description}</Form.Text>
                    </Col>
                  </Form.Group>
                </div>
              );
            })
          }
          <Button
            variant="success"
            type="submit"
            size="lg"
            className="float-right"
            disabled={loading}
          >Save</Button>
        </Form>
      </div>
    </EquipmentPageLayout>
  );
}

export default EquipmentSettings;
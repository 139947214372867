import { useCallback } from 'react';
import { Button, ButtonGroup } from "react-bootstrap";
import { IoIosCreate, IoIosTrash } from "react-icons/io";
import ConfirmModal from "../../../components/ConfirmModal";
import useUpdateWebhooks from "../hooks/useUpdateWebhooks";
import EndpointFormModal from './EndpointFormModal';

export default function OrganisationWebhookActions({ endpoint }) {
  const { updateWebhooks } = useUpdateWebhooks();

  const handleDeleteWebhook = useCallback(() => {
    updateWebhooks({
      ...endpoint,
      webhook: null,
    });
  }, [updateWebhooks, endpoint]);

  return (
    <ButtonGroup>
      <EndpointFormModal endpoint={endpoint}>
        <Button
          size="md"
          variant="outline-secondary"
        >
          <IoIosCreate size="1.2em" /> Edit
        </Button>
      </EndpointFormModal>
      <ConfirmModal
        header={`Delete this webhook for endpoint: ${endpoint.endpoint}?`}
        body="This cannot be undone"
        confirmText="Delete"
      >
        <Button
          size="md"
          variant="danger"
          onClick={handleDeleteWebhook}
        >
          <IoIosTrash size="1.2em" /> Delete
        </Button>
      </ConfirmModal>
    </ButtonGroup>
  );
}

import React, { Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { IoIosNotifications, IoIosNotificationsOff } from 'react-icons/io';
import moment from 'moment';
import withNavigationDeviceProps from './withNavigationDeviceProps';

// Components
import Slider from '../../../components/Bar';
import VolumeImage from '../../alarm/VolumeImage';
import ToneImage from '../../alarm/ToneImage';
import TemperatureImage from '../../alarm/TemperatureImage';
import RunningStatus from '../components/RunningStatus';

import Rms, { Units as RmsUnits } from '../../../components/values/Rms';
import Temperature from '../../../components/values/Temperature';
import Power from '../../../components/values/Power';

import { InfoCard, InfoCardItem, InfoCardHeader } from '../../../components/InfoCard';
import BasicModal from '../../../components/BasicModal';
import CopyDeviceSerialButton from './CopyDeviceSerialButton';

// Other
import {
  twoDigitsNumberFormatter,
  calibrationFormatter,
  latLngFormatter,
  wifiScoreFormatter,
  batteryScoreFormatter,
} from '../../../components/table/formatters';

import { getDevice, getDevicePlaceholderImage, getDeviceHasProductCode, getDeviceRoleName, getDeviceConditionCurvesKeys, getDeviceConditionCurves } from '../selectors';
import {
  getOrganisationRmsAvailablePreference,
  getOrganisationMm2AvailablePreference,
} from '../../organisation/selectors';
import StatusSummaryIndicator from './StatusSummaryIndicator';
import { ISOIndicator } from '../../../components/charts/ISOTableModal';
import useDeviceLinks from '../hooks/useDeviceLinks';
import DeviceLinks from './DeviceLinks';
import { isFitPower, isFitMachine } from '../utils';
import { percentageNumberFormatter } from '../../../components/table/formatters';

export const fmIconStyle = {
  width: '1.3em',
  verticalAlign: '-20%',
};

function theMegaPluraliser3000(type, number) {
  if (type === 'hour') {
    if (number === 1) {
      return `${number} hour`;
    }
    return `${number} hours`;
  } else {
    if (number === 1) {
      return `${number} minute`;
    }
    return `${number} minutes`;
  }
}

// add descriptions to the InfoCard header
function DeviceInsightsInfoCardHeader({ infoCardChildren, ...props }) {

  // filter children props to those with info
  const childrenProps = React.Children
    .map(infoCardChildren, el => React.isValidElement(el) && el.props)
    .filter(props => props && props.info);

  // don't display if no information is found
  if (!childrenProps.length) {
    return null;
  }

  const buttons = (
    <BasicModal
      header={props.children}
      body={(
        <div>
          <p>
            The Insights card displays the following insights about your equipment:
          </p>
          {childrenProps.map(({ heading, info }) => (
            <div key={heading}>
              <h5>{heading}</h5>
              <p>{info}</p>
            </div>
          ))}
          <div>
            You can read more detailed information about the Insights card or the Equipment Detail
            screen at the <a
              href="https://learn.movus.com.au/knowledge/the-detailed-view"
              target="_blank"
              rel="noopener noreferrer"
            >
              MOVUS Support Centre
            </a>.
          </div>
        </div>
      )}
      footer={({ close }) => (
        <Button onClick={close} variant="secondary">OK</Button>
      )}
    >
      <Button size="sm" variant="outline-secondary">Info</Button>
    </BasicModal>
  );

  return (
    <InfoCardHeader buttons={buttons} {...props} />
  );
}


function EquipmentSidebarInsightsFitPower({ device = {} }) {
  return (
    <InfoCard collapsible heading="Insights" HeaderComponent={DeviceInsightsInfoCardHeader}>
      {device.archived && (
        <InfoCardItem className="list-group-item-danger text-center">
          Archived
        </InfoCardItem>
      )}
      <InfoCardItem heading="Status">
        <StatusSummaryIndicator status={device.status_summary} />
      </InfoCardItem>
      <InfoCardItem
        heading="Running"
        info="Running displays a fan that indicates whether the equipment is running or not. If the equipment is currently running, the fan will spin. If the equipment is currently off the fan will be stationary. If the FitMachine monitoring the equipment has never reported, or last reported more than 8 hours ago, “N/A” is displayed instead of a fan as the equipment running state is unknown."
      >
        <RunningStatus value={device.running} />
      </InfoCardItem>
      {/* <InfoCardItem
        heading="Current"
        info="" // @TODO: Add info for current
      >
        {device.current}
      </InfoCardItem> */}
    </InfoCard>
  );
}

function EquipmentSidebarInsightsFitMachine({ device = {} }) {
  const conditionCurves = useSelector(getDeviceConditionCurves);
  const conditionCurvesKeys = useSelector(getDeviceConditionCurvesKeys);
  const rmsAvailable = useSelector(getOrganisationRmsAvailablePreference);
  const mm2Available = useSelector(getOrganisationMm2AvailablePreference);
  const hasCondCurve = useSelector(state => getDeviceHasProductCode(state, device.id, 'condcurve'));

  return (
    <InfoCard collapsible heading="Insights" HeaderComponent={DeviceInsightsInfoCardHeader}>
      {device.archived && (
        <InfoCardItem className="list-group-item-danger text-center">
          Archived
        </InfoCardItem>
      )}
      <InfoCardItem heading="Status">
        <StatusSummaryIndicator status={device.status_summary} />
      </InfoCardItem>
      {hasCondCurve && <InfoCardItem heading="Condition Correction">{device.tags?.condition_curve || conditionCurves[conditionCurvesKeys[0]]?.name}</InfoCardItem>}
      {(device.is_calibrating || device.calibration < 100) &&
        // show calibration if either calibration is currently happening
        // or the calibration was progressing as a number and is less than 100%
        <InfoCardItem heading="Learning progress">
          {calibrationFormatter(device.calibration, device)}
        </InfoCardItem>
      }
      <InfoCardItem
        heading="Running"
        info="Running displays a fan that indicates whether the equipment is running or not. If the equipment is currently running, the fan will spin. If the equipment is currently off the fan will be stationary. If the FitMachine monitoring the equipment has never reported, or last reported more than 8 hours ago, “N/A” is displayed instead of a fan as the equipment running state is unknown."
      >
        <RunningStatus value={device.running} />
      </InfoCardItem>
      <InfoCardItem
        heading="Condition"
        info="Condition refers to the current health of the equipment. It is calculated by the MOVUS Artificial Intelligence (AI), by comparing current data to baseline values recorded during the learning process."
      >
        <Slider conditionValue={device.condition_indicated} thumbSizeValue={130} />
      </InfoCardItem>
      <InfoCardItem
        heading="AI Volume"
        info="AI Volume refers to the amount of vibration recorded by the FitMachine. E.g. Increasing the volume in an audio system will increase the vibration. The dial displays the deviation from normal, as determined by the MOVUS AI."
      >
        <VolumeImage rms_value={device.condition_vibration} isoClass={device.iso_class}/>
      </InfoCardItem>
      <InfoCardItem
        heading="AI Tone"
        info="AI Tone refers to the frequency profile of the vibration recorded by the FitMachine. E.g. Changing the bass or treble in an audio system will change the frequency profile. The dial displays the deviation from normal, as determined by the MOVUS AI."
      >
        <ToneImage vibration_condition={device.condition_vibration} />
      </InfoCardItem>
      <InfoCardItem
        heading="AI Temperature"
        info="AI Temperature refers to the temperature recorded by the FitMachine. The dial displays the deviation from normal, as determined by the MOVUS AI."
      >
        <TemperatureImage temperature_condition={device.condition_temperature} />
      </InfoCardItem>
      {!!rmsAvailable && (
        // user can see rms
        <InfoCardItem
          heading="Vibration (RMS)"
          info={(
            <Fragment>
              Vibration (RMS)
              displays the current vibration (in <RmsUnits />) recorded by the FitMachine.
            </Fragment>
          )}
        >
          <Rms value={device.rms} />
        </InfoCardItem>
      )}
      {!!mm2Available && (!rmsAvailable ? (
        // user can see only mm2
        <InfoCardItem
          heading="Vibration (RMS)"
          info="Vibration (RMS) displays the current vibration recorded by the FitMachine. This RMS value includes frequencies up to 160 Hz."
        >
          {twoDigitsNumberFormatter(device.rms2)}
        </InfoCardItem>
      ) : (
        // user can see both rms and mm2
        <InfoCardItem
          heading="Vibration (RMS - filtered)"
          info="Vibration (RMS) displays the current vibration recorded by the FitMachine. This RMS value includes frequencies up to 160 Hz."
        >
          {twoDigitsNumberFormatter(device.rms2)}
        </InfoCardItem>
      ))}
      <InfoCardItem
        heading="Temperature"
        info="Temperature displays the ambient temperature in the area the FitMachine is located. Ambient temperature is recorded in °C or °F, depending on your User Profile preferences. The FitMachine temperature sensor is located inside the FitMachine and not at the base of the magnet. It is useful for understanding the temperature of the environment the equipment operates in, however it is a lagging indicator and is not best suited to external cooling or rapidly changing temperatures."
      >
        <Temperature value={device.temperature} />
      </InfoCardItem>
      <InfoCardItem
        heading="Utilisation (4 weeks)"
        info="An average of how often the equipment has been above the running cut-off in the last 4 weeks (and thus considered to be running)."
      >
        {percentageNumberFormatter(device.utilisation_month)}
      </InfoCardItem>
    </InfoCard>
  );
}

function EquipmentSidebar({ device={}, placeholder, deviceRoleName }) {

  const { links = [] } = useDeviceLinks(device?.id, { fetch: false });

  if (!device || !device.id) {
    return null;
  }
  const isFMSensor = isFitMachine(device);
  const isFPSensor = isFitPower(device);

  return (
    <Fragment>
      <h5 className="montserrat" style={{marginTop: "10px", marginBottom: "15px"}}>
        {`${device.site_name} - ${device.sub_area_name} - ${device.equipment_name}`}
      </h5>
      <p>
        Monitored by: <span className="d-inline-block mr-1">
          <Link to={`/devices/${device.id}`}>
            <img
              src={placeholder}
              style={fmIconStyle}
              alt="FitMachine"
            /> {device.serial}
          </Link>
        </span>
        <CopyDeviceSerialButton device={device} />
      </p>
      {links.length > 0 && <DeviceLinks device={device} />}
      {isFMSensor && <EquipmentSidebarInsightsFitMachine device={device} />}
      {isFPSensor && <EquipmentSidebarInsightsFitPower device={device} />}
      <InfoCard collapsible heading="Quick Info" initialCollapsed>
        <InfoCardItem heading="Last data processed">
          {moment(device.fitmachine_last_heard).format("ll")}
        </InfoCardItem>
        {isFMSensor && <InfoCardItem heading="Notifications status">
          {device.mute_advisory_for === 0 ? (
            <IoIosNotifications size="1.4em" />
          ) : (
            <IoIosNotificationsOff size="1.4em" />
          )}
        </InfoCardItem>}
        <InfoCardItem heading="Network health">
          <div className="d-flex">
            {wifiScoreFormatter(device.wifi_score, device)}
          </div>
        </InfoCardItem>
        {isFMSensor && <InfoCardItem heading="Battery health">
          <div className="d-flex">
            {batteryScoreFormatter(device.battery_score, device)}
          </div>
        </InfoCardItem>}
        {isFMSensor && device?.fmconfig && <InfoCardItem
          heading={"Sampling interval"}
        >
          <div className="d-flex">
            {device.fmconfig['sample_interval']['hour'] ? theMegaPluraliser3000('hour', device.fmconfig['sample_interval']['hour']) : ""}
            {" "}
            {device.fmconfig['sample_interval']['minute'] ? theMegaPluraliser3000('minute', device.fmconfig['sample_interval']['minute']) : ""}
          </div>
        </InfoCardItem>}
        {isFMSensor && device?.fmconfig && <InfoCardItem
          heading={"Reporting interval"}
        >
          <div className="d-flex">
            {device.fmconfig['report_interval']['hour'] ? theMegaPluraliser3000('hour', device.fmconfig['report_interval']['hour']) : ""}
            {" "}
            {device.fmconfig['report_interval']['minute'] ? theMegaPluraliser3000('minute', device.fmconfig['report_interval']['minute']) : ""}
          </div>
        </InfoCardItem>}
        <InfoCardItem heading="Power rating">
          <Power value={device.power_rating} />
        </InfoCardItem>
        <InfoCardItem heading={(
          <Fragment>
            <span>MAC address</span>
            {' '}
            <CopyDeviceSerialButton device={device} />
          </Fragment>
        )}>
          {device.serial}
        </InfoCardItem>
        <InfoCardItem heading="Variable speed drive">
          {device.variable_speed_drive ? 'Yes' : 'No'}
        </InfoCardItem>
        <InfoCardItem heading="Confined space">
          {device.confined_space ? 'Yes' : 'No'}
        </InfoCardItem>
        {isFMSensor && <InfoCardItem heading="Learning start date">
          {moment(device.calibration_start).format("ll")}
        </InfoCardItem>}
        <InfoCardItem heading="Notes">
          {device.note}
        </InfoCardItem>
      </InfoCard>
      <InfoCard collapsible heading="Equipment Details" initialCollapsed>
        <InfoCardItem heading="Equipment name">
          {device.equipment_name}
        </InfoCardItem>
        <InfoCardItem heading="Equipment number">
          {device.equipment_number}
        </InfoCardItem>
        {device?.tags && device?.tags?.criticality && <InfoCardItem heading="Criticality">
          {device.tags.criticality}
        </InfoCardItem>}
        <InfoCardItem heading="Equipment brand">
          {device.equipment_brand}
        </InfoCardItem>
        <InfoCardItem heading="Equipment model">
          {device.equipment_model}
        </InfoCardItem>
        <InfoCardItem heading="Equipment type">
          {device.equipment_type}
        </InfoCardItem>
        <InfoCardItem heading="Equipment role">
          {deviceRoleName || device.role}
        </InfoCardItem>
        <InfoCardItem heading="Capacity">
          {device.equipment_capacity}
        </InfoCardItem>
        <InfoCardItem heading="Power rating">
          <Power value={device.power_rating} />
        </InfoCardItem>
        {isFMSensor && <InfoCardItem heading="ISO class">
          <div className="d-flex align-items-center">
            {device.iso_class}&nbsp;&nbsp;
            <ISOIndicator device={device} />
          </div>
        </InfoCardItem>}
        <InfoCardItem heading="Max RPM">
          {device.max_rpm}
        </InfoCardItem>
        <InfoCardItem heading="Variable speed drive">
          {device.variable_speed_drive ? 'Yes' : 'No'}
        </InfoCardItem>
        <InfoCardItem heading="Confined space">
          {device.confined_space ? 'Yes' : 'No'}
        </InfoCardItem>
        <InfoCardItem heading="Running">
          <RunningStatus value={device.running} />
        </InfoCardItem>
        {isFMSensor && <InfoCardItem heading="Running cutoff">
          <Rms value={device.running_cutoff} />
        </InfoCardItem>}
        <InfoCardItem heading="Notes">
          {device.note}
        </InfoCardItem>
      </InfoCard>
      <InfoCard collapsible heading="Location Details" initialCollapsed>
        <InfoCardItem heading="Organisation name">
          {device.organisation_name}
        </InfoCardItem>
        <InfoCardItem heading="Site name">
          {device.site_name}
        </InfoCardItem>
        <InfoCardItem heading="Sub-area name">
          {device.sub_area_name}
        </InfoCardItem>
        <InfoCardItem heading="Latitude">
          {latLngFormatter(parseFloat(device.latitude))}
        </InfoCardItem>
        <InfoCardItem heading="Longitude">
          {latLngFormatter(parseFloat(device.longitude))}
        </InfoCardItem>
      </InfoCard>
      {links.length === 0 && <DeviceLinks device={device} />}
    </Fragment>
  );
}

const mapStateToProps = (state, { deviceId }) => ({
  device: getDevice(state, deviceId),
  placeholder: getDevicePlaceholderImage(state, deviceId),
  rmsAvailable: getOrganisationRmsAvailablePreference(state),
  mm2Available: getOrganisationMm2AvailablePreference(state),
  hasCondCurve: getDeviceHasProductCode(state, deviceId, 'condcurve'),
  deviceRoleName: getDeviceRoleName(state, deviceId),
  conditionCurves: getDeviceConditionCurves(state),
  conditionCurvesKeys: getDeviceConditionCurvesKeys(state),
});

export default withNavigationDeviceProps(
  connect(mapStateToProps)(EquipmentSidebar)
);


import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import {
  IoIosAddCircleOutline,
  IoIosCloud,
  IoIosRadio,
  IoIosLink,
} from 'react-icons/io';

import TableToolbar from '../../../components/TableToolbar';

import AddOrganisationTokenFormModal from './AddOrganisationTokenFormModal';
import AddStreamingConfigurationFormModal from './AddStreamingConfigurationFormModal';
import EndpointFormModal from './EndpointFormModal';
import { getCurrentOrganisationHasProductCode } from '../selectors';

const buttonProps = {
  target: "_blank",
  rel: "noopener noreferrer",
  variant: "outline-secondary",
};

const AddButton = ({ organisationId }) => {
  const hasStreamingFeature = useSelector(state => getCurrentOrganisationHasProductCode(state, 'streaming_api'));
  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" className="pl-1">
        <IoIosAddCircleOutline size="1.4em" /> Add{' '}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <AddOrganisationTokenFormModal organisationId={organisationId}>
          <Dropdown.Item>
            <IoIosCloud size="1.2em" /> <span>Add API Token</span>
          </Dropdown.Item>
        </AddOrganisationTokenFormModal>
        {hasStreamingFeature && (
          <AddStreamingConfigurationFormModal organisationId={organisationId}>
            <Dropdown.Item>
              <IoIosRadio size="1.2em" /> <span>Add Streaming Configuration</span>
            </Dropdown.Item>
          </AddStreamingConfigurationFormModal>
        )}
        <EndpointFormModal>
          <Dropdown.Item>
            <IoIosLink size="1.2em" />
            <span>Add Webhook Endpoint</span>
          </Dropdown.Item>
        </EndpointFormModal>
      </Dropdown.Menu>
    </Dropdown>
  );
};

function DeveloperCentreToolbar({
  title="Developers Admin",
  tableProps,
  loading,
  organisationId,
  subTitle,
}) {

  const hasStreamingFeature = useSelector(state => getCurrentOrganisationHasProductCode(state, 'streaming_api'));
  return (
    <TableToolbar
      searchable
      title={title}
      subTitle={subTitle}
      loading={loading}
      buttonGroups={[
        [<AddButton key="add" organisationId={organisationId} />],
        [
          <LinkContainer key="tokens" to="/developer/admin/tokens">
            <Button variant="outline-secondary">API Tokens</Button>
          </LinkContainer>,
          hasStreamingFeature && <LinkContainer key="streaming" to="/developer/admin/streaming">
            <Button variant="outline-secondary">Streams</Button>
          </LinkContainer>,
          <LinkContainer key="webhooks" to="/developer/admin/webhooks">
            <Button variant="outline-secondary">Webhooks</Button>
          </LinkContainer>,
          <Button
            key="documentation"
            href="https://w3.movus.com.au/apidocs/index.html"
            {...buttonProps}
          >
            API Documentation
          </Button>,
          <Button
            key="support"
            href="https://learn.movus.com.au/knowledge/understanding-developer-centre"
            {...buttonProps}
          >
            Support
          </Button>,
        ].filter(Boolean),
      ]}
      tableProps={tableProps}
    />
  );
}

export default DeveloperCentreToolbar;

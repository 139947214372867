import React, { useEffect, useRef, useCallback } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { Row, Col, Button, DropdownButton, ButtonGroup, Dropdown, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IndexLinkContainer } from 'react-router-bootstrap';
import { withRouter, matchPath } from 'react-router-dom';
import { Icon, Breadcrumbs } from '@blueprintjs/core';
import NotificationCenter from '../modules/notification/components/NotificationCenter';

import './header.scss';

import {
  IoIosContact,
  IoIosHome,
  IoIosMap,
  IoIosNotifications,
  IoIosRadio,
  IoIosLaptop,
  IoIosLogOut,
  IoIosMenu,
  IoIosPersonAdd,
  IoLogoUsd,
  IoIosPaper,
} from "react-icons/io";

import IoIosFactory from './IoIosFactory';
import IoIosMotor from './IoIosMotor';

import { logout, submitUserPreferences, fetchUserPreferences } from '../modules/user/actions';
import Private from './Private';
import { getUser, getUserTag, isUserLoggedIn } from '../modules/user/selectors';
import {
  getActiveGroupId,
  getGroupTreePath,
  getOrganisationGroup,
  getCurrentOrganisationHasProductCode,
} from '../modules/organisation/selectors';
import { headerHeight } from '../modules/app/actions';
import { usePoll } from '../hooks/usePoll';
import { getIntervalMilliSeconds } from './lib/utils';
import useHomeView, { HOME_VIEW } from '../hooks/useHomeView';
import { debounce } from '../lib/utils';

const blueprintIconStyle = {
  width: '1.2em',
  marginRight: '0.3em',
  textAlign: 'center',
};

const { REACT_APP_CHECK_USER_INTERVAL_MINUTES = 30 } = process.env;
const INTERVAL = getIntervalMilliSeconds(REACT_APP_CHECK_USER_INTERVAL_MINUTES);

function HeaderButton({ tooltipText, to, Icon, children, matchTo, ...buttonProps }) {
  const LinkContainer = to ? IndexLinkContainer : 'div';

  // match beginning of path
  const getIsActive = useCallback((match, { pathname='' }={}) => {
    return matchPath(pathname, { path: matchTo });
  }, [matchPath, matchTo]);

  // create consistently styled buttons
  const button = (
    <LinkContainer {...(to ? { to, isActive: getIsActive} : {})}>
      <Button size="sm" variant="outline-secondary" {...buttonProps}>
        {Icon && (
          <Icon size="2em" />
        )} {children && (
          <span>
            {children}
          </span>
        )}
      </Button>
    </LinkContainer>
  );

  // display button inside a tooltip trigger or not
  return !tooltipText ? button : (
    <OverlayTrigger
      placement="bottom"
      overlay={(
        <Tooltip>
          {tooltipText}
        </Tooltip>
      )}
    >
      {button}
    </OverlayTrigger>
  );
}

function HeaderBreadcrumbs() {
  const activeGroupId = useSelector(getActiveGroupId);
  const organisationGroup = useSelector(getOrganisationGroup, shallowEqual);
  // If there's group selected, show the group's path; otherwise(no group selected, i.e. user just logged in), show the top group name.
  const treePath = useSelector(state => getGroupTreePath(state, activeGroupId), shallowEqual) || (organisationGroup ? [organisationGroup] : []);

  return (
    <Breadcrumbs items={
      treePath.map(item => ({ text: item.name }))
    } />
  );
}

function UserHeader(props) {

  const {
    userIsLoggedIn,
    user,
    expanded,
    toggleExpanded,
    hasEquipmentMapFeature,
    hasNetworkFeature,
    hasOrganisationTokenFeature,
    hasBillingCentre,
    hasSelectedableDash,
    headerHeight,
    fetchUserPreferences,
  } = props;
  const headerRef = useRef(null);
  const { view, setView } = useHomeView();  // @TODO: Refactor to useLocalPreference hook.

  useEffect(() => {
    const handleHeaderHeight = debounce(() => {
      headerHeight(headerRef.current?.offsetHeight || 0);
    }, 500);
    window.addEventListener('resize', handleHeaderHeight);
    return () => {
      window.removeEventListener('resize', handleHeaderHeight);
    };
  }, []);

  usePoll(() => {fetchUserPreferences(user);}, [], { interval: INTERVAL, skipFirstTime: true });

  if (!userIsLoggedIn) {
    return null;
  }
  return (
    <div ref={headerRef}>
      <Container fluid>
        <Row className="small-gutters mt-1 pt-1 mb-1">
          <Col xs="auto">
            <Row className="small-gutters">
              <Col className={`mb-1 ${expanded !== false ? 'd-md-none' : ''}`} xs="auto">
                <HeaderButton
                  tooltipText={!expanded ? 'Views' : ''}
                  Icon={IoIosMenu}
                  onClick={toggleExpanded}
                />
              </Col>
              <Col className="mb-1" xs="auto">
                <ButtonGroup>
                  <HeaderButton
                    tooltipText="Home"
                    Icon={IoIosHome}
                    to="/home"
                    matchTo="/home"
                  />
                  {hasSelectedableDash && <DropdownButton as={ButtonGroup} menuAlign="left" variant="outline-secondary" title="" size="sm">
                    <Dropdown.Header>Choose a view</Dropdown.Header>
                    <IndexLinkContainer to="/home" isActive={(match) => match && view===HOME_VIEW.STANDARD}>
                      <Dropdown.Item onClick={() => setView(HOME_VIEW.STANDARD)}>
                        Standard
                      </Dropdown.Item>
                    </IndexLinkContainer>
                    {hasSelectedableDash && <>
                      <IndexLinkContainer to="/home" isActive={(match) => match && view===HOME_VIEW.PLANT}>
                        <Dropdown.Item onClick={() => setView(HOME_VIEW.PLANT)}>
                          Plant Room
                        </Dropdown.Item>
                      </IndexLinkContainer>
                      <IndexLinkContainer to="/home" isActive={(match) => match && view===HOME_VIEW.MANAGEMENT}>
                        <Dropdown.Item onClick={() => setView(HOME_VIEW.MANAGEMENT)}>
                          Management
                        </Dropdown.Item>
                      </IndexLinkContainer>
                    </>}
                  </DropdownButton>}
                </ButtonGroup>
              </Col>
              <Col className="mb-1" xs="auto">
                <ButtonGroup size="sm" data-toggle="buttons">
                  {hasEquipmentMapFeature && (
                    <HeaderButton
                      tooltipText="Equipment Map"
                      Icon={IoIosMap}
                      to="/equipment/map"
                      matchTo="/equipment/map"
                    />
                  )}
                  <HeaderButton
                    tooltipText="Equipment List"
                    Icon={IoIosMotor}
                    to="/equipment/list"
                    matchTo="/equipment/list"
                  />
                  <HeaderButton
                    tooltipText="Alarms"
                    Icon={IoIosNotifications}
                    to="/alarms/list/condition"
                    matchTo="/alarms/list/:alarmType"
                  />
                  <HeaderButton
                    tooltipText="Events"
                    Icon={IoIosPaper}
                    to="/events"
                    matchTo="/events"
                  />
                </ButtonGroup>
              </Col>
            </Row>
          </Col>
          {/*  when this column overflows, ensure is is right-aligned using ml-auto */}
          <Col className="ml-auto" xs="auto">
            {/* align items in this row to the right */}
            <Row className="small-gutters justify-content-end">
              <Private minUserType="Admin">
                <Col className="mb-1" xs="auto">
                  <HeaderButton
                    to="/users/new"
                    Icon={IoIosPersonAdd}
                  >
                    Invite User
                  </HeaderButton>
                </Col>
              </Private>
              <Col className="mb-1" xs="auto">
                <ButtonGroup>
                  <HeaderButton to="/users/me" Icon={IoIosContact}>
                    Me
                  </HeaderButton>
                  <DropdownButton as={ButtonGroup} menuAlign="right" variant="outline-secondary" title="">
                    <Private minUserType="Admin">
                      <Dropdown.Header>Admin Tools</Dropdown.Header>
                      <IndexLinkContainer to="/devices/admin">
                        <Dropdown.Item>
                          <IoIosRadio size="1.2em" /> <span>Sensor Admin</span>
                        </Dropdown.Item>
                      </IndexLinkContainer>
                      {hasNetworkFeature && (
                        <IndexLinkContainer to="/gateways/admin">
                          <Dropdown.Item>
                            <Icon
                              iconSize="1em"
                              icon="graph"
                              title="Network"
                              style={blueprintIconStyle}
                            /> <span>Network</span>
                          </Dropdown.Item>
                        </IndexLinkContainer>
                      )}
                      <IndexLinkContainer to="/users/admin">
                        <Dropdown.Item>
                          <Icon
                            iconSize="1em"
                            icon="people"
                            title="Users"
                            style={blueprintIconStyle}
                          /> <span>Users</span>
                        </Dropdown.Item>
                      </IndexLinkContainer>
                      <IndexLinkContainer to="/organisations/admin">
                        <Dropdown.Item>
                          <IoIosFactory size="1.2em" /> <span>Organisations</span>
                        </Dropdown.Item>
                      </IndexLinkContainer>
                      {hasOrganisationTokenFeature && (
                        <IndexLinkContainer to="/developer/admin/tokens">
                          <Dropdown.Item>
                            <IoIosLaptop size="1.2em" /> <span>Developers</span>
                          </Dropdown.Item>
                        </IndexLinkContainer>
                      )}
                      {hasBillingCentre && (
                        <IndexLinkContainer to="/billing">
                          <Dropdown.Item>
                            <IoLogoUsd size="1.2em" /> <span>Billing</span>
                          </Dropdown.Item>
                        </IndexLinkContainer>
                      )}
                      <Dropdown.Divider />
                    </Private>
                    <Dropdown.Item onClick={() => props.logout()}>
                      <IoIosLogOut size="1.2em" /> <span>Logout</span>
                    </Dropdown.Item>
                  </DropdownButton>
                </ButtonGroup>
              </Col>
              <Col className="mb-1" xs="auto">
                <NotificationCenter />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="small-gutters mt-1 pt-1 mb-1">
          <HeaderBreadcrumbs />
        </Row>
      </Container>
    </div>
  );
}

function Header(props) {
  const { userIsLoggedIn } = props;
  // show the header only if the user is logged in
  return userIsLoggedIn ? (
    <UserHeader {...props} />
  ) : null;
}

const mapStateToProps = state => {
  return {
    userIsLoggedIn: isUserLoggedIn(state),
    user: getUser(state),
    whatsNewHighlight: getUserTag(state, 'whats_new_highlight'),
    hasEquipmentMapFeature: getCurrentOrganisationHasProductCode(state, 'map_views'),
    hasNetworkFeature: getCurrentOrganisationHasProductCode(state, 'network_centre'),
    hasOrganisationTokenFeature: getCurrentOrganisationHasProductCode(state, 'token_api'),
    hasBillingCentre: getCurrentOrganisationHasProductCode(state, 'billing_centre'),
    hasSelectedableDash: getCurrentOrganisationHasProductCode(state, 'select_dash'),
    hasFitPower: getCurrentOrganisationHasProductCode(state, 'fitpower'),
  };
};

const mapDispatchToProps = {
  logout,
  headerHeight,
  submitUserPreferences,
  fetchUserPreferences,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

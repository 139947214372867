
import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';


import {
  IoIosTrash,
} from "react-icons/io";

import { archiveUser } from '../actions';

import ConfirmModal from '../../../components/ConfirmModal';
import Private from '../../../components/Private';

import { getUser } from '../selectors';

function ArchiveUserModal({
  user,
  archiveUser,
  onSuccess,
  onError,
  ...props
}) {

  const onClick = useCallback(async () => {
    try {
      await archiveUser(user);
      if (onSuccess) {
        onSuccess(user);
      }
    }
    catch(e) {
      if (onError) {
        onError(user);
      }
    }
  }, [archiveUser, user]);

  return (
    <Private minUserType="Admin">
      <ConfirmModal
        confirmText="Archive"
        body={(
          <Fragment>
            <p>
              This change will take effect immediately. Archived users will no longer be able to log in or use the MachineCloud Dashboard, FitMachine Mobile App or MachineCloud API. They will no longer receive any system emails.
            </p>
            <p>
              Actions taken by the archived user will be preserved. For example, the archived user’s name will still be attached to any FitMachines they previously onboarded.
            </p>
            <p>
              Archived users can only be restored by contacting MOVUS Support.
            </p>
          </Fragment>
        )}
        {...props}
      >
        <Dropdown.Item onClick={onClick}>
          <IoIosTrash size="1.5em" /> Archive
        </Dropdown.Item>
      </ConfirmModal>
    </Private>
  );
}

const mapStateToProps = (state, { userId }) => {
  return {
    user: getUser(state, userId),
  };
};
const mapDispatchToProps = { archiveUser };

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveUserModal);

import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import {
  fetchGroupMembers,
  fetchGroupAccess,
} from '../actions';

import {
  getGroupUsers,
  getGroupDevices,
  getGroupAccessUsers,
  getGroupAccessGroups,
  getGroup,
  getActiveGroupNodeInfo,
} from '../selectors';

function EditGroupSummary({ isUserGroup }) {

  return (
    <Alert
      role={undefined}
      variant="info"
      className="mb-2"
    >
      <Row className="small-gutters align-items-center">
        <Col xs="auto">
          Editing {isUserGroup ? 'user' : 'equipment'} group access
        </Col>
        <Col className="ml-auto" xs="auto">
          <Row className="small-gutters align-items-center">
            <Col className="ml-auto" xs="auto">
              <LinkContainer to="/group/access">
                <Button size="sm">Back to access summary</Button>
              </LinkContainer>
            </Col>
          </Row>
        </Col>
      </Row>
    </Alert>
  );
}

const mapStateToProps = (state, { groupId }) => {
  const {
    isUserGroup,
  } = getActiveGroupNodeInfo(state);
  return {
    isUserGroup,
    groupName: (getGroup(state, groupId) || {}).group_name,
    users: getGroupUsers(state, groupId),
    devices: getGroupDevices(state, groupId),
    accessUsers: getGroupAccessUsers(state, groupId),
    accessGroups: getGroupAccessGroups(state, groupId),
  };
};

const mapDispatchToProps = {
  fetchGroupMembers,
  fetchGroupAccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupSummary);

import { useEffect, useMemo, useCallback } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { formatCurrency } from '../../../components/lib/utils';
import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';
import { IoIosTrash, IoIosCreate, IoIosAddCircleOutline } from 'react-icons/io';
import ConfirmModal from '../../../components/ConfirmModal';
import AlarmImpactSettingsModal from './AlarmImpactSettingsModal';
import useAlarmImpactSettings from '../hooks/useAlarmImpactSettings';
import useLocale from '../../../hooks/useLocale';

function DeleteImpactSettingButton({ onDeleteImpactSetting, disabled }) {
  return (
    <ConfirmModal
      header="Delete the rate?"
      confirmText="Delete"
    >
      <Button size="md" variant="outline-secondary" onClick={() => { onDeleteImpactSetting(); }} disabled={disabled}>
        <IoIosTrash size="1.2em" /><span>Delete</span>
      </Button>
    </ConfirmModal>
  );
};

function ImpactSettings({ deviceId, groupId }) {
  const { impactSettings = [],
    loading,
    error,
    lastFetch,
    device,
    fetchImpactSettings,
    deleteImpactSetting,
  } = useAlarmImpactSettings({ deviceId, groupId });

  useEffect(() => {
    fetchImpactSettings();
  }, [deviceId, groupId]);

  const { currency } = useLocale({deviceId, groupId});

  const columns = useMemo(() => {
    return [
      {
        dataField: 'event_category',
        text: 'Rate',
      },
      {
        dataField: 'description',
        text: 'Description',
      },
      {
        dataField: 'units',
        text: 'Units',
      },
      {
        dataField: 'rate',
        text: 'Unit Rate',
        align: 'right',
        headerAlign: 'right',
        formatter: cell => formatCurrency(Number(cell), currency)
      },
      {
        dataField: 'actions',
        text: 'Actions',
        align: 'right',
        headerAlign: 'right',
        formatter: (value, row) => {
          return (
            <div>
              <ButtonGroup>
                <AlarmImpactSettingsModal config={row} deviceId={deviceId} groupId={groupId} disabled={row.read_only}>
                  <IoIosCreate size="1.2em" />
                  <span>Edit</span>
                </AlarmImpactSettingsModal>
                <DeleteImpactSettingButton
                  onDeleteImpactSetting={() => {deleteImpactSetting(row);}}
                  disabled={row.read_only}
                />
              </ButtonGroup>
            </div>
          );
        },
      },
    ];
  }, [deviceId, currency]);

  const renderHeader = useCallback(props => {
    return (
      <TableToolbar
        searchable
        title="Event Impact Rates"
        loading={loading}
        lastFetch={lastFetch}
        error={error}
        tableProps={props}
        buttons={[
          <AlarmImpactSettingsModal key="add" deviceId={deviceId} groupId={groupId}>
            <IoIosAddCircleOutline size="1.4em" />{' '}
            <span>Add Rate</span>
          </AlarmImpactSettingsModal>,
        ]}
      />
    );
  }, [loading, lastFetch, error]);

  // return archived status message
  if (device?.archived) {
    return (
      <>
        <p>
          Impact configuration is not currently available for archived equipment.
        </p>
      </>
    );
  }

  return (
    <div className="px-0">
      <Table
        keyField="id"
        renderHeader = {renderHeader}
        data={impactSettings}
        noDataIndication={() => 'No event impact rates.'}
        columns={columns}
        loading={loading}
      />
    </div>
  );
}

export default ImpactSettings;
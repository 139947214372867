import React, { useCallback, useMemo, useState, } from 'react';
import { connect, useSelector } from 'react-redux';
import { Container, Button, ButtonGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { IoIosNotificationsOutline } from "react-icons/io";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { fetchAlarms } from '../actions';

import { calculateLevel } from '../VolumeImage';

import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';
import { useColumnsWithVisibility } from '../../../components/table/utils';
import EmptyCard from '../../../components/EmptyCard';

import {
  rms,
  mm2,
  temperature,
  siteName,
  subAreaName,
  equipmentName,
  equipmentNumber,
  organisationName,
  conditionOverall,
  conditionVolume,
  conditionVibration,
  conditionTemperature,
} from '../../equipment/columns';

import {
  alarmTimestamp,
  alarmStatusExtra as statusBadge,
  alarmTypeExtra as alarmTypeBadge,
  current,
} from '../columns';
import useOrganisationUserColumns from '../../organisation/hooks/useOrganisationUserColumns';
import { getAlarms, getAlarmListState } from '../selectors';
import {
  getOrganisationMm2AvailablePreference,
  getOrganisationRmsAvailablePreference,
  getActiveSubGroupId,
  getCurrentOrganisationHasProductCode,
} from '../../organisation/selectors';
import { fetchDevices } from '../../equipment/actions';
import { usePoll } from '../../../hooks/usePoll';
import { getIntervalMilliSeconds } from '../../../components/lib/utils';
import useIncludeSubGroup from '../../../hooks/useIncludeSubGroup';
import useGroup from '../../organisation/hooks/useGroup';
import DownloadTableCSVLink from '../../../components/DownloadTableCSVLink';
import AlarmDateFilter, { DATE_RANGE } from '../components/AlarmDateFilter';
import ColumnsManager, { reorderColumns } from '../../../components/ColumnsManager';
import useLocalPreference from '../../user/hooks/useLocalPreference';

const { REACT_APP_CHECK_ORGANISATION_INTERVAL_MINUTES = 5 } = process.env;
const checkPeriods = getIntervalMilliSeconds(REACT_APP_CHECK_ORGANISATION_INTERVAL_MINUTES);

export function NoDataIndication() {
  return (
    <EmptyCard Icon={IoIosNotificationsOutline}>
      <EmptyCard.Body>
        <EmptyCard.Title>Your equipment hasn’t triggered any alarms yet.</EmptyCard.Title>
        <EmptyCard.Text>
          To begin triggering alarms,
          ensure an admin has completed deploying all devices onto the equipment.
        </EmptyCard.Text>
        <EmptyCard.Text>
          Once you start receiving alarms,
          they will be listed here in the Alarms Log.
        </EmptyCard.Text>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  );
}

const defaultSorted = [{
  dataField: 'alarm_timestamp',
  order: 'desc'
}];

const alarmTypeFilter = 'condition';

const columnsArr = [
  alarmTimestamp,
  current,
  alarmTypeBadge,
  siteName,
  { ...subAreaName, dataField: 'site_subarea_name' },
  equipmentName,
  equipmentNumber,
  statusBadge,
  organisationName,
  conditionOverall,
  conditionVolume,
  conditionVibration,
  conditionTemperature,
  rms,
  mm2,
  temperature,
];

export function useTableProps({
  alarmTypeFilter,
  activeSubGroupId,
  alarms=[],
  fetchAlarms,
  fetchDevices,
  loading: loadingAlarms,
  lastFetch,
  error,
  columnsArr,
}) {

  const { state: { loading: loadingGroup }} = useGroup(); // Get the loading state of loading group members.
  const loading = loadingGroup || loadingAlarms;
  const tableName = 'alarm_list';
  const { columns: organisationUserColumns } = useOrganisationUserColumns({ showTag: tableName });

  const { includeSubGroup, setIncludeSubGroup } = useIncludeSubGroup('alarm');

  const { preference: columnsPreference } = useLocalPreference({ key: tableName });

  function makeColumns() {
    const equipmentNumberColumnIndex = columnsArr.findIndex(col => col.dataField === 'equipment_number');
    // Fow now, we want to put user columns after equipment number. It's subject to change though.
    return equipmentNumberColumnIndex < 0 ? [...columnsArr, ...organisationUserColumns] :
      [
        ...columnsArr.slice(0, equipmentNumberColumnIndex + 1),
        ...organisationUserColumns,
        ...columnsArr.slice(equipmentNumberColumnIndex + 1)
      ];
  }
  const alarmColumns = makeColumns();

  const columns = useMemo(() => {
    return reorderColumns(columnsPreference, alarmColumns);
  }, [columnsArr, organisationUserColumns, columnsPreference]);

  usePoll(
    canceller => fetchAlarms({ type: alarmTypeFilter }, canceller),
    [fetchAlarms, alarmTypeFilter],
    { cancellable: true, interval: checkPeriods }
  );

  usePoll(
    () => activeSubGroupId && fetchDevices({ includeChildren: includeSubGroup }),
    [fetchDevices, activeSubGroupId, includeSubGroup],
    { interval: checkPeriods, includeSubGroup }
  );
  const hasUserAlarms = useSelector(state => getCurrentOrganisationHasProductCode(state, 'user_alarms'));
  const hasThresholdAlarms = useSelector(state => getCurrentOrganisationHasProductCode(state, 'rms_tmp_alarms'));

  const [dateRange, setDateRange] = useState(DATE_RANGE.ALL);
  const filteredAlarms = useMemo(() => {
    let cutoffDate;
    switch(dateRange) {
      case DATE_RANGE.LAST_WEEK: cutoffDate = moment().subtract(1, 'weeks').startOf('day'); break;
      case DATE_RANGE.LAST_MONTH: cutoffDate = moment().subtract(1, 'months').startOf('day'); break;
      case DATE_RANGE.LAST_YEAR: cutoffDate = moment().subtract(1, 'years').startOf('day'); break;
      default: cutoffDate = moment('1970-01-01');
    }
    return alarms.filter(({ alarm_source, alarm_timestamp }) => alarm_source === alarmTypeFilter && moment(alarm_timestamp).isSameOrAfter(cutoffDate));
  }, [
    alarms,
    alarmTypeFilter,
    dateRange,
  ]);

  const renderHeader = useCallback(props => {
    return (
      <TableToolbar
        searchable
        title="Alarms"
        loading={loading}
        lastFetch={lastFetch}
        error={error}
        tableProps={props}
        buttonGroups={[
          <AlarmDateFilter onDateChange={(value) => setDateRange(value)} disabled={loading || error} />,
          <Button
            as={DownloadTableCSVLink}
            columns={columns}
            data={filteredAlarms}
            variant="outline-secondary"
            name="MOVUS-alarms-list"
            loading={loading}
            namespace={`alarms-list-${alarmTypeFilter}`}
          />,
          [
            <LinkContainer key="condition" to="/alarms/list/condition" exact>
              <Button variant="outline-secondary">AI Condition</Button>
            </LinkContainer>,
            hasThresholdAlarms && <LinkContainer key="threshold" to="/alarms/list/threshold" exact>
              <Button variant="outline-secondary">Measured Data</Button>
            </LinkContainer>,
            hasUserAlarms && <LinkContainer key="user" to="/alarms/list/user" exact>
              <Button variant="outline-secondary">Manual Alarms</Button>
            </LinkContainer>,
          ],
          [
            <ButtonGroup key={1}>
              <ColumnsManager
                key={0}
                columns={alarmColumns}
                tableName={tableName}
                variant="outline-secondary"
              />
              {activeSubGroupId && <Button
                key={1}
                variant={includeSubGroup ? 'secondary' : 'outline-secondary'}
                onClick={() => setIncludeSubGroup(!includeSubGroup)}>
                {!includeSubGroup ? 'Show' : 'Hide'} Subgroup Alarms
              </Button>}
            </ButtonGroup>
          ]
        ]}
      />
    );
  }, [loading, lastFetch, error, includeSubGroup, activeSubGroupId, filteredAlarms, columns]);

  return {
    renderHeader,
    filteredAlarms,
    columns,
  };
};

function Alarms({
  activeSubGroupId,
  alarms=[],
  fetchAlarms,
  fetchDevices,
  loading,
  lastFetch,
  error,
  rmsAvailable,
  mm2Available,
}) {

  const { renderHeader, filteredAlarms, columns } = useTableProps({
    alarmTypeFilter,
    activeSubGroupId,
    fetchAlarms,
    fetchDevices,
    loading,
    lastFetch,
    error,
    alarms,
    columnsArr,
  });

  const filteredColumns = useColumnsWithVisibility(columns, {
    'rms': !!rmsAvailable,
    'rms2': !!mm2Available,
  });
  const history = useHistory();

  return (
    <Container fluid>
      <Table
        pagination
        renderHeader={renderHeader}
        defaultSorted={defaultSorted}
        noDataIndication={NoDataIndication}
        loading={loading}
        columns={filteredColumns}
        data={useMemo(() => {
          return filteredAlarms
            // condition volume is added here but should in future come from Platform
            // todo: remove
            .map(fields => ({
              ...fields,
              condition_volume: calculateLevel(fields.rms, fields.equipment_iso_class),
            }));
        }, [
          filteredAlarms,
          calculateLevel,
        ])}
        rowEvents = {{
          onClick: (e, alarm) => {
            if(window.getSelection().toString().length > 0) return;
            history.push(`/alarms/${alarm.id}`);
          }
        }}
        rowStyle={{
          cursor: 'pointer'
        }}
      />
    </Container>
  );
}

const mapStateToProps = state => {
  const alarms = getAlarms(state);
  const { loading, lastFetch, error } = getAlarmListState(state);
  return {
    alarms,
    loading,
    lastFetch,
    error,
    activeSubGroupId: getActiveSubGroupId(state),
    rmsAvailable: getOrganisationRmsAvailablePreference(state),
    mm2Available: getOrganisationMm2AvailablePreference(state),
  };
};

const mapDispatchToProps = { fetchAlarms, fetchDevices };

export default connect(mapStateToProps, mapDispatchToProps)(Alarms);

import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { ButtonGroup, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import EquipmentPageLayout from "../components/EquipmentPageLayout";
import Title from "../../../components/Title";
import useDeviceEvents from "../hooks/useDeviceEvents";
import useDeviceAlarms from "../hooks/useDeviceAlarms";
import useDeviceWatchers from "../hooks/useDeviceWatchers";
import DeviceAlarmCard from "../components/DeviceAlarmCard";
import { getDevice } from '../selectors';
import { sortAlarmsDefault } from '../../alarm/selectors';
import ActivityList from "../../app/components/ActivityList";
import useActivityView from "../hooks/useActivityView";

function EquipmentEventView() {
  const { id } = useParams();
  const { loading, error, lastFetch, deviceEvents } = useDeviceEvents(parseInt(id));
  const { loading: loadingWatchers } = useDeviceWatchers(id, { fetch: true }); // Fetch device watchers

  return (
    <ActivityList>
      <Title
        title={' '}
        loading={loading || loadingWatchers}
        error={error}
        lastFetch={lastFetch}
      />
      {deviceEvents.map(event => {
        return (
          <DeviceAlarmCard
            key={event.id}
            alarmId={event.id}
          />
        );
      })}
    </ActivityList>
  );
}

function EquipmentCommentView() {
  return (
    <div>Equipment comment view</div>
  );
}

function EquipmentAlarmView() {
  const { id } = useParams();
  const { loading, error, lastFetch, deviceAlarms: alarms } = useDeviceAlarms(parseInt(id));
  const { loading: loadingWatchers } = useDeviceWatchers(id, { fetch: true }); // Fetch device watchers
  const sortedAlarms = alarms
    // sort by default for alarms
    .sort(sortAlarmsDefault);
  return (
    <ActivityList>
      <Title
        title={' '}
        loading={loading || loadingWatchers}
        error={error}
        lastFetch={lastFetch}
      />
      {sortedAlarms.map(alarm => {
        return (
          <DeviceAlarmCard
            key={alarm.id}
            alarmId={alarm.id}
          />
        );
      })}
    </ActivityList>
  );
}

function EquipmentActivityViews() {
  const { id } = useParams();
  const deviceId = parseInt(id);
  const view = useActivityView();
  const device = useSelector(state => getDevice(state, deviceId)) || {};

  return (
    <EquipmentPageLayout>
      <div className="my-4">
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <h2>ACTIVITY</h2>
          </div>
          <ButtonGroup className="d-block">
            <LinkContainer to="?view=events">
              <Button variant="outline-secondary" size="sm" active={view === 'events'}>Events</Button>
            </LinkContainer>
            {/* <LinkContainer to="?view=comments">
              <Button variant="outline-secondary" size="sm" active={view==='comments'}>Comments</Button>
            </LinkContainer> */}
            <LinkContainer to="?view=alarms">
              <Button variant="outline-secondary" size="sm" active={view === 'alarms'}>Alarms</Button>
            </LinkContainer>
          </ButtonGroup>
        </div>
        {
          device.archived ?
            <>
              <p>
                Activity is not currently available for archived equipment.
              </p>
            </> :
            <div>
              {view === 'alarms' ? <EquipmentAlarmView /> : // Show different views as per parameter.
                view === 'comments' ? <EquipmentCommentView />:
                  <EquipmentEventView />}
            </div>
        }
      </div>
    </EquipmentPageLayout>
  );
}

export default EquipmentActivityViews;
import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import PageLayout from '../components/PageLayout';
import NoDataIndication  from '../components/NoDataIndication';
import { fetchUsage } from '../actions';
import { getUsageState, getUsage, getIsEmptyUsage, getPeriodUsage, getUsageEstimatedClosingBalance } from '../selectors';
import { getUserOrganisation } from '../../user/selectors';
import { getOrganisationId } from '../../organisation/selectors';
import { formatNumber } from '../../../components/lib/utils';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { AppStoreState } from '../../types';
import { StateProps as UsageStateProps, UsageResponse, MonitoredHour } from '../types';
import { FetchUsageAction } from '../types/Action';
import { getActiveGroupId, getRootGroupId } from '../../organisation/selectors';
import './usage.scss';

interface StateProps {
  usageState: UsageStateProps;
  usage: UsageResponse;
  isEmptyUsage: boolean;
  periodUsage: MonitoredHour[];
  userOrganisation: any;  // @TODO: Fix the any type.
  activeGroupId?: number;
  rootGroupId?: number;
  estimatedClosingBalance: number;
};
interface DispatchProps {
  fetchUsage: (id: number) => (dispatch: Dispatch<FetchUsageAction>) => FetchUsageAction;
};

const mapStateToProps = (state: AppStoreState): StateProps => {
  const organisationId = getOrganisationId(state);
  return {
    usageState: getUsageState(state),
    usage: getUsage(state),
    isEmptyUsage: getIsEmptyUsage(state),
    periodUsage: getPeriodUsage(state),
    userOrganisation: organisationId && getUserOrganisation(state, organisationId),
    activeGroupId: getActiveGroupId(state),
    rootGroupId: getRootGroupId(state),
    estimatedClosingBalance: getUsageEstimatedClosingBalance(state),
  };
};

const mapDispatchToProps: DispatchProps = {
  fetchUsage
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {};

function Usage({
  usageState,
  usage,
  isEmptyUsage,
  periodUsage,
  userOrganisation,
  fetchUsage,
  activeGroupId,
  rootGroupId,
  estimatedClosingBalance,
}: Props) {

  useEffect(() => {
    if (activeGroupId) {
      fetchUsage(activeGroupId);
    } else if(rootGroupId) {
      fetchUsage(rootGroupId);
    }
  }, [activeGroupId]);

  return (
    <PageLayout
      title="Usage"
      loading={usageState.loading}
      searchProps={{
        hide: true
      }}
      showRowCount={false}
    >
      {usageState.loading && isEmptyUsage ?
        <div className="d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div> :
        isEmptyUsage ? <NoDataIndication title="We are unable to display usage information for the selected group."/> :
          <>
            <p>This page shows the usage for the current billing period on your account {userOrganisation?.organisation_name}.</p>
            <div className="usage-table-wrapper">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    {usage && usage.billing_period && <th colSpan={2}>
                      Billing Period: {moment(usage.billing_period.start).format('DD MMM YYYY')} - {moment(usage.billing_period.end).format("DD MMM YYYY")}
                    </th>}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="col-5">Opening Balance</td>
                    <td className={`col-5 ${usage.balance < 0 ? 'color-movus-green' : ''}`}>{formatNumber(usage.balance)}</td>
                  </tr>
                  <tr>
                    <td>Current Usage</td>
                    <td>{formatNumber(usage.current_usage)}</td>
                  </tr>
                  <tr>
                    <td>Estimated Usage</td>
                    <td>{formatNumber(usage.estimated_usage)}</td>
                  </tr>
                  <tr>
                    <td>Estimated Closing Balance</td>
                    <td className={`${estimatedClosingBalance < 0 ? 'color-movus-green' : ''}`}>{formatNumber(estimatedClosingBalance)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {periodUsage.length === 0 ? <NoDataIndication title="We are unable to display usage information for the selected group." /> : (
              <ReactECharts
                option={{
                  title: {
                    text: 'Usage by Day'
                  },
                  tooltip: {},
                  xAxis: {
                    data: periodUsage.map(u => ({
                      value:  moment(u.date).format('DD MMM YYYY'),
                      textStyle: {
                        fontSize: 9
                      }
                    })),
                  },
                  yAxis: {
                    name: 'Hours'
                  },
                  series: [{
                    name: 'Monitored hours',
                    type: 'bar',
                    data: periodUsage.map(u => u.hours),
                    itemStyle: {
                      color: 'rgb(0, 175,170)',
                    }
                  }],
                }}
                style={{height: 400}}
              />
            )}
          </>}
    </PageLayout>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Usage);
import { useGroupPendingEventsReport } from '../../modules/organisation/hooks/useGroupEventsReport';
import HomeCard from './HomeCard';
import Table from '../Table';
import { equipmentNameLink, pendingEventStatusBadge, scheduledDate, latestComments } from './columns';

const columns = [
  equipmentNameLink,
  pendingEventStatusBadge,
  scheduledDate,
  latestComments,
];

function PendingEvents() {
  const groupPendingEventsReport = useGroupPendingEventsReport();
  if(!groupPendingEventsReport) return null;

  const {_state_: { loading, error } = {}, items = []} = groupPendingEventsReport || {};
  return (
    <HomeCard
      header="Pending action events"
      headerLink={{to: '/events?status=scheduled,in_progress', text: 'All pending action events'}}
      loading={loading}
      error={error}
    >
      <Table
        data={items}
        keyField="alarm_id"
        loading={loading}
        columns={columns}
        condensed
      />
    </HomeCard>
  );
}

export default PendingEvents;
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import {
  fetchOrganisations,
  fetchOrganisationWithId,
  submitOrganisationDetails,
  submitNewOrganisationDetails,
  submitNewChildOrganisationDetails,
} from '../actions';
import { getOrganisationsList } from '../selectors';
import { isAuthorised } from '../../user/selectors';

function OrganisationForm({organisation, onSubmitting, onSuccess, onError }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const editMode = !!organisation;
  const [loading, setLoading] = useState(false);

  const organisations = useSelector(getOrganisationsList, shallowEqual);
  const userIsSuperAdmin = useSelector(state => isAuthorised(state, { minUserType: 'Super Admin', minOrgType: 'MOVUS' }));
  const userCanEditParentOrg = useSelector(state => isAuthorised(state, { minUserType: 'Partner Admin', minOrgType: 'Partner' }));
  const parentOrganisationId = organisation?.parent_organisation_id;
  const nonChildOrganisations = useMemo(() => {
    return organisations
      // filter to relevant organisations
      .filter(org => !org.parent_organisation_id)
      // sort by organisation name
      .sort((a, b) => `${a.name}`.localeCompare(`${b.name}`));
  }, [organisations]);

  useEffect(() => {
    userCanEditParentOrg && dispatch(fetchOrganisations());
  }, []);

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: organisation?.name || '',
      sub_domain: organisation?.sub_domain || '',
      parent_organisation_id: organisation?.parent_organisation_id,
    }
  });

  const onSubmit = useCallback(async (formValues) => {
    formValues.parent_organisation_id = formValues.parent_organisation_id !== undefined
      ? formValues.parent_organisation_id
        ? parseInt(formValues.parent_organisation_id)
        : undefined // see DASH-454 for details: cannot PUT  "parent_organisation_id" = null
      : undefined;
    try {
      setLoading(true);
      typeof onSubmitting === 'function' && onSubmitting();
      if(editMode) {
        await dispatch(submitOrganisationDetails(organisation, formValues));
        await dispatch(fetchOrganisationWithId(organisation));
      } else {
        const { parent_organisation_id, ...payload } = formValues;
        let response;
        if(parent_organisation_id) {
          response = await dispatch(submitNewChildOrganisationDetails(parent_organisation_id, payload));
        } else {
          response = await dispatch(submitNewOrganisationDetails(payload));
        }
        typeof onSuccess === 'function' && onSuccess(Date.now());
        if (response && response.headers && response.headers.location) {
          const matches = `${response.headers.location}`.match(/(\d+)$/);
          if (matches && matches[1]) {
            await dispatch(fetchOrganisationWithId({ id: matches[1] }));
            history.push(`/organisations/${matches[1]}`);
          }
          else {
            history.push('/organisations/admin');
          }
        }
      }
    } catch(e) {
      typeof onError === 'function' && onError(e.message);
    } finally {
      setLoading(false);
    }
  }, [organisation]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group as={Row}  controlId="form__edit_organisation--name">
        <Form.Label column sm="2" xs="4">
          Name
        </Form.Label>
        <Col sm="8" xs="6">
          <Form.Control
            {...register(
              "name",
              { required: true,
                validate: value => !!value.trim()
              }
            )}
            placeholder="Name"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}  controlId="form__edit_organisation--sub_domain">
        <Form.Label column sm="2" xs="4">
          Subdomain
        </Form.Label>
        <Col sm="8" xs="6">
          <Form.Control
            {...register(
              "sub_domain",
              { required: true,
                validate: value => !!value.trim()
              }
            )}
            placeholder="org.dash.movus"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Parent Organisation
        </Form.Label>
        <Col sm="10">
          {userIsSuperAdmin && !parentOrganisationId && (
            <Form.Check
              {...register("parent_organisation_id")}
              id="parent_organisation_id_null"
              type="radio"
              label="No parent"
              value=""
              defaultChecked={!parentOrganisationId}
            />
          )}
          {nonChildOrganisations.map(({ id, name }) => (
            <Form.Check
              {...register("parent_organisation_id")}
              key={`parent_organisation_id-${id}`}
              id={`parent_organisation_id-${id}`}
              type="radio"
              label={name}
              value={id}
              defaultChecked={
                // set as current value
                (id === parentOrganisationId) ||
                // or default to the only value if only one is available
                (!userIsSuperAdmin && nonChildOrganisations.length === 1)
              }
            />
          ))}
        </Col>
      </Form.Group>
      <Form.Group>
        <Button
          variant="success"
          type="submit"
          size="lg"
          className="float-right mb-chat-widget"
          disabled={loading || Object.keys(errors).length > 0}
        >
          {editMode ? 'Update' : 'Submit'}
        </Button>
      </Form.Group>
    </form>
  );
}

export default OrganisationForm;
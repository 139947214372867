import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { updateGateway } from '../actions';
import withNavigationGatewayProps from "../components/withNavigationGatewayProps";
import LocationInput from '../../../components/form/LocationInput';
import useDomain from '../../domain/hooks/useDomain';

function EditGateway({ gateway = {} }) {
  const dispatch = useDispatch();
  const {domainData: gatewayTypes = [], fetchGatewayTypes } = useDomain({ key: 'gatewayTypes', fetch: false, cache: false });
  const { _state_: { loading } = {}} = gateway;

  useEffect(() => {
    fetchGatewayTypes();
  }, []);

  const { register, handleSubmit, formState, watch, setValue, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: gateway.name,
      type: gateway.type,
      site_name: gateway.site_name,
      sub_area_name: gateway.sub_area_name,
      latitude: gateway.latitude,
      longitude: gateway.longitude,
    }
  });
  const watchLatitude = watch('latitude');
  const watchLongitude = watch('longitude');
  useEffect(() => {
    if(Object.keys(gateway).length === 0) return;
    reset({
      name: gateway.name,
      type: gateway.type,
      site_name: gateway.site_name,
      sub_area_name: gateway.sub_area_name,
      latitude: gateway.latitude,
      longitude: gateway.longitude,
    });
  }, [gateway.id]);

  const handleChangeLocation = useCallback((lat, lng) => {
    setValue('latitude', lat);
    setValue('longitude', lng);
  }, []);

  const onSubmit = useCallback(formValues => {
    if(!formValues.name) delete formValues.name;
    if(!formValues.type) delete formValues.type;    // For 'name' and 'type', not submitting if they're empty.
    dispatch(updateGateway(gateway.id, formValues));
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group as={Row} controlId="form__edit_gateway--name">
        <Form.Label column sm="3">
          Name
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="text"
            {...register("name")}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="form__edit_gateway--type">
        <Form.Label column sm="3">
          AP Type
        </Form.Label>
        <Col sm="9">
          <Form.Control as="select" {...register("type")} style={{maxWidth: '12em'}}>
            {gatewayTypes.map((type, index) => <option key={index} value={type}>{type}</option>)}
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="form__edit_gateway--site_name">
        <Form.Label column sm="3">
          Site Name
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="text"
            {...register("site_name", {
              required: true,
            })}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="form__edit_gateway--sub_area_name">
        <Form.Label column sm="3">
          Sub-area Name
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="text"
            {...register("sub_area_name", {
              required: true,
            })}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="form__edit_gateway--latitude">
        <Form.Label column sm="3">
          Latitude
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="text"
            {...register("latitude", {
              required: true,
              validate: value => value > -90 && value < 90,
            })}
            style={{maxWidth: '12em'}}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="form__edit_gateway--longitude">
        <Form.Label column sm="3">
          Longitude
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="text"
            {...register("longitude", {
              required: true,
              validate: value => value > -180 && value < 180,
            })}
            style={{maxWidth: '12em'}}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Location
        </Form.Label>
        <Col sm="9">
          <LocationInput
            latitude={watchLatitude}
            longitude={watchLongitude}
            onChangeLocation={handleChangeLocation}
          />
        </Col>
      </Form.Group>
      <Button
        variant="success"
        size="lg"
        className="float-right mb-chat-widget mb-4"
        type="submit"
        disabled={!formState.isValid || loading}
      >Update</Button>
    </form>
  );
}

export default withNavigationGatewayProps(EditGateway);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import moment from 'moment';

// General
import Root from './components/Root';
import NotFound from './components/NotFound';
import PrivateRoute from './components/PrivateRoute';

// Users
import Login from './modules/user/screens/Login';
// import Home from './modules/user/screens/Home';
import HomePage from './modules/user/screens/HomePage';
import UsersAdmin, { UsersList } from './modules/user/screens/UsersAdmin';
import EditUser from './modules/user/screens/EditUser';
import UpdatePassword from './modules/user/screens/UpdatePassword';
import ForgotPassword from './modules/user/screens/ForgotPassword';
import ResetPassword from './modules/user/screens/ResetPassword';
import Activity from './modules/user/screens/Activity';

// Equipment
import EquipmentList from './modules/equipment/screens/EquipmentList';
import EquipmentDetail from './modules/equipment/screens/EquipmentDetail';
import EquipmentFitpowerDetail from './modules/equipment/screens/EquipmentFitpowerDetail';
import EquipmentMap from './modules/equipment/screens/EquipmentMap';
import EditEquipment from './modules/equipment/screens/EditEquipment';
import EditEquipmentImpact from './modules/equipment/screens/EditEquipmentImpact';
import EditUserEquipment from './modules/user/screens/EditUserEquipment';
import EquipmentRouter from './modules/equipment/screens/EquipmentRouter';
import EquipmentImpactReport from './modules/equipment/screens/EquipmentImpactReport';
import EquipmentSettings from './modules/equipment/screens/EquipmentSettings';

// Devices
import DevicesAdmin from './modules/equipment/screens/DevicesAdmin';
import DevicesAdminArchived from './modules/equipment/screens/DevicesAdminArchived';
import DeviceActivity from './modules/equipment/screens/DeviceActivity';
import DeviceNetwork from './modules/equipment/screens/DeviceNetwork';
import DeviceErrors from './modules/equipment/screens/DeviceErrors';

// Gateways
import GatewaysAdmin from './modules/gateway/screens/GatewaysAdmin';
import GatewayNetwork from './modules/gateway/screens/GatewayNetwork';
import EditGateway from './modules/gateway/screens/EditGateway';

// Alarms
import Alarms from './modules/alarm/screens/Alarms';
import ThresholdAlarms from './modules/alarm/screens/ThresholdAlarms';
import UserAlarms from './modules/alarm/screens/UserAlarms';
import Alarm from './modules/alarm/screens/Alarm';
import AlarmsRouter from './modules/alarm/screens/AlarmsRouter';
import AlarmImpact from './modules/alarm/screens/AlarmImpact';

// Organisations
import OrganisationsAdmin from './modules/organisation/screens/OrganisationsAdmin';
import OrganisationActivity from './modules/organisation/screens/Activity';
import NewOrganisation from './modules/organisation/screens/NewOrganisation';
import EditOrganisation from './modules/organisation/screens/EditOrganisation';
import EditOrganisationPreferences from './modules/organisation/screens/EditOrganisationPreferences';
import EditUserPreferences from './modules/user/screens/EditUserPreferences';
import EditGroupEquipment from './modules/organisation/screens/EditGroupEquipment';
import EditGroupUsers from './modules/organisation/screens/EditGroupUsers';
import EditGroupAccessUsers from './modules/organisation/screens/EditGroupAccessUsers';
import EditGroupAccessGroups from './modules/organisation/screens/EditGroupAccessGroups';
import EditGroupAccess from './modules/organisation/screens/EditGroupAccess';
import EditGroupConfig from './modules/organisation/screens/EditGroupConfig';
import EditGroupImpact from './modules/organisation/screens/EditGroupImpact';
import GroupImpactSummary from './modules/organisation/screens/GroupImpactSummary';
import OrganisationTokens from './modules/organisation/screens/OrganisationTokens';
import StreamingConfiguration from './modules/organisation/screens/StreamingConfiguration';
import OrganisationWebhooks from './modules/organisation/screens/OrganisationWebhooks';

// Billing
import BillingAdmin from './modules/billing/screens/BillingAdmin';
// import Invoices from './modules/billing/screens/Invoices';
import Plan from './modules/billing/screens/Plan';
import Transactions from './modules/billing/screens/Transactions';
import Usage from './modules/billing/screens/Usage';

// Event
import Events from './modules/alarm/screens/Events';

import { isUserLoggedIn, isSuperAdmin } from './modules/user/selectors';
import EquipmentActivityViews from './modules/equipment/screens/EquipmentActivityViews';
// route logged in user routes
function Routes({ userIsLoggedIn, location, isSuperAdmin }) {

  useEffect(() => {
    moment.updateLocale('en', { // Set up Monday as the first day of week for calendar.
      week: {
        dow: 1,
      }
    });
  }, []);

  // ensure that on pathname change (page change) the new page starts at the top of the page
  useEffect(() => {
    // scroll to the top of the page
    document?.body?.scrollIntoView?.();
  }, [location && location.pathname]);

  // forward the nextLocation org, to use on the Login page
  const search = location && location.query && location.query.org
    ? `org=${location.query.org}`
    : undefined;

  // take user out of user routes if they don't have a token
  // and save the location to redirect them to after they log in if the page isn't found
  if (!userIsLoggedIn) {
    return (
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/password/forgot" component={ForgotPassword} />
        <Route path="/password/:setPasswordType(set|reset)" component={ResetPassword} />
        <Redirect to={{ pathname: '/login', search, state: { nextLocation: location } }} />
      </Switch>
    );
  }

  // if a user is logged in and has a redirection available, send them there
  if (location &&
    location.state &&
    location.state.nextLocation &&
    location.state.nextLocation.pathname &&
    location.state.nextLocation.pathname !== location.pathname
  ) {
    return (
      <Redirect to={location.state.nextLocation} />
    );
  }

  return (
    <Root userIsLoggedIn={userIsLoggedIn}>
      <Switch>
        <Redirect from="/login" to="/equipment/list" />
        <Redirect from="/password" to="/equipment/list" />
        <Redirect from="/" exact to="/equipment/list" />
        <Route path="/home" component={HomePage} />
        <Route path="/equipment/list" component={EquipmentList} />
        <PrivateRoute productCode="map_views" path="/equipment/map" component={EquipmentMap} />
        <PrivateRoute path="/equipment/:id/settings" minUserType="Admin" component={EquipmentSettings} />
        <PrivateRoute path="/equipment/:id/impact" minUserType="Admin" component={EditEquipmentImpact} />
        <PrivateRoute path="/equipment/:id/impact_summary" minUserType="Admin" component={EquipmentImpactReport} />
        <Route path="/equipment/:id/activity" component={EquipmentActivityViews} />
        {/* <Route path="/equipment/:id/activityviews" component={EquipmentActivityViews} /> */}
        <PrivateRoute productCode="fitpower" path="/equipment/:id/fitpower" component={EquipmentFitpowerDetail} />
        <Route path="/equipment/:id" exact component={EquipmentDetail} />
        <Route path="/equipment" component={EquipmentRouter} />
        <Route path="/alarms/list/condition" component={Alarms} />
        <PrivateRoute productCode="rms_tmp_alarms" path="/alarms/list/threshold" component={ThresholdAlarms} />
        <PrivateRoute productCode="user_alarms" path="/alarms/list/user" component={UserAlarms} />
        <Route path="/alarms/:id/impact" component={AlarmImpact} />
        <Route path="/alarms/:id" component={Alarm} />
        <Route path="/alarms" component={AlarmsRouter} />
        <Route path="/events" component={Events} />
        <Route path="/users/:userEditType(me)/password/edit" component={UpdatePassword} />
        <Route path="/users/:userEditType(me)/preferences/edit" component={EditUserPreferences} />
        <PrivateRoute minUserType="Admin" path="/users/:userEditType(me)/equipment/edit" component={EditUserEquipment} />
        <Route path="/users/:userEditType(me)/edit" component={EditUser} />
        <Route path="/users/:userEditType(me)" component={Activity} />
        <PrivateRoute minUserType="Admin" path="/users/admin" component={UsersAdmin} redirectTo="/users/list" />
        <Route path="/users/list" component={UsersList} />
        <PrivateRoute minUserType="Admin" path="/users/:userEditType(new)" component={EditUser} />
        <PrivateRoute minUserType="Admin" path="/users/:id/password/edit" component={UpdatePassword} />
        <PrivateRoute minUserType="Admin" path="/users/:id/preferences/edit" component={EditUserPreferences} />
        <PrivateRoute minUserType="Admin" path="/users/:id/equipment/edit" component={EditUserEquipment} />
        <PrivateRoute minUserType="Admin" path="/users/:id/edit" component={EditUser} />
        <PrivateRoute minUserType="Admin" path="/users/:id" component={Activity} />
        <PrivateRoute minUserType="Admin" path="/devices/admin/archived" component={DevicesAdminArchived} />
        <PrivateRoute minUserType="Admin" path="/devices/admin" component={DevicesAdmin} />
        <PrivateRoute minUserType="Admin" path="/devices/:id/edit" component={EditEquipment} />
        {isSuperAdmin ?
          <PrivateRoute minUserType="Super Admin" path="/devices/:id/errors" component={DeviceErrors} /> :
          <PrivateRoute productCode="fmlog" minUserType="Admin" path="/devices/:id/errors" component={DeviceErrors} />
        }
        <PrivateRoute productCode="network_centre" path="/devices/:id/network" component={DeviceNetwork} />
        <Route path="/devices/:id" component={DeviceActivity} />
        <PrivateRoute productCode="network_centre" minUserType="Admin" path="/gateways/admin" component={GatewaysAdmin} />
        <PrivateRoute productCode="network_centre" minUserType="Admin" path="/gateways/:id/edit" component={EditGateway} />
        <PrivateRoute productCode="network_centre" path="/gateways/:id" component={GatewayNetwork} />
        <PrivateRoute minUserType="Admin" path="/organisations/admin" component={OrganisationsAdmin} />
        <PrivateRoute minUserType="Partner Admin" minOrgType="Partner" path="/organisations/:editType(new)" component={NewOrganisation} />
        <PrivateRoute minUserType="Admin" path="/organisations/:id/edit" component={EditOrganisation} />
        <PrivateRoute minUserType="Admin" path="/organisations/:id/preferences/edit" component={EditOrganisationPreferences} />
        <PrivateRoute productCode="groups_equipment" minUserType="Admin" path="/group/devices" component={EditGroupEquipment} />
        <PrivateRoute productCode="groups_equipment" minUserType="Admin" path="/group/users" component={EditGroupUsers} />
        <PrivateRoute productCode="groups_equipment" minUserType="Admin" path="/group/access/users" component={EditGroupAccessUsers} />
        <PrivateRoute productCode="groups_equipment" minUserType="Admin" path="/group/access/groups" component={EditGroupAccessGroups} />
        <PrivateRoute productCode="groups_equipment" minUserType="Admin" path="/group/access" component={EditGroupAccess} />
        <PrivateRoute productCode="groups_equipment" minUserType="Admin" path="/group/config" component={EditGroupConfig} />
        <PrivateRoute productCode="groups_equipment" minUserType="Admin" path="/group/impact" component={EditGroupImpact} />
        <PrivateRoute productCode="groups_equipment" minUserType="Admin" path="/group/impact_summary" component={GroupImpactSummary} />
        <PrivateRoute productCode="streaming_api" minUserType="Admin" path="/developer/admin/streaming" component={StreamingConfiguration} />
        <PrivateRoute /** @TODO: productCode="?" */ minUserType="Admin" path="/developer/admin/webhooks" component={OrganisationWebhooks} />
        <PrivateRoute productCode="token_api" minUserType="Admin" path="/developer/admin" component={OrganisationTokens} />
        {/* <PrivateRoute productCode="billing_centre" minUserType="Admin" path="/billing/invoices" component={Invoices} /> */}
        <PrivateRoute productCode="billing_centre" minUserType="Admin" path="/billing/plan" component={Plan} />
        <PrivateRoute productCode="billing_centre" minUserType="Admin" path="/billing/transactions" component={Transactions} />
        <PrivateRoute productCode="billing_centre" minUserType="Admin" path="/billing/usage" component={Usage} />
        <PrivateRoute productCode="billing_centre" minUserType="Admin" path="/billing" component={BillingAdmin} />
        <Route path="/organisations/:id" component={OrganisationActivity} />
        <Route component={NotFound} />
      </Switch>
    </Root>
  );
}

const mapStateToProps = (state) => ({
  // add user token into logged in route logic
  userIsLoggedIn: isUserLoggedIn(state),
  isSuperAdmin: isSuperAdmin(state),
});

export default withRouter(connect(mapStateToProps)(Routes));

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import { fetchDevices } from '../../equipment/actions';
import {
  fetchGroup,
  fetchGroupMembers,
  fetchGroupMembersAvailable,
  addGroupDevices,
  deleteGroupDevices,
} from '../actions';

import TableListEdit, {
  included,
  sortByActiveThenString,
  sortByActiveThenNumber,
} from '../../../components/TableListEdit';

import { useColumnsWithVisibility } from '../../../components/table/utils';
import { addToast } from '../../../components/Toaster';
import {
  archived,
  organisationName,
  siteName,
  subAreaName,
  equipmentName,
  type,
} from '../../equipment/columns';

import { hasAdminRightsInActiveGroup } from '../../user/selectors';
import { getDevices } from '../../equipment/selectors';
import {
  getGroupDevices,
  getGroupDevicesAvailable,
  isTopLevelGroupActive,
  isStandardOrganisation,
  getNearestGroupOrganisationAncestor,
  getNearestOrganisationAncestorId,
  getActiveGroupNodeInfo,
} from '../selectors';
import { ApiRequestCanceller } from '../../../lib/utils';

const defaultSorted = [{
  dataField: 'equipment_name',
  order: 'asc'
}];

function searchFilterFactory(searchText='') {
  // create search text filter over all values in item object
  const lowercaseFilter = searchText.toLowerCase().trim();
  return (device={}) => {
    return `${
      device.equipment_name
    } ${
      device.site_name
    } ${
      device.sub_area_name
    } ${
      !device.archived ? 'active' : 'archived'
    }`.toLowerCase().includes(lowercaseFilter);
  };
}

const columns = [{
  ...organisationName,
  sortFunc: sortByActiveThenString,
}, {
  ...siteName,
  sortFunc: sortByActiveThenString,
}, {
  ...subAreaName,
  sortFunc: sortByActiveThenString,
}, {
  ...equipmentName,
  sortFunc: sortByActiveThenString,
}, type, {
  ...archived,
  sortFunc: sortByActiveThenNumber,
}, {
  ...included,
  text: 'Show',
}];

// disable any archived device from being editable
function isItemDisabled(device) {
  return device.archived;
}

const returnTrue = () => true;

function EditGroupDevices({
  groupId,
  isUserGroup,
  isOrganisationGroup,
  topLevelGroupActive,
  userCanViewGroups,
  userCanEditGroups,
  organisationIsStandard,
  nearestOrganisationGroup = {},
  nearestOrganisationId,
  groupDevices,
  devices,
  archivedDevices,
  fetchGroup,
  fetchGroupMembers,
  fetchGroupMembersAvailable,
  fetchDevices,
  addGroupDevices,
  deleteGroupDevices,
}) {
  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = useState({});

  // get devices filtered to current org
  const filteredDevices = useMemo(() => {
    return [...devices || [], ...archivedDevices || []]
      .filter(topLevelGroupActive ? Boolean : device => {
        return device.organisation_id === nearestOrganisationId;
      });
  }, [nearestOrganisationId, topLevelGroupActive, loadingState]);

  const isItemIncluded = useMemo(() => {
    const groupDeviceIds = (groupDevices || []).map(({ id }) => id);
    return device => groupDeviceIds.includes(device.id);
  }, [groupDevices]);

  // load devices list
  useEffect(() => {
    const canceller = new ApiRequestCanceller();
    const group = { id: groupId };
    // fetch group's devices if needed
    // add already fetching state
    async function loadDevicesLists() {
      const submittedAt = Date.now();
      setLoadingState({ submittedAt });
      try {
        await fetchGroup(group);
        // this logic should follow that of the selector logic
        // if on top level, fetch all devices
        if (topLevelGroupActive) {
          await Promise.all([
            fetchDevices({ forOrg: true }, canceller),
            fetchDevices({ filter: 'archived', forOrg: true }, canceller),
            // if the user can edit groups, fetch the available members
            userCanEditGroups && fetchGroupMembersAvailable(group, canceller),
          ]);
        }
        else {
          await Promise.all([
            fetchGroupMembers(group),
            fetchDevices({ filter: 'archived', forOrg: true }, canceller),
            // if the user can edit groups, fetch the available members
            userCanEditGroups && fetchGroupMembersAvailable(group, canceller),
          ]);
        }
        // if the user can edit groups, fetch the available members
        if (userCanEditGroups) {
          // Move it to Promise.all array.
          // await fetchGroupMembersAvailable(group, canceller);
        }
        // fetch the members on the nearest organisation group
        else if (!topLevelGroupActive && nearestOrganisationGroup.id) {
          // and the organisation group
          await Promise.all([
            fetchGroup({ id: nearestOrganisationGroup.id }),
            fetchGroupMembers({ id: nearestOrganisationGroup.id }),
          ]);
        }
        setLoadingState(loadingState => {
          return loadingState.submittedAt === submittedAt
            ? { succeededAt: new Date() }
            : loadingState;
        });
      }
      catch (error) {
        setLoadingState(loadingState => {
          return loadingState.submittedAt === submittedAt
            ? { error: error.message || 'Error' }
            : loadingState;
        });
      }
    }
    // execute async code
    if (group.id) {
      loadDevicesLists();
    }

    return () => {
      dispatch(canceller.cancel());
    };
  }, [groupId, topLevelGroupActive, nearestOrganisationGroup.id]);

  const [selectedItems, setSelectedItems] = useState([]);
  const handleChangeSelectItems = useCallback((selectedItems) => {
    setSelectedItems(selectedItems);
  }, []);
  const updatedDevices = useMemo(() => {
    // get devices list from current form state
    const newDeviceIds = selectedItems.map(d => d.id);
    const oldDeviceIds = (groupDevices || []).map(d => d.id);
    const addIds = newDeviceIds.filter(id => !oldDeviceIds.includes(id));
    const delIds = oldDeviceIds.filter(id => !newDeviceIds.includes(id));

    return { addIds, delIds };
  }, [groupDevices, selectedItems]);
  const hasDevicesUpdated = useMemo(() => {
    return updatedDevices.addIds.length > 0 || updatedDevices.delIds.length > 0;
  }, [updatedDevices]);
  const addedDevices = useMemo(() => {
    return filteredDevices.filter(d => updatedDevices.addIds.includes(d.id));
  }, [filteredDevices, updatedDevices.addIds]);
  const deletedDevices = useMemo(() => {
    return filteredDevices.filter(d => updatedDevices.delIds.includes(d.id));
  }, [filteredDevices, updatedDevices.delIds]);

  const onSubmit = useCallback(async (includedItems=[]) => {
    const group = { id: groupId };

    // get devices list from current form state
    const newDeviceIds = includedItems.map(d => d.id);
    const oldDeviceIds = (groupDevices || []).map(d => d.id);
    const addIds = newDeviceIds.filter(id => !oldDeviceIds.includes(id));
    const delIds = oldDeviceIds.filter(id => !newDeviceIds.includes(id));

    if (addIds.length || delIds.length) {
      await Promise.all([
        addIds.length && addGroupDevices(group, addIds),
        delIds.length && deleteGroupDevices(group, delIds),
      ]);
      addToast({ variant: 'success', header: 'Devices updated successfully' });
      await fetchGroupMembers(group);
    } else {
      addToast({ variant: 'warning', header: 'No changes made' });
    }
  }, [
    addGroupDevices,
    deleteGroupDevices,
    fetchGroupMembers,
    groupId,
    groupDevices,
  ]);

  const visibleColumns = useColumnsWithVisibility(columns, {
    // apply hidden status to hide organisation field for standard orgs
    'organisation_name': !organisationIsStandard,
    // if at least one device is marked as archived, show the archive status column
    'archived': !!filteredDevices.find(({ archived }) => archived),
  });

  if (!userCanViewGroups) {
    return null;
  }

  // if this is not a device group:
  // the user should be guided towards the appropriate access editing page
  if (isUserGroup) {
    return <Redirect to="/group/access" />;
  }

  return (
    <TableListEdit
      before={organisationIsStandard ? null : (
        <Alert variant="warning">
          <p>
            You cannot modify child organisation groups as a parent organisation.
          </p>
          <p className="mb-0">
            Sign into the child organisation directly to modify a group.
          </p>
        </Alert>
      )}
      title="Group Equipment"
      columns={visibleColumns}
      defaultSorted={defaultSorted}
      loadingState={loadingState}
      noDataIndication={() => 'No Equipment'}
      list={filteredDevices}
      isItemIncluded={isOrganisationGroup ? returnTrue : isItemIncluded}
      isItemDisabled={isOrganisationGroup ? returnTrue : isItemDisabled}
      searchFilterFactory={searchFilterFactory}
      onChange={handleChangeSelectItems}
      onSubmit={onSubmit}
      userCanEdit={!!userCanEditGroups && !isOrganisationGroup && loadingState.succeededAt}
      requireConfirm={{
        header: hasDevicesUpdated ? 'Are you sure?' : 'No changes made',
        body: hasDevicesUpdated ? (
          <>
            {!!addedDevices.length && <div>
              You are going to add belowe devices to the group:
              <ul>
                {addedDevices.map(d => <li key={d.id}>{d.equipment_name}</li>)}
              </ul>
            </div>}
            {!!deletedDevices.length && <div>
              You are going to remove below devices from the group:
              <ul>
                {deletedDevices.map(d => <li key={d.id}>{d.equipment_name}</li>)}
              </ul>
            </div>}
          </>
        ) : '',
      }}
      confirmButtonProps={{style: {display: !hasDevicesUpdated ? 'none' : ''}}}
    />
  );
}


const mapStateToProps = (state, { groupId }) => {
  const topLevelGroupActive = isTopLevelGroupActive(state);
  const userIsAdmin = !!hasAdminRightsInActiveGroup(state);
  const organisationIsStandard = !!isStandardOrganisation(state);
  const nearestOrganisationGroup = getNearestGroupOrganisationAncestor(state, groupId);
  const nearestOrganisationId = getNearestOrganisationAncestorId(state, groupId);
  const {
    isOrganisationGroup,
    isUserGroup,
  } = getActiveGroupNodeInfo(state);
  return {
    isOrganisationGroup,
    isUserGroup,
    topLevelGroupActive,
    userCanViewGroups: userIsAdmin,
    userCanEditGroups: userIsAdmin && organisationIsStandard,
    organisationIsStandard,
    nearestOrganisationGroup,
    nearestOrganisationId,
    ...topLevelGroupActive ? {
      groupDevices: getDevices(state),
    } : {
      groupDevices: getGroupDevices(state, groupId),
    },
    ...organisationIsStandard ? {
      devices: getGroupDevicesAvailable(state, groupId),
    } : {
      devices: getDevices(state, { forOrg: true }),
    },
    archivedDevices: getDevices(state, { forOrg: true, archived: true }),
  };
};
const mapDispatchToProps = {
  fetchDevices,
  fetchGroup,
  fetchGroupMembers,
  fetchGroupMembersAvailable,
  addGroupDevices,
  deleteGroupDevices,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupDevices);

import { useCallback, useMemo, } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Row, Col, Button, Dropdown } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { IoIosTrash, IoIosAddCircleOutline } from 'react-icons/io';
import FormModal from '../../../components/FormModal';
import useWebhooks from '../hooks/useWebhooks';
import useUpdateWebhooks from '../hooks/useUpdateWebhooks';
import { isValidUrl } from '../../../lib/utils';

function EndpointFormModal({
  children,
  endpoint,
}) {
  const history = useHistory();
  const location = useLocation();
  const { webhooks: {
    available = {},
    transforms = [],
  } = {} } = useWebhooks();
  const { updateWebhooks } = useUpdateWebhooks();
  const editMode = !!endpoint;

  const webhookOptions = useMemo(() => {
    const options = {};
    for(const [key, value] of Object.entries(available)) {
      if(options[value.group]) {
        options[value.group].push(
          {...value, value: key }
        );
      } else {
        options[value.group] = [{...value, value: key}];
      }
    }
    return options;
  }, [available]);

  const defaultValues = useMemo(() => {
    return {
      endpoint: endpoint?.endpoint || '',
      data_format: endpoint?.data_format || 'none',
      webhook: (endpoint?.webhook || []).map(item => item.key),
      headers: Object.entries(endpoint?.headers || {}).map(([key, value]) => ({key, value})),
      parameters: Object.entries(endpoint?.parameters || {}).map(([key, value]) => ({key, value})),
    };
  }, [endpoint]);

  const { register, handleSubmit, formState, control, getValues, reset } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { fields: headersFields, append: appendHeader, remove: removeHeader } = useFieldArray({
    control,
    name: 'headers',
  });

  const { fields: parametersFields, append: appendParameter, remove: removeParameter } = useFieldArray({
    control,
    name: 'parameters',
  });

  const handleAddHeaderfield = useCallback(() => {
    if(headersFields.length === 0) {
      appendHeader({key: '', value: ''});
      return;
    }
    const headersValue = getValues('headers');
    let isHeadersFieldValid = true;
    for(const header of headersValue) {
      const { key = '', value = '' } = header || {};
      if(!key.trim() || !value.trim()) {
        isHeadersFieldValid = false;
        break;
      }
    }
    if(isHeadersFieldValid) {
      appendHeader({key: '', value: ''});
    }
  }, [headersFields]);

  const handleAddParameterField = useCallback(() => {
    if(parametersFields.length === 0) {
      appendParameter({key: '', value: ''});
      return;
    }
    const parametersValue = getValues('parameters');
    let isParametersFieldValid = true;
    for(const parameter of parametersValue) {
      const { key = '', value = '' } = parameter || {};
      if(!key.trim() || !value.trim()) {
        isParametersFieldValid = false;
        break;
      }
    }
    if(isParametersFieldValid) {
      appendParameter({key: '', value: ''});
    }
  }, [parametersFields]);

  const resetFormValues = useCallback(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = useCallback(async (data) => {
    const { headers, parameters } = data;
    const headersMap = {};
    const parametersMap = {};
    headers.forEach(({key, value}) => {
      if(key && value) {
        headersMap[key] = value;
      }
    });
    parameters.forEach(({key, value}) => {
      if(key && value) {
        parametersMap[key] = value;
      }
    });
    data.headers = Object.entries(headersMap).length > 0 ? headersMap : null;
    data.parameters = Object.entries(parametersMap).length > 0 ? parametersMap : null;
    await updateWebhooks(data);
    if(!location.pathname.endsWith('/admin/webhooks')) {
      history.push('/developer/admin/webhooks');
    }
  }, [updateWebhooks, headersFields, parametersFields]);

  const renderForm = useCallback(() => {
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Webhook URL
          </Form.Label>
          <Col sm="9">
            <Form.Control
              {...register("endpoint", {
                required: true,
                validate: isValidUrl,
              })}
              placeholder="http://my-app.com/api"
              disabled={editMode}
            ></Form.Control>
            <Form.Text className="text-muted">
              Events selected below will trigger an HTTP POST request to this URL.
            </Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Destination (optional)
          </Form.Label>
          <Col sm="9">
            <Form.Control
              as="select"
              {...register("data_format")}
              style={{maxWidth: '150px'}}
            >
              <option key="none" value="none"></option>
              {transforms.map(transform => (
                <option key={transform} value={transform}>{transform}</option>
              ))}
            </Form.Control>
            <Form.Text className="text-muted">
              If selected, we'll send your webhook events in the format expected by your destination
            </Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Events
          </Form.Label>
          <Col sm="9">
            {Object.keys(webhookOptions).map(group => (
              <fieldset key={group} className="border mb-2">
                <legend className="px-2" style={{width: 'auto'}}>{group}</legend>
                {webhookOptions[group].map(option => (
                  <Form.Group
                    key={option.value}
                    controlId={option.value}
                  >
                    <Form.Check
                      type="checkbox"
                      value={option.value}
                      label={option.short_name}
                      {...register('webhook')}
                    />
                    <Form.Text muted className="mt-0">{option.description}</Form.Text>
                  </Form.Group>
                ))}
              </fieldset>
            ))}
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm={3}>
            <Dropdown>
              <Dropdown.Toggle variant="primary" className="pl-1">
                <IoIosAddCircleOutline size="1.4em" /> Add{' '}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleAddHeaderfield}>
                  Add Header
                </Dropdown.Item>
                <Dropdown.Item onClick={handleAddParameterField}>
                  Add Parameter
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Form.Group>
        {headersFields.length > 0 && <Form.Group as={Row} className="align-items-start">
          <Form.Label column sm="3" className="d-flex align-items-center">
            <span className="flex-grow-1">Headers</span>
          </Form.Label>
          <Col sm="9">
            {headersFields.map((field, index) => (
              <div className="d-flex mb-1" key={field.id}>
                <Form.Group className="flex-grow-1 mr-1 mb-0">
                  <Form.Control type="text" placeholder="Key" {...register(`headers.${index}.key`)} />
                </Form.Group>
                <Form.Group className="flex-grow-1 mr-1 mb-0">
                  <Form.Control type="text" placeholder="Value" {...register(`headers.${index}.value`)} />
                </Form.Group>
                <Button
                  size="md"
                  variant="danger"
                  onClick={() => removeHeader(index)}
                >
                  <IoIosTrash size="1.2em" /> Delete
                </Button>
              </div>
            ))}
          </Col>
        </Form.Group>}
        {parametersFields.length > 0 && <Form.Group as={Row} className="align-items-start">
          <Form.Label column sm="3" className="d-flex align-items-center">
            <span className="flex-grow-1">Parameters</span>
          </Form.Label>
          <Col sm="9">
            {parametersFields.map((field, index) => (
              <div className="d-flex mb-1" key={field.id}>
                <Form.Group className="flex-grow-1 mr-1 mb-0">
                  <Form.Control type="text" placeholder="Key" {...register(`parameters.${index}.key`)} />
                </Form.Group>
                <Form.Group className="flex-grow-1 mr-1 mb-0">
                  <Form.Control type="text" placeholder="Value" {...register(`parameters.${index}.value`)} />
                </Form.Group>
                <Button
                  size="md"
                  variant="danger"
                  onClick={() => removeParameter(index)}
                >
                  <IoIosTrash size="1.2em" /> Delete
                </Button>
              </div>
            ))}
          </Col>
        </Form.Group>}
      </Form>
    );
  }, [
    endpoint,
    webhookOptions,
    headersFields,
    parametersFields,
    handleAddParameterField,
    handleAddHeaderfield,
    formState,
  ]);

  return (
    <FormModal
      header={editMode ? "Edit endpoint" : "Add endpoint"}
      form={renderForm()}
      valid={formState.isValid && formState.isDirty}
      size="lg"
      confirmButtonProps={{variant: 'success'}}
      onShow={resetFormValues}
      confirmText={editMode ? "Update" : "Save"}
    >
      {children}
    </FormModal>
  );
}

export default EndpointFormModal;
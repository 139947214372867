
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';

import {
  IoIosCreate,
  IoIosTrash,
  IoIosCopy,
} from 'react-icons/io';

import copy from 'copy-to-clipboard';

import { deleteOrganisationToken } from '../actions';

import ConfirmModal from '../../../components/ConfirmModal';
import { addToast } from '../../../components/Toaster';

import EditOrganisationTokenFormModal from './EditOrganisationTokenFormModal';

const copyItemToClipboard = (item) => {
  const result = copy(item, {message: 'Press #{key} to copy'});
  if (result) {
    addToast({variant: 'success', header: 'Copied to clipboard'});
  }
};

function OrganisationTokenActions({
  deleteOrganisationToken,
  organisationId,
  token,
  description,
}) {

  return (
    <ButtonGroup>
      <EditOrganisationTokenFormModal
        organisationId={organisationId}
        token={token}
        description={description}
      >
        <Button
          size="md"
          variant="outline-secondary"
        >
          <IoIosCreate size="1.2em" /> Edit
        </Button>
      </EditOrganisationTokenFormModal>
      <Button
        size="md"
        variant="outline-secondary"
        onClick={() => copyItemToClipboard(token)}
      >
        <IoIosCopy size="1.2em" /> Copy
      </Button>
      <ConfirmModal
        header="Delete token?"
        body="This cannot be undone"
        confirmText="Delete"
      >
        <Button
          size="md"
          variant="danger"
          onClick={useCallback( () => {
            deleteOrganisationToken({ id: organisationId }, token);
          }, [organisationId, token])}
        >
          <IoIosTrash size="1.2em" /> Delete
        </Button>
      </ConfirmModal>
    </ButtonGroup>
  );
}

const mapDispatchToProps = {
  deleteOrganisationToken,
};

export default connect(null, mapDispatchToProps)(OrganisationTokenActions);

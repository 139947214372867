import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, AccordionContext } from 'react-bootstrap';
import { IoIosArrowForward } from 'react-icons/io';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import useAlarmEvent from "../../alarm/hooks/useAlarmEvent";
import useAlarm from '../../alarm/hooks/useAlarm';
import Table from "../../../components/Table";
import { AddCommentRow } from './DeviceAlarmEvents';
import { eventDateTime, user, alarmStatusExtra as statusBadge, categoryColorBadge, comment, eventActions } from "../../alarm/columns";
import { getDeviceHasProductCode } from '../selectors';
import { getAlarmDeviceId } from '../../alarm/selectors';

function AccordionToggle({eventKey, callback}) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;
  return (
    <div
      onClick={decoratedOnClick}
      aria-hidden="true"
      style={{cursor: 'pointer'}}
      className="d-flex align-items-center p-2"
    >
      <span
        className="d-flex align-items-center"
        style={{transform: `${isCurrentEventKey ? 'rotate(90deg)' : ''}`, transition: 'transofmr, .3s'}}>
        <IoIosArrowForward />
      </span>&nbsp;
      {isCurrentEventKey ? 'Close' : 'View'} comments and alarm history
    </div>
  );
}

function DeviceAlarmEventsTableView({alarmId}) {
  const { alarmEvents = [], postEvent, loading } = useAlarmEvent(alarmId);
  const alarm = useAlarm(alarmId);
  const deviceId = useSelector(state => getAlarmDeviceId(state, alarmId));
  const canEditEventFeedback = useSelector(state => getDeviceHasProductCode(state, deviceId, 'event_feedback'));
  const columns = [
    eventDateTime,
    user,
    statusBadge,
    categoryColorBadge,
    comment,
    eventActions,
  ];

  // @TODO: Lift alarm in the list to event: when alarm_id is available, show 'acknowledge' button
  // By acknowleding, the alarm is promoted to event.
  return (
    <>
      {canEditEventFeedback && alarm && alarm.alarm_status !== 'closed' &&
        <div className="pt-2 px-4">
          <AddCommentRow alarm={alarm} postEvent={postEvent} confirming={loading} />
        </div>
      }
      <Accordion>
        <div>
          {/* <Accordion.Toggle eventKey={alarmId} as={Card.Header}>
            Select to view comments and alarm history
          </Accordion.Toggle> */}
          <AccordionToggle eventKey={alarmId.toString()} />
          <Accordion.Collapse eventKey={alarmId.toString()}>
            <Table
              keyField="event_id"
              condensed
              data={alarmEvents}
              columns={columns}
            />
          </Accordion.Collapse>
        </div>
      </Accordion>
    </>
  );
}

export default DeviceAlarmEventsTableView;
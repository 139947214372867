import { useCallback, useRef, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import FormModal from '../../../components/FormModal';
import { useFocusableRef } from '../../../components/form/useFocusableRef';
import useAlarmEvent from '../../alarm/hooks/useAlarmEvent';

function EditCommentModal({ children, value: initialValue='', onSubmit }) {
  const formRef = useRef();
  const [focusRef, setFocus] = useFocusableRef();
  const [comment, setComment] = useState(initialValue);
  const trimmed = `${comment}`.trim();
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = useCallback(async () => {
    // submit only valid comments
    if (trimmed) {
      setSubmitting(true);
      setFocus(false);
      try {
        await onSubmit({ comment: trimmed });
        setSubmitting(false);
      }
      catch {
        setSubmitting(false);
      }
      setFocus(true);
    }
  }, [trimmed, onSubmit, setSubmitting, setFocus]);

  // fix up/down arrow keys from drawing focus outside the modal
  const handleKeyDown = useCallback(e => e.stopPropagation(), []);

  return (
    <FormModal
      // set defaults
      header="Edit comment"
      confirmText="Save"
      // override given props
      size="md"
      valid={!submitting && !!trimmed && trimmed !== initialValue}
      // on open, focus on focusRef
      onShow={() => {
        setFocus(true);
        setComment(initialValue);
      }}
      // reset focus on close
      onClose={() => {
        setFocus(false);
      }}
      form={(
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Form.Group as={Row} controlId="alarm-comment">
            <Form.Label column sm={5}>
              Comment
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                ref={focusRef}
                as="textarea"
                name="comment"
                disabled={submitting}
                value={comment}
                onKeyDown={handleKeyDown}
                onChange={e => setComment((e && e.target && e.target.value) || '')}
              />
            </Col>
          </Form.Group>
        </Form>
      )}
    >
      {children}
    </FormModal>
  );
}


function EditCommentButton({event, ...props}) {
  const { editEvent } = useAlarmEvent(event.alarm_id);
  const handleEditEvent = useCallback((payload) => {
    return editEvent(event.event_id, payload);
  }, [event.event_id]);

  return (
    <EditCommentModal value={event.comment} onSubmit={handleEditEvent}>
      <span className="btn btn-link btn-sm p-0" style={{cursor: 'pointer'}} {...props}>Edit</span>
    </EditCommentModal>
  );
}

export default EditCommentButton;

import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withNavigationOrganisationProps from './withNavigationOrganisationProps';

import { InfoCard, InfoCardItem } from '../../../components/InfoCard';

import { fetchOrganisationWithId } from '../actions';

import {
  getOrganisation,
  getOrganisationRmsDisplayPreferenceOption,
  getOrganisationMm2AvailablePreference,
} from '../selectors';
import { isAuthorised } from '../../user/selectors';

function OrganisationSidebar({
  organisation = {},
  userCanSeeParent,
  parentOrganisationId,
  parentOrganisation={},
  fetchOrganisationWithId,
  rmsDisplay,
  mm2Available,
}) {

  // fetch parent org information if the user does not have it yet
  useEffect(() => {
    if (userCanSeeParent && parentOrganisationId && !parentOrganisation.id) {
      fetchOrganisationWithId({ id: parentOrganisationId });
    }
  }, [userCanSeeParent, parentOrganisationId, fetchOrganisationWithId]);

  if (!organisation || !organisation.id) {
    return null;
  }

  return (
    <Fragment>
      <h5 className="montserrat" style={{ marginTop: 10, marginBottom: 15 }}>
        {organisation.name}
      </h5>
      <InfoCard>
        <InfoCardItem heading="Subdomain">
          {organisation.sub_domain}
        </InfoCardItem>
        {userCanSeeParent && parentOrganisation && parentOrganisation.id && (
          <InfoCardItem heading="Parent Organisation">
            <Link to={`/organisations/${parentOrganisation.id}/`}>
              {parentOrganisation.name || '(Parent Organisation)'}
            </Link>
          </InfoCardItem>
        )}
      </InfoCard>
      {mm2Available && rmsDisplay && (
        <InfoCard collapsible heading="Preferences">
          <InfoCardItem heading="Vibration">
            {rmsDisplay.title}
            {/* note: when organisation preferences are editable, add "(by default)" messaging */}
          </InfoCardItem>
        </InfoCard>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state, { organisationId }) => {
  const organisation = organisationId && getOrganisation(state, organisationId);
  const parentOrganisationId = organisation && organisation.parent_organisation_id;
  return {
    organisation,
    parentOrganisationId,
    userCanSeeParent: isAuthorised(state, { minUserType: 'Partner Admin', minOrgType: 'Partner' }),
    parentOrganisation: parentOrganisationId
      ? getOrganisation(state, parentOrganisationId)
      : undefined, // on parents this can be null which doesn't destructure well
    rmsDisplay: getOrganisationRmsDisplayPreferenceOption(state, organisationId),
    mm2Available: getOrganisationMm2AvailablePreference(state, organisationId),
  };
};

export default withNavigationOrganisationProps(
  connect(mapStateToProps, { fetchOrganisationWithId })(OrganisationSidebar)
);

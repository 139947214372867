
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Alert, Button } from 'react-bootstrap';

import IoIosMotor from '../../../components/IoIosMotor';

import Private from '../../../components/Private';

import { getUser, getUserDevices } from '../selectors';
import { fetchUserDevices } from '../actions';

function NoDevicesAlert({ userId, user={}, userDevices=[], fetchUserDevices }) {

  const [loadingOrOrgHasNoDevices, setLoading] = useState(!userDevices.length);

  useEffect(() => {
    // if we are attempting to load a user page, but the user is not yet in redux state
    // then attempt to fetch this user
    if (!userDevices.length) {
      (async function() {
        setLoading(true);
        await fetchUserDevices({ id: userId });
      })();
    }
    else {
      setLoading(false);
    }
  }, [userId, userDevices.length]);

  // don't show if still checking or the organisation itself has no devices
  if (loadingOrOrgHasNoDevices) {
    return null;
  }

  // count user devices
  const userDevicesCount = userDevices.reduce((acc, { display }) => acc + (display ? 1 : 0), 0);
  // if user has devices they need no alert
  if (userDevicesCount) {
    return null;
  }

  // if user has no devices then show an alert
  return (
    <Alert variant="warning">
      <p className="mb-0">
        <IoIosMotor size="1.4em"/> This user cannot view any equipment.
      </p>
      <Switch>
        <Route path={`/users/${user.id}/equipment/edit`}>
          <p className="mb-0 mt-2">Add equipment permissions below</p>
        </Route>
        <Route>
          <LinkContainer to={`/users/${user.id}/equipment/edit`}>
            <Button size="md" variant="outline-secondary" className="mt-2">
              <span>Edit equipment access</span>
            </Button>
          </LinkContainer>
        </Route>
      </Switch>
    </Alert>
  );
};

function UserNoDevicesAlert(props) {

  // if user in question is a non-user then exit
  if (!props.user || props.user.user_type !== 'User') {
    return null;
  }

  // only show this component to Admins
  return (
    <Private minUserType="Admin">
      <NoDevicesAlert {...props} />
    </Private>
  );
}

const mapStateToProps = (state, { userId }) => {
  return {
    user: userId && getUser(state, userId),
    userDevices: userId && getUserDevices(state, userId),
  };
};
const mapDispatchToProps = { fetchUserDevices };

export default connect(mapStateToProps, mapDispatchToProps)(UserNoDevicesAlert);

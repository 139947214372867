import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ContactSupportLink from './ContactSupportLink';
import Title from './Title';

export default class NotFound extends Component {
  render() {
    return (
      <Container fluid>
        <Title title="Not Found" />
        <p className="lead">The requested URL was not found. <Link to="/equipment/list">Continue to the equipment list page</Link>.</p>
        <p>If you still experience issues, please <ContactSupportLink subject="Page not found">contact Support</ContactSupportLink>.</p>
      </Container>
    );
  }
}
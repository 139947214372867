import { useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import { useGroupStatusSummary } from '../../modules/organisation/hooks/useGroupStats';
import HomeCard from './HomeCard';
import { getChartLabelColor } from '../lib/utils';
import useElementWidth from '../../hooks/useElementWidth';

const LG_BREAKDOWN = 620;
const SM_BREAKDOWN = 410;

// Device status chart for FitMachine.
function DeviceStatus() {
  const statusSummary = useGroupStatusSummary();
  const cardRef = useRef(null);
  const width = useElementWidth(cardRef.current);

  const chartOptions = {
    legend: width <= SM_BREAKDOWN ? {
      orient: 'horizontal',
      left: 'center',
      top: 'bottom',
      textStyle: {
        fontSize: 10,
      }
    }: {
      orient: 'vertical',
      left: 'left',
      top: 'top',
      textStyle: {
        fontSize: 12,
      }
    },
    center:
      width >= LG_BREAKDOWN ? ['50%', '50%'] :
        width <= SM_BREAKDOWN ? ['50%', '35%'] :
          ['66%', '50%'],
    radius: width <= SM_BREAKDOWN ? ['25%', '60%'] : ['30%', '70%']
  };

  if(!statusSummary) return null;

  const { items = [], _state_: {loading, error} = {} } = statusSummary || {};

  return (
    <div ref={cardRef}>
      <HomeCard
        header="FitMachine Status"
        headerLink={{ to: '/devices/admin', text: 'All FitMachines' }}
        loading={loading}
        error={error}
      >
        <ReactEcharts
          option={{
            tooltip: {
              formatter: '{b}: {d}%',
            },
            legend: {...chartOptions.legend, data: items.map(item => item.key)},
            series: {
              type: 'pie',
              radius: chartOptions.radius,
              center: chartOptions.center,
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center'
                }
              },
              color: items.map(item => getChartLabelColor(item.color)),
              data: items.map(item => ({
                value: item.count,
                name: item.key,
              })),
            }
          }}
        />
      </HomeCard>
    </div>
  );
}

export default DeviceStatus;

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoIosCheckbox } from 'react-icons/io';
import { MdExpandMore } from "react-icons/md";
import { FaBatteryFull, FaBatteryThreeQuarters, FaBatteryHalf, FaBatteryQuarter, FaBatteryEmpty } from 'react-icons/fa';
import { BiWifiOff } from 'react-icons/bi';
import { GrWifiLow, GrWifiMedium, GrWifiNone, GrWifi } from 'react-icons/gr';
import { BsQuestion } from 'react-icons/bs';

import { FormattedValue } from '../values/utils/displayUtils';
import { getTimezoneOffset } from '../values/Timezone';

import { isSuperAdmin } from '../../modules/user/selectors';

import useUniqueId from '../../hooks/useUniqueId';

const ONE_DAY = 24*3600*1000;

function capitalise(text='') {
  return text.substr(0, 1).toUpperCase() + text.substr(1);
}

// a consistent overlay for simple tooltips
export function Overlay({ tooltip, children }) {
  // create a unique id for this component to use for the overlay popup control
  const id = useUniqueId('tooltip');
  return (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip id={id}>{tooltip}</Tooltip>
      )}
    >
      {children}
    </OverlayTrigger>
  );
}

export function ExpansionIndicator({expanded}) {
  return (
    <Overlay tooltip={expanded ? 'Less' : 'More'}>
      <MdExpandMore style={{
        transform: `${expanded ? '' : 'rotate(-90deg)'}`,
        transition: 'transform, .5s',
        cursor: 'pointer'
      }} />
    </Overlay>
  );
}

// render header cells, with context from columnProps and redux state
// link https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/column-props.html#columnheaderformatter-function
export function HeaderCell(props) {
  const {
    // from columnProps
    text,
    tooltip,
    icon,
    // from components
    sortElement,
    filterElement,
    // from redux state
    units,
  } = props;

  // return the formatted text with a tooltip
  return (
    <div>
      <Overlay tooltip={tooltip || text}>
        {/* span is used because OverlayTrigger requires one child */}
        <span>{icon || text}{units && ` (${units.trim()})`}</span>
      </Overlay>
      { sortElement }
      { filterElement }
    </div>
  );
}

export function InlineHeaderCell(props) {
  const {
    text,
    tooltip,
    icon,
    sortElement,
    filterElement,
    units,
    dataField,
  } = props;

  // Keep the header and sort element in one line.
  return (
    <>
      <div className="d-flex" style={{ whiteSpace: 'nowrap' }}>
        {dataField.includes('latest') && <>Last&nbsp;</>}
        <Overlay tooltip={tooltip || text}>
          {/* span is used because OverlayTrigger requires one child */}
          <span>{icon || text}{units && `(${units.trim()})`}</span>
        </Overlay>
        { sortElement }
      </div>
      { filterElement }
    </>
  );
}

// row cells are just formatted value getters with certain defaults
export function RowCell(props) {
  return (
    <FormattedValue displayUnits={false} defaultValue="N/A" {...props} />
  );
}


/* export formatters for react-bootstrap-table2 columns */
// link: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/column-props.html

// export headerFormatter with an auto-generating id for overlays
export function headerFormatter(columnProps, colIndex, components) {
  return (
    <HeaderCell {...columnProps} {...components} />
  );
}

export function inlineHeaderFormatter(columnProps, colIndex, components) {
  return (
    <InlineHeaderCell {...columnProps} {...components} />
  );
}

// export basic cell formatters
export const valueFormatter = value => value;
export const nullFormatter = () => null;
export const lengthFormatter = value => value ? value.length : 0;


/* text formatters */

// format text with a default value
export const textFormatterCreator = defaultValue => {
  return value => value || defaultValue;
};

// format text with default N/A
export const textFormatter = textFormatterCreator('N/A');


/* date formatters */

// export different date formatter creators for filtering
export const absoluteDateFilterCreator = (defaultValue='N/A') => {
  return date => date ? moment(date).format('ll') : defaultValue;
};
export const relativeDateFilterCreator = (defaultValue='N/A') => {
  return date => date ? capitalise(moment(date).fromNow()) : defaultValue;
};
export const completeDateFilterCreator = (defaultValue='N/A') => {
  return date => {
    if(!date) return defaultValue;
    const diff = moment().diff(date);
    if(diff > ONE_DAY) {
      return moment(date).format('lll');
    }
    return capitalise(moment(date).fromNow());
  };
};

// export default formatters for filtering
export const absoluteDateFilter = absoluteDateFilterCreator();
export const relativeDateFilter = relativeDateFilterCreator();
export const completeDateFilter = completeDateFilterCreator();
export const relativeDateOrNeverFilter = relativeDateFilterCreator('Never');

const earliestDate = moment(new Date('2015-01-01'));
export function DateOverlay(props) {
  const {
    date,
    row,
    index,
    prefix,
    suffix,
    formatter,
    maxFuture = { weeks: 2 },
    unexpectedExplanation,
    userIsSuperAdmin,
  } = props;
  const dateMoment = moment(date);
  const latestDate = moment().add(maxFuture);
  const dateIsUnexpected = dateMoment.isBefore(earliestDate) || dateMoment.isAfter(latestDate);

  // if the date is outside the expected date range, then do not show the date information
  const formattedDate = dateIsUnexpected ? 'Unknown' : formatter(dateMoment);

  // show date-timestamp with a tooltip if the data is expected or user is a Super Admin
  return !dateIsUnexpected || userIsSuperAdmin ? (
    <Overlay
      tooltip={(
        <Fragment>
          {dateIsUnexpected && userIsSuperAdmin && (
            <div className="mb-2">
              <div className="mb-1">
                Tooltip only visible to Super&nbsp;Admins:
              </div>
              This timestamp has been marked as unknown due to it being earlier
              than {earliestDate.format('YYYY')} or
              later than {latestDate.fromNow()}.
              {unexpectedExplanation ? <div className="my-1">{unexpectedExplanation}</div> : ' '}
              The timestamp is:
            </div>
          )}
          {typeof prefix === 'function' ? prefix(dateMoment, row, index) : prefix || null}
          {dateMoment.format('LLLL')} ({getTimezoneOffset()})
          {typeof suffix === 'function' ? suffix(dateMoment, row, index) : suffix || null}
        </Fragment>
      )}
    >
      <span>{formattedDate}</span>
    </Overlay>
  ) : formattedDate;
}

const ConnectedDateOverlay = connect(state => ({
  userIsSuperAdmin: isSuperAdmin(state),
}))(DateOverlay);

const DateFormatterCreator = (defaultValue='N/A', opts={}, dateFilter=absoluteDateFilter) => {
  return (date, row, index) => date ? (
    <ConnectedDateOverlay
      date={date}
      row={row}
      index={index}
      {...opts}
      formatter={dateFilter}
    />
  ) : defaultValue;
};

// create a formatter function which has a namespace for overlays and a default value
export const absoluteDateFormatterCreator = (defaultValue='N/A', opts={}) => {
  return (date, row, index) => date ? (
    <ConnectedDateOverlay
      date={date}
      row={row}
      index={index}
      {...opts}
      formatter={absoluteDateFilter}
    />
  ) : defaultValue;
};

// create a formatter function which has a namespace for overlays and a default value
export const relativeDateFormatterCreator = (defaultValue='N/A', opts={}) => {
  return (date, row, index) => date ? (
    <ConnectedDateOverlay
      date={date}
      row={row}
      index={index}
      {...opts}
      formatter={relativeDateFilter}
    />
  ) : defaultValue;
};

export const absoluteDateFormatter = DateFormatterCreator('N/A', {}, absoluteDateFilter);
export const relativeDateFormatter = DateFormatterCreator('N/A', {}, relativeDateFilter);
export const completeDateFormatter = DateFormatterCreator('N/A', {}, completeDateFilter);

// number formatters //

// format number with a default value
export const numberFormatterCreator = (defaultValue, digits=2, append='') => {
  const formatOptions = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
  return value => typeof value === 'number'
    // return a formatted number according to the user's locale
    ? `${value.toLocaleString(undefined, formatOptions)}${append}`
    : defaultValue;
};

// format x digits with default N/A
export const zeroDigitsNumberFormatter = numberFormatterCreator('N/A', 0);
export const twoDigitsNumberFormatter = numberFormatterCreator('N/A', 2);
export const threeDigitsNumberFormatter = numberFormatterCreator('N/A', 3);

// format 0 digits percentage with default N/A
export const percentageNumberFormatter = numberFormatterCreator('N/A', 0, '%');

// format latitude and longitude to a reasonable level
// check: https://en.wikipedia.org/wiki/Decimal_degrees
// 6 digits is about 10cm resolution
// 7 digits is about 1cm resolution
export const latLngFormatter = numberFormatterCreator('N/A', 7);


// other formatters //

// calibration may may multiple state dues to inclusion of the is_calibrating field
// is is_calibrating is truthy then we should render a calibration percentage
// if valid calibration exists, we should display this regardless of is_calibrating
export function calibrationFormatter(calibration, { is_calibrating }) {
  const value = is_calibrating || typeof calibration === 'number' ? calibration || 0 : null;
  return percentageNumberFormatter(value);
}

export function booleanFormatter(value) {
  return value ? (
    <IoIosCheckbox size="1.1em"/>
  ) : (
    <Form.Check disabled style={{ marginLeft: 2 }} />
  );
}

export function securityFormatter(value) {
  const startingCharacter = 0;
  const percentageOfCharactersToDisplay = 0.1;
  const valueString = value ? `${value}` : '';
  const valueStringLength = valueString.length;
  const charsToDisplay = Math.ceil(valueStringLength * percentageOfCharactersToDisplay);
  // be extra cautious and cover all possibilities
  if (valueStringLength === 0) return 'N/A';
  if (valueStringLength === 1) return '...';
  if (valueStringLength === 2) return `${valueString[0]}...`;
  const start = valueString.slice(startingCharacter, charsToDisplay);
  const end = valueString.slice((valueStringLength - charsToDisplay), valueStringLength);
  return `${start}...${end}`;
}

function BatteryScoreIcon({value, size = 30}) {
  switch(value) {
    case 0:
      return <FaBatteryEmpty size={size} className="text-danger" />;
    case 1:
      return <FaBatteryQuarter size={size} />;
    case 2:
      return <FaBatteryHalf size={size} />;
    case 3:
      return <FaBatteryThreeQuarters size={size} />;
    case 4:
      return <FaBatteryFull size={size} />;
    default:
      return <BsQuestion size={size} />;
  }
}

export function batteryScoreFormatter(value, row = {}) {
  const { battery_voltage } = row;
  return (
    <Overlay tooltip={ !isNaN(battery_voltage) ? `${Number(battery_voltage).toFixed(2)}v` : 'N/A' }>
      <div>
        <BatteryScoreIcon value={value} />
      </div>
    </Overlay>
  );
}

function WifiScoreIcon({ value, size = 30 }) {
  switch(value) {
    case 0:
      return <GrWifiNone size={size} className="text-danger" />;
    case 1:
      return <GrWifiLow size={size} className="text-danger" />;
    case 2:
    case 3:
      return <GrWifiMedium size={size} />;
    case 4:
      return <GrWifi size={size} />;
    default:
      return <BiWifiOff size={size} />;
  }
}

export function wifiScoreFormatter(value, row = {}) {
  const { wifi_signal } = row;
  return (
    <Overlay tooltip={ !isNaN(wifi_signal) ? `${wifi_signal}dB` : 'N/A' }>
      <div>
        <WifiScoreIcon value={value} />
      </div>
    </Overlay>
  );
}
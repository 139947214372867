import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

import DateRangePicker from '../form/DateRangePicker';

import DownloadChartCSVLink from './DownloadChartCSVLink';
import DownloadChartImageLink from './DownloadChartImageLink';
import LoadingSpinner from '../LoadingSpinner';
import { IoIosInformationCircleOutline } from 'react-icons/io';

import { trackEvent } from '../../modules/app/actions';

export function convertToTimestamp(time) {
  return (new Date(time)).getTime();
}

function convertToLocalDateString(time) {
  const date = new Date(time);
  return `${
    date.getFullYear()
  }${
    `${date.getMonth()+1}`.padStart(2, '0')
  }${
    `${date.getDate()}`.padStart(2, '0')
  }`;
}

// add style enforcement fix for the Dropdown components
// for the Menu to continue aligning to the right of the Toggle
// even when the size of the Menu is resized, otherwise the user receives
// a bad experience where the dropdown is right-aligned
// but popper.js has pinned the dropdown position to the left
// before:   ____________                       ____________
//          | Download ▼ |                     | Download ▼ |
//       ___|____________|       -->        ___|____________|___________
//      | ◌ Download CSV |                 | ⦰ Download CSV (0 samples) |
//      |________________|                 |____________________________|
// after:    ____________                       ____________
//          | Download ▼ |                     | Download ▼ |
//       ___|____________| -->  _______________|____________|
//      | ◌ Download CSV |     | ⦰ Download CSV (0 samples) |
//      |________________|     |____________________________|
export const popperConfigUpdateOnChange = {
  modifiers: [
    {
      enabled: true,
      name: 'updateOnMenuContentChanges',
      phase: 'afterWrite',
      effect: ({ state, instance }) => {
        // watch for changes if possible
        // https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
        if (MutationObserver) {
          const popper = state && state.elements && state.elements.popper;
          // update instance on mutation observed
          const observer = new MutationObserver(() => instance.update());
          // start observing the popper menu contents
          observer.observe(popper, { characterData: true, subtree: true });
          // cleanup effect by disconnecting the observer
          return () => observer.disconnect();
        }
      },
    },
  ],
};

// times in ms
const oneHour = 1000 * 60 * 60;
const oneDay = 24 * oneHour;

function BaseChartToolbar({
  namespace,
  deviceId,
  dateRange,
  setDateRange,
  selectableDateRange,
  samples = [],
  hasMore,
  stillFetching,
  toolbarButtons = [],
  getChart,
  renderId,
  trackEvent,
}) {

  const [downloadMenuIsOpen, setDownloadMenuIsOpen] = useState(false);

  const onDatesChange = useCallback(({ startDate, endDate }) => {
    // set range of chart, if both dates are defined
    // react-dates passes back the times as moment objects or `null`
    if (startDate && endDate) {
      setDateRange({
        startTime: startDate.startOf('day').valueOf(),
        // if the end date is today then
        // limit the range up to now, not up to the end of today
        endTime: Math.min(Date.now(), endDate.endOf('day').valueOf()),
        fetchData: namespace !== 'energy-overview', // Do not fetch sample data for energy overview chart.
      });
    }
  }, [setDateRange]);

  const isOutsideRange = useCallback(day => {
    const { startTime, endTime } = (selectableDateRange || {});
    return day.endOf('day').isBefore(startTime)
      || day.startOf('day').isAfter(endTime);
  }, [selectableDateRange]);

  const chartNameParts = {
    equipment: deviceId,
    chart: `${namespace}`.replace(/[^a-z]+/g, '_'),
    from: convertToLocalDateString(dateRange.startTime),
    to: convertToLocalDateString(dateRange.endTime),
  };

  const trackRange = dateRange.endTime > dateRange.startTime && {
    days: Math.round((dateRange.endTime - dateRange.startTime) / oneDay),
    hours: Math.round((dateRange.endTime - dateRange.startTime) / oneHour),
  };

  const trackDownloadCsvClick = useCallback(() => {
    trackEvent('Device Samples CSV', 'downloaded', {
      deviceId,
      date_range_days: trackRange && trackRange.days,
      date_range_hours: trackRange && trackRange.hours,
    });
  }, [
    trackEvent,
    deviceId,
    trackRange,
  ]);

  const trackDownloadImageClick = useCallback(() => {
    trackEvent('Device Chart PNG', 'downloaded', {
      deviceId,
      chart_name: chartNameParts.chart,
      date_range_days: trackRange && trackRange.days,
      date_range_hours: trackRange && trackRange.hours,
    });
  }, [
    trackEvent,
    deviceId,
    chartNameParts.chart,
    trackRange,
  ]);

  return (
    <>
      <Row className="small-gutters">
        <Col xs="auto" className="mb-1">
          {/* time period picker goes here */}
        </Col>
        <Col xs="auto" className="ml-auto">
          <Row className="small-gutters d-flex justify-content-end align-items-center">
            {stillFetching ? (
              <Col xs="auto" className="mb-1">
                <LoadingSpinner size={2} />
              </Col>
            ) : hasMore ? (
              <Col xs="auto" className="mb-1">
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip>
                      There is more data in the past for this chart
                    </Tooltip>
                  )}
                >
                  <IoIosInformationCircleOutline size="1.4em" className="react-icon--inline" />
                </OverlayTrigger>
              </Col>
            ) : null
            // (
            //   <Col xs="auto" className="mb-1">
            //     <OverlayTrigger
            //       placement="top"
            //       overlay={(
            //         <Tooltip>
            //           There is no more data in the past for this chart
            //         </Tooltip>
            //       )}
            //     >
            //       <IoIosWarning size="1.4em" className="react-icon--inline" />
            //     </OverlayTrigger>
            //   </Col>
            // )
            }
            <Col xs="auto" className="mb-1">
              <DateRangePicker
                startDateId={`${namespace}-chart-startDate`}
                endDateId={`${namespace}-chart-endDate`}
                startDate={dateRange.startTime}
                endDate={dateRange.endTime}
                onDatesChange={onDatesChange}
                isOutsideRange={isOutsideRange}
                readOnly={false}
                disabled={stillFetching}
              />
            </Col>
            <Col xs="auto" className="mb-1">
              <Dropdown
                // wrap callback to prevent React hooks warning
                // about using setState with multiple function args
                onToggle={useCallback(isOpen => {
                  setDownloadMenuIsOpen(isOpen);
                }, [setDownloadMenuIsOpen])}
              >
                <Dropdown.Toggle
                  variant="outline-dark"
                  size="lg"
                >
                  Save as
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  show={!!downloadMenuIsOpen}
                  popperConfig={popperConfigUpdateOnChange}
                >
                  {downloadMenuIsOpen && (
                    // prevent re-computation of item when dropdown is closed
                    <Dropdown.Item
                      LinkComponent={Button}
                      LinkComponentAs="a"
                      as={DownloadChartCSVLink}
                      name={`${
                        'MOVUS'
                      }-equipment_${
                        chartNameParts.equipment
                      }-to_${
                        chartNameParts.to
                      }-from_${
                        chartNameParts.from
                      }`}
                      loading={stillFetching}
                      samples={samples.map(sample => ({...sample, 'sample_timestamp': sample.sample_time,  'sample_time': (new Date(sample.sample_time)).toString()}))}
                      startTime={dateRange.startTime}
                      endTime={dateRange.endTime}
                      onClick={trackDownloadCsvClick}
                      namespace={namespace}
                    >
                      Save data as CSV
                    </Dropdown.Item>
                  )}
                  {downloadMenuIsOpen && (
                    // prevent re-computation of item when dropdown is closed
                    <Dropdown.Item
                      LinkComponent={Button}
                      LinkComponentAs="a"
                      as={DownloadChartImageLink}
                      chart={getChart()}
                      name={`${
                        'MOVUS'
                      }-equipment_${
                        chartNameParts.equipment
                      }-to_${
                        chartNameParts.to
                      }-from_${
                        chartNameParts.from
                      }-chart_${
                        chartNameParts.chart
                      }`}
                      renderId={renderId}
                      onClick={trackDownloadImageClick}
                    >
                      Save image as PNG
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {toolbarButtons.map((toolbarButton, index) => (
              // place any given toolbar components in consistent formatting
              <Col key={index} xs="auto" className="mb-1">
                {toolbarButton}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default connect(null, { trackEvent })(BaseChartToolbar);

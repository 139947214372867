
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Row, Col, Form, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import FormModal from '../../../components/FormModal';
import { useFocusableRef } from '../../../components/form/useFocusableRef';

import { getOrganisation, getGroup, getGroupTreeNode, getGroupTreeNamePath, getCurrentOrganisationHasProductCode } from '../selectors';
import { fetchGroup, fetchOrganisationGroups, createGroup, selectActiveGroup } from '../actions';
import { getFormValues } from '../../../lib/utils';

const confirmButtonProps = {
  variant: 'success',
};

function AddGroupFormModal({
  groupId,
  groupNode = {},
  // get most current data from group list, default to (presumably stale) data from group tree
  group = {
    id: groupNode.id,
    group_name: groupNode.name,
    group_type: groupNode.type,
  },
  groupTreeNamePath,
  organisation = {},
  selectActiveGroup,
  fetchGroup,
  createGroup,
  fetchOrganisationGroups,
  children,
  ...props
}) {

  const [name, setName] = useState('');
  const [pristineName, setPristineName] = useState(true);
  const [groupType, setGroupType] = useState('group');

  const parentGroupType = group.group_type;
  useEffect(() => {
    // set initial group type to either device/user/group
    setGroupType(
      parentGroupType === 'device' || parentGroupType === 'user'
        ? parentGroupType
        : 'group'
    );
  }, [parentGroupType]);

  const [valid, setValid] = useState(false);

  const formRef = useRef(null);
  const [nameRef, setShown] = useFocusableRef(null);

  const handleSubmit = useCallback(async e => {
    const { group_name, group_type, parent_id } = getFormValues(e, formRef.current) || {};
    if (group_name && parseInt(parent_id) > 0) {
      try {
        const { headers } = await createGroup({ group_name, group_type, parent_id: parseInt(parent_id) });
        await fetchOrganisationGroups(organisation);
        const groupId = headers && headers.location && parseInt(headers.location.split('/').pop());
        if (groupId > 0) {
          selectActiveGroup({ id: groupId });
        }
      }
      catch(err) {
        // allow error to prevent closing of the modal
        throw new Error(err);
      }
    }
  }, [createGroup, formRef.current, organisation]);

  const hasUserGroupsFeature = useSelector(state => getCurrentOrganisationHasProductCode(state, 'groups_user'));

  return (
    <FormModal
      // set defaults
      header="Add group"
      confirmText="Add group"
      confirmButtonProps={confirmButtonProps}
      // add given props
      {...props}
      // override given props
      size="md"
      valid={valid}
      onShow={useCallback(() => {
        setShown(true);
      }, [])}
      onClose={useCallback(() => {
        setShown(false);
        setName('');
      }, [])}
      form={(
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Form.Group as={Row} controlId="group_name">
            <Form.Label column sm={5}>
              Name
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                ref={nameRef}
                type="text"
                name="group_name"
                isInvalid={!pristineName && !valid}
                value={name}
                onChange={e => {
                  const name = e.target.value;
                  setPristineName(false);
                  setName(name);
                  setValid(!!name);
                }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="group_type">
            <Form.Label column sm={5}>
              Type
            </Form.Label>
            <Col sm={7}>
              <ToggleButtonGroup
                type="radio"
                name="group_type"
                value={groupType}
                onChange={groupType => setGroupType(groupType)}
              >
                <ToggleButton
                  variant={groupType === 'group' ? 'primary' : 'light'}
                  disabled={(
                    parentGroupType === 'device' ||
                    parentGroupType === 'user'
                  )}
                  value="group"
                >
                  General
                </ToggleButton>
                <ToggleButton
                  variant={groupType === 'device' ? 'primary' : 'light'}
                  disabled={parentGroupType === 'user'}
                  value="device"
                >
                  Device group
                </ToggleButton>
                {hasUserGroupsFeature && (
                  <ToggleButton
                    variant={groupType === 'user' ? 'primary' : 'light'}
                    disabled={parentGroupType === 'device'}
                    value="user"
                  >
                    User group
                  </ToggleButton>
                )}
              </ToggleButtonGroup>
            </Col>
          </Form.Group>
          <Form.Control
            type="hidden"
            name="parent_id"
            defaultValue={group.id}
          />
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Parent group
            </Form.Label>
            <Form.Label column sm={7}>
              <p>
                {groupTreeNamePath}
              </p>
              <Form.Text id="equipment_model_help" className="text-muted">
                Choose a different parent group by selecting it in the sidebar and pressing “Add”
              </Form.Text>
            </Form.Label>
          </Form.Group>
        </Form>
      )}
    >
      {children}
    </FormModal>
  );
}

const mapStateToProps = (state, { groupId }) => {
  return {
    groupId,
    group: getGroup(state, groupId),
    groupNode: getGroupTreeNode(state, groupId),
    groupTreeNamePath: getGroupTreeNamePath(state, groupId),
    organisation: getOrganisation(state),
  };
};
const mapDispatchToProps = {
  selectActiveGroup,
  fetchGroup,
  fetchOrganisationGroups,
  createGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  return useSelector(state => getCurrentOrganisationHasProductCode(state, 'groups_equipment')) ? (
    <AddGroupFormModal {...props} />
  ) : null;
});

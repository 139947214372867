import { useState, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Row, Col, Form, Modal } from 'react-bootstrap';
import { GoAlert } from 'react-icons/go';
import { FaCogs } from 'react-icons/fa';
import moment from 'moment';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ConfirmModal from '../../../components/ConfirmModal';
import SingleDatePicker from '../../../components/form/SingleDatePicker';
import useAlarm from '../../alarm/hooks/useAlarm';
import useAlarmEvent from '../../alarm/hooks/useAlarmEvent';
import useDomain from '../../domain/hooks/useDomain';

function isToday(day) {
  if(!moment.isMoment(day)) return false;
  const today = moment();
  return today.date() === day.date() && today.month() === day.month() && today.year() === day.year();
}

function DeviceAlarmStatusRow({variant = 'light', status, action, className}) {
  return (
    <div
      className={[
        `m-0 px-4 py-3 rounded-0 alert alert-${variant}`,
        // allow light variant to have a visible border
        variant === 'light' && 'border-top',
        className,
      ].filter(Boolean).join(' ')}
    >
      <Row>
        <Col className="d-flex align-items-center">{status && status()}</Col>
        <Col xs="auto">{action && action()}</Col>
      </Row>
    </div>
  );
}

function AckConfirmModal({confirm, ...props}) {
  return (
    <Modal {...props} >
      <Modal.Header closeButton>
        <Modal.Title>
          <GoAlert className="text-danger" />&nbsp;Warning! Are you sure you want to Acknowledge this alarm?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Acknowledging this alarm will promote it to become the new Current Event
        and prevent any further activity on the previous Event.
        Once Acknowledged, this cannot be undone.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onHide}>Cancel</Button>
        <Button variant="warning" onClick={confirm}>Acknowledge</Button>
      </Modal.Footer>
    </Modal>
  );
}

function AckFormModal({onSubmit, loading, alarm, ...props}) {
  const { register, handleSubmit, watch, setValue, formState } = useForm({
    mode: 'onChange',
  });
  const watchAlarmStatus = watch('alarm_status');

  const { domainData: invalidReasons, fetchAlarmInvalidReasons } = useDomain({
    key: 'alarmInvalidReasons', fetch: false,
  });
  const { domainData: validReasons, fetchAlarmValidReasons } = useDomain({
    key: 'alarmValidReasons', fetch: false,
  });
  const reasons = useMemo(() => {
    return (
      watchAlarmStatus === 'valid' ?
        validReasons :
        watchAlarmStatus === 'invalid' ?
          invalidReasons: reasons) || [];
  }, [invalidReasons, validReasons, watchAlarmStatus]);

  useEffect(() => {
    if(!props.show) return;
    if(watchAlarmStatus === 'valid' && !validReasons) fetchAlarmValidReasons();
    if(watchAlarmStatus === 'invalid' && !invalidReasons) fetchAlarmInvalidReasons();
  }, [props.show, watchAlarmStatus]);

  useEffect(() => {
    if(watchAlarmStatus === 'acknowledged') setValue('reason', undefined);  // When action is switched to acknowledged, set reason as empty value.
    if(watchAlarmStatus === 'valid') setValue('reason', '');
    if(watchAlarmStatus === 'invalid') setValue('reason', '');  // Reset reason dropdown.
  }, [watchAlarmStatus]);

  useEffect(() => {
    if(props.show) setValue('alarm_status', 'acknowledged');
  }, [props.show]);

  const submitData = useCallback((data) => {
    if(typeof onSubmit === 'function') onSubmit(data);
  }, [onSubmit]);

  const actions = [{
    alarm_status: 'acknowledged',
    option: 'Save as acknowledged',
  }, {
    alarm_status: 'valid',
    option: 'Mark alarm as valid',
  }, {
    alarm_status: 'invalid',
    option: 'Mark alarm as invalid',
  }];

  return (
    <Modal
      {...props}
    >
      <Modal.Header closeButton>
        <Modal.Title><FaCogs />&nbsp;Action Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Add any relevant comments and select from the list below&nbsp;
        if you wish to proceed to validate or save the current details.
      </Modal.Body>
      <Form>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="3">Reason</Form.Label>
            <Col sm="9">
              <Form.Control
                as="select"
                disabled={watchAlarmStatus!=='valid' && watchAlarmStatus!=='invalid'}
                {...register('reason', { validate: value => value !== '' })}
                defaultValue={''}
              >
                <option hidden value={''}></option>
                {reasons.map((option, index)=> {
                  return <option key={index} value={option}>{option}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">Comment</Form.Label>
            <Col sm="9">
              <Form.Control
                as="textarea"
                {...register('comment')}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Action
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="select"
                {...register('alarm_status', {required: true})}
              >
                {actions.map((action, index) => {
                  return <option key={action.alarm_status} value={action.alarm_status}>{action.option}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {loading && <LoadingSpinner inline size={1} />}
          <Button variant="outline-secondary" onClick={props.onHide} disabled={loading}>Cancel</Button>
          <Button
            variant="danger"
            type="button"
            disabled={loading || !formState.isValid}
            onClick={handleSubmit(data => submitData({...data, notify: false}))}
          >
            Proceed
          </Button>
          {watchAlarmStatus === 'valid' && alarm?._config?.notify_on_valid &&
            <Button
              variant="warning"
              type="button"
              disabled={loading || !formState.isValid}
              onClick={handleSubmit(data => submitData({...data, notify: true}))}
            >Proceed with Notification</Button>
          }
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function AcknowledgeActionButton({alarm, variant="danger", children = 'Acknowledge'}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { postEvent, loading } = useAlarmEvent(alarm.id, { eventId: alarm.eventId });

  const handleAcknowledge = useCallback((data) => {
    postEvent(data);
  }, [postEvent]);
  // console.log(loading, error, success);
  const handleClickAckButton = useCallback(() => {
    if(alarm.is_legacy) {
      setShowForm(true);
    } else {
      setShowConfirm(true);
    }
  }, []);

  return (
    <>
      <AckConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        confirm={() => { setShowConfirm(false); setShowForm(true); }}
      />
      <AckFormModal
        show={showForm}
        onHide={() => setShowForm(false)}
        alarm={alarm}
        onSubmit={handleAcknowledge}
        loading={loading}
      />
      <Button variant={variant} onClick={handleClickAckButton}>{children}</Button>
    </>
  );
}

function CloseActionButton({alarm}) {
  const { postEvent } = useAlarmEvent(alarm.id);

  const handleClose = useCallback(() => {
    postEvent({ alarm_status: 'closed' });
  }, []);

  return (
    <ConfirmModal
      header={
        <>
          <GoAlert className="text-danger" />&nbsp;Warning! Are you sure to close this event?
        </>
      }
      body={
        <>
          Closing this event will prevent you from adding any additional comments or performing any further status changes.&nbsp;
          This cannot be undone.
        </>
      }
      confirmText="Close Event"
      confirmButtonProps={{variant: 'dark'}}
    >
      <Button variant="dark" onClick={handleClose}>Close</Button>
    </ConfirmModal>
  );
}

function CloseConfirmModal({confirm, loading, ...props}) {
  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>
          <GoAlert className="text-danger" />&nbsp;Warning! Are you sure to close this event?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Closing this event will prevent you from adding any additional comments or performing any further status changes.&nbsp;
        This cannot be undone.</p>
      </Modal.Body>
      <Modal.Footer>
        {loading && <LoadingSpinner inline size={1} />}
        <Button variant="outline-secondary" onClick={props.onHide} disabled={loading}>Cancel</Button>
        <Button variant="dark" onClick={confirm} disabled={loading}>Close Event</Button>
      </Modal.Footer>
    </Modal>
  );
}

function ValidateFormModal({onSubmit, isValid, loading, alarm, ...props}) {
  const canClose = alarm.alarm_status === 'acknowledged'; // When alarm status is acknowledged only, you can close it
  const { show } = props;
  const { register, handleSubmit, watch, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      alarm_status: isValid ? 'valid' : 'invalid'
    }
  });
  const watchAlarmStatus = watch('alarm_status');
  const [date, setDate] = useState(null);

  const { domainData: invalidReasons, fetchAlarmInvalidReasons } = useDomain({key: 'alarmInvalidReasons', fetch: false, cache: false});
  const { domainData: validReasons, fetchAlarmValidReasons } = useDomain({key: 'alarmValidReasons', fetch: false, cache: false});

  useEffect(() => {
    if(watchAlarmStatus === 'scheduled') setDate(moment().add(7, 'days'));
    else setDate(null);
  }, [watchAlarmStatus]);

  useEffect(() => {
    if(!show) return;
    if(isValid) {
      fetchAlarmValidReasons();
    } else {
      fetchAlarmInvalidReasons();
    }
  }, [show]);

  const reasons = useMemo(() => {
    return isValid ? validReasons || [] : invalidReasons || [];
  }, [invalidReasons, validReasons]);

  const actions = isValid ?
    [
      { option: 'Save as valid', alarm_status: 'valid' },
      { option: 'Close as valid', alarm_status: 'closed' },
      { option: 'Schedule maintenance activity', alarm_status: 'scheduled' }] :
    [
      { option: 'Save as invalid', alarm_status: 'invalid' },
      { option: 'Close as invalid', alarm_status: 'closed' },
    ];

  const submitData = useCallback((data) => {
    if(date) data.due_date = moment(date).format('YYYY-MM-DD');
    if(data.alarm_status === 'closed') data.can_close_as = isValid ? 'valid' : 'invalid';  // Close alarm as valid or invalid.
    if(typeof onSubmit==='function') {
      onSubmit(data);
    }
  }, [onSubmit, date]);

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title><FaCogs />&nbsp;Action Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Select the reason for the valid event, add any relevant comments&nbsp;
        and then select the next action to be taken.
      </Modal.Body>
      <Form>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="3">Reason</Form.Label>
            <Col sm="9">
              <Form.Control
                as="select"
                {...register('reason', { validate: value => value !== '' })}
                defaultValue={''}
              >
                <option hidden value={''}></option>
                {reasons.map((option, index) => {
                  return <option key={index} value={option}>{option}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">Comment</Form.Label>
            <Col sm="9">
              <Form.Control
                as="textarea"
                {...register('comment')}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">Action</Form.Label>
            <Col sm="9">
              <Form.Control as="select" {...register('alarm_status', { required: true })} disabled={!canClose}>
                {actions.map((action, index) => {
                  return <option key={index} value={action.alarm_status}>{action.option}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <div style={{minHeight: '50px'}}>
            {watchAlarmStatus === 'scheduled' && <Form.Group as={Row}>
              <Form.Label column sm="3">Date</Form.Label>
              <Col sm="9">
                <SingleDatePicker
                  date={date}
                  isOutsideRange={day => !isToday(day) && day.isBefore(moment()) }
                  onDateChange={date => setDate(date)}
                />
              </Col>
            </Form.Group>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading && <LoadingSpinner inline size={1} />}
          <Button variant="outline-secondary" onClick={props.onHide} disabled={loading}>Cancel</Button>
          <Button variant="danger" type="button" disabled={loading || !formState.isValid} onClick={handleSubmit(data => submitData({...data, notify: false}))}>Proceed</Button>
          {isValid && alarm?._config?.notify_on_valid && <Button
            variant="warning"
            type="button"
            disabled={loading || !formState.isValid}
            onClick={handleSubmit(data => submitData({...data, notify: true}))}>
              Proceed with Notification
          </Button>}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function ValidateActionButton({alarm, variant="warning", buttonText="Valid"}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [dataToSubmit, setDataToSubmit] = useState({});
  const { postEvent, loading } = useAlarmEvent(alarm.id);

  const handleValidate = useCallback(data => {
    if(data.alarm_status === 'closed') {
      // Show confirm modal if use choose to close event.
      setDataToSubmit(data);
      setShowForm(false);
      setShowConfirm(true);
      return;
    }
    postEvent(data);
  }, [postEvent]);

  const handleConfirm = useCallback(() => {
    postEvent(dataToSubmit);
  }, [postEvent, dataToSubmit]);

  return (
    <>
      <ValidateFormModal
        show={showForm}
        onHide={() => setShowForm(false)}
        isValid
        onSubmit={handleValidate}
        loading={loading}
        alarm={alarm}
      />
      <CloseConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        confirm={handleConfirm}
        loading={loading}
      />
      <Button variant={variant} onClick={() => setShowForm(true)}>{buttonText}</Button>
    </>
  );
}

function InvalidateActionButton({alarm, variant="warning"}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [dataToSubmit, setDataToSubmit] = useState({});
  const { postEvent, loading } = useAlarmEvent(alarm?.id);
  const handleValidate = useCallback(data => {
    if(data.alarm_status === 'closed') {
      // Show confirm modal if use choose to close event.
      setDataToSubmit(data);
      setShowForm(false);
      setShowConfirm(true);
      return;
    }
    postEvent(data);
  }, [postEvent]);

  const handleConfirm = useCallback(() => {
    postEvent(dataToSubmit);
  }, [postEvent, dataToSubmit]);

  return (
    <>
      <ValidateFormModal
        show={showForm}
        onHide={() => setShowForm(false)}
        isValid={false}
        onSubmit={handleValidate}
        loading={loading}
        alarm={alarm}
      />
      <CloseConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        confirm={handleConfirm}
        loading={loading}
      />
      <Button variant={variant} onClick={() => setShowForm(true)}>Invalid</Button>
    </>
  );
}

function ScheduleFormModal({onSubmit, loading, ...props}) {
  const { register, handleSubmit } = useForm({
    mode: 'onChange',
  });
  const [date, setDate] = useState(moment().add(7, 'days'));

  const submitData = useCallback((data) => {
    data.alarm_status = 'scheduled';
    if(date) {
      data.due_date = moment(date).format('YYYY-MM-DD');
    }
    if(typeof onSubmit === 'function') onSubmit(data);
  }, [onSubmit, date]);

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title><FaCogs />&nbsp;Action Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>Schedule the maintenance activity.</Modal.Body>
      <Form onSubmit={handleSubmit(submitData)}>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="3">Comment</Form.Label>
            <Col sm="9">
              <Form.Control as="textarea" {...register('comment')} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">Date</Form.Label>
            <Col sm="9">
              <SingleDatePicker
                date={date}
                isOutsideRange={day => !isToday(day) && day.isBefore(moment())}
                onDateChange={date => setDate(date)}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {loading && <LoadingSpinner inline size={1} />}
          <Button variant="outline-secondary" onClick={props.onHide} disabled={loading}>Cancel</Button>
          <Button variant="danger" type="submit" disabled={loading}>Proceed</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function ScheduleActionButton({alarm}) {
  const [showForm, setShowForm] = useState(false);
  const { postEvent, loading, } = useAlarmEvent(alarm.id);
  const handleValidate = useCallback(data => {
    postEvent(data);
  }, [postEvent]);

  return (
    <>
      <ScheduleFormModal
        show={showForm}
        onHide={() => setShowForm(false)}
        onSubmit={handleValidate}
        loading={loading}
      />
      <Button variant="info" onClick={() => setShowForm(true)}>Schedule</Button>
    </>
  );
}

function UpdateFormModal({onSubmit, loading, ...props}) {
  const statusActions = [
    {alarm_status: 'in_progress', option: 'Maintenance activity in progress'},
    {alarm_status: 'resolved', option: 'Maintenance completed'},
    {alarm_status: 'scheduled', option: 'Maintenance activity needing rescheduling'}
  ];

  const { register, handleSubmit, watch } = useForm({
    mode: 'onChange',
  });
  const watchAlarmStatus = watch('alarm_status');
  const [date, setDate] = useState(null);

  useEffect(() => {
    if(watchAlarmStatus === 'scheduled') {
      setDate(moment().add(7, 'days'));
    } else {
      setDate(null);
    }
  }, [watchAlarmStatus]);

  const submitData = useCallback((data) => {
    if(date) data.due_date = moment(date).format('YYYY-MM-DD');
    if(typeof onSubmit === 'function') onSubmit(data);
  }, [onSubmit, date]);

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title><FaCogs />&nbsp;Update Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Please update the status of the maintenance activity of this event,
        add any relevant comments and complete any additional information required before proceeding.
      </Modal.Body>
      <Form onSubmit={handleSubmit(submitData)}>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="3">Status</Form.Label>
            <Col sm="9">
              <Form.Control as="select" {...register('alarm_status')}>
                {statusActions.map((action, index) => {
                  return <option key={index} value={action.alarm_status}>{action.option}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">Comment</Form.Label>
            <Col sm="9">
              <Form.Control
                as="textarea"
                {...register('comment')}
              />
            </Col>
          </Form.Group>
          <div style={{minHeight: '50px'}}>
            {watchAlarmStatus === 'scheduled' &&
              <Form.Group as={Row}>
                <Form.Label column sm="3">Date</Form.Label>
                <Col sm="9">
                  <SingleDatePicker
                    date={date}
                    isOutsideRange={day => !isToday(day) && day.isBefore(moment())}
                    onDateChange={date => setDate(date)}
                  />
                </Col>
              </Form.Group>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading && <LoadingSpinner inline size={1} />}
          <Button variant="outline-secondary" onClick={props.onHide} disabled={loading}>Cancel</Button>
          <Button variant="danger" type="submit" disabled={loading}>Proceed</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function UpdateActionButton({alarm}) {
  const [showForm, setShowForm] = useState(false);
  const { postEvent, loading, success } = useAlarmEvent(alarm.id);
  const handleUpdate = useCallback(data => {
    postEvent(data);
  }, [postEvent]);

  useEffect(() => {
    if(success && !loading) setShowForm(false);
  }, [success, loading]);

  return (
    <>
      <UpdateFormModal
        show={showForm}
        onHide={() => setShowForm(false)}
        onSubmit={handleUpdate}
        loading={loading}
      />
      <Button variant="danger" onClick={() => setShowForm(true)}>Update</Button>
    </>
  );
}

function DeviceAlarmStatusIsNew({ alarm }) {
  const renderStatus = useCallback(() => {
    return (
      <div>
        <strong>Action required.</strong>&nbsp;
        <span>Please acknowledge this alarm to continue</span>
      </div>
    );
  }, []);

  const renderAction = useCallback(() => {
    return (
      <>
        <AcknowledgeActionButton alarm={alarm} variant="warning" />
      </>
    );
  }, []);
  return <DeviceAlarmStatusRow variant="warning" status={renderStatus} action={renderAction} />;
}

function DeviceAlarmStatusIsAck({ alarm }) {
  const renderStatus = useCallback(() => {
    return (
      <div>
        <strong>Action required.</strong>&nbsp;
        <span>Please mark this alarm as valid or invalid to continue.</span>
      </div>
    );
  }, []);
  const renderAction = useCallback(() => {
    return (
      <>
        <ValidateActionButton alarm={alarm} />&nbsp;
        {/* {alarm._config?.notify_on_valid && <ValidateActionButton alarm={alarm} buttonText="Valid with Notification" notify={true} />}&nbsp; */}
        <InvalidateActionButton alarm={alarm} />
      </>
    );
  }, []);
  return <DeviceAlarmStatusRow variant="warning" status={renderStatus} action={renderAction} />;
}

function DeviceAlarmStatusIsClose({alarm}) {
  const renderStatus = useCallback(() => {
    return (
      <span>Event {alarm.closed_as}</span>
    );
  }, []);

  return <DeviceAlarmStatusRow variant="dark" status={renderStatus} />;
}

function DeviceAlarmStatusIsInvalid({ alarm }) {
  const renderStatus = useCallback(() => {
    return (
      <div>
        <strong>Invalid event.</strong>&nbsp;
        <span>Please close this event or remark it as valid.</span>
      </div>
    );
  }, []);

  const renderAction = useCallback(() => {
    return (
      <>
        <ValidateActionButton alarm={alarm} variant="danger" />&nbsp;
        <CloseActionButton alarm={alarm} />
      </>
    );
  }, [alarm]);

  return <DeviceAlarmStatusRow variant="secondary" status={renderStatus} action={renderAction} />;
}

function DeviceAlarmStatusIsValid({ alarm }) {
  const renderStatus = useCallback(() => {
    return (
      <div>
        <strong>Valid Event.</strong>&nbsp;
        <span>Please schedule, close or remark this event as invalid</span>
      </div>
    );
  }, []);

  const renderAction = useCallback(() => {
    return (
      <>
        <ScheduleActionButton alarm={alarm} />&nbsp;
        <InvalidateActionButton alarm={alarm} variant="secondary" />&nbsp;
        <CloseActionButton alarm={alarm} />
      </>
    );
  }, [alarm]);

  return <DeviceAlarmStatusRow variant="danger" status={renderStatus} action={renderAction} />;
}

function DeviceAlarmStatusIsScheduled({alarm}) {
  const renderStatus = useCallback(() => {
    return (
      <div>
        <strong>{alarm.overdue ? 'Overdue' : 'Valid'} event. Maintenance activity scheduled for {moment(alarm.due_at).format('DD MMM YYYY')}</strong><br />
        <span>Please update the activity progress or remark this event as invalid</span>
      </div>
    );
  }, [alarm]);
  const renderAction = useCallback(() => {
    return (
      <>
        <UpdateActionButton alarm={alarm} />&nbsp;
        <InvalidateActionButton alarm={alarm} />&nbsp;
      </>
    );
  }, [alarm]);
  return <DeviceAlarmStatusRow variant={alarm.overdue ? 'danger' : 'info'} status={renderStatus} action={renderAction} />;
}

function DeviceAlarmStatusIsInProgress({alarm}) {
  const renderStatus = useCallback(() => {
    return (
      <div>
        {alarm.overdue ?
          <strong>Overdue event. Maintenance activity scheduled for {moment(alarm.due_at).format('DD MMM YYYY')}</strong> :
          <strong>Valid event. Maintenance activity in progress.</strong>}<br />
        <span>Please update the activity progress or remark this event as invalid.</span>
      </div>
    );
  }, []);

  const renderAction = useCallback(() => {
    return (
      <>
        <UpdateActionButton alarm={alarm} />&nbsp;
        <InvalidateActionButton alarm={alarm} />
      </>
    );
  }, [alarm]);

  return <DeviceAlarmStatusRow variant={alarm.overdue ? 'danger' : 'primary'} status={renderStatus} action={renderAction} />;
}

function DeviceAlarmStatusIsResolved({alarm}) {
  const renderStatus = useCallback(() => {
    return (
      <div>
        <strong>Resolved event.</strong>&nbsp;
        <span>Please finalise any comments and mark this event as closed.</span>
      </div>
    );
  }, []);

  const renderAction = useCallback(() => {
    return (
      <CloseActionButton alarm={alarm} />
    );
  }, [alarm]);
  return <DeviceAlarmStatusRow variant="success" status={renderStatus} action={renderAction} />;
}

function DeviceAlarmStatus({ alarmId }) {
  const alarm = useAlarm(alarmId);
  switch(alarm.alarm_status) {
    case 'new': return <DeviceAlarmStatusIsNew alarm={alarm} />;
    case 'acknowledged': return <DeviceAlarmStatusIsAck alarm={alarm} />;
    case 'invalid': return <DeviceAlarmStatusIsInvalid alarm={alarm} />;
    case 'valid': return <DeviceAlarmStatusIsValid alarm={alarm} />;
    case 'scheduled': return <DeviceAlarmStatusIsScheduled alarm={alarm} />;
    case 'in_progress': return <DeviceAlarmStatusIsInProgress alarm={alarm} />;
    case 'resolved': return <DeviceAlarmStatusIsResolved alarm={alarm} />;
    case 'closed': return <DeviceAlarmStatusIsClose alarm={alarm} />;
    default: return null;
  }
}

export default DeviceAlarmStatus;
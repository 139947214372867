import HomeCard from './HomeCard';
import Table from '../Table';
import { useReviewEventsManagementReport } from '../../modules/organisation/hooks/useGroupEventsReport';
import { equipmentNameLink, initialAlarmBadge, acknowledgedBy, latestComments } from './columns';

const columns = [
  equipmentNameLink,
  initialAlarmBadge,
  acknowledgedBy,
  latestComments,
];

function PendingReviewEvents() {
  const reviewEventsManagementReport = useReviewEventsManagementReport();

  if(!reviewEventsManagementReport) return null;

  const {_state_: { loading, error } = {}, items = []} = reviewEventsManagementReport || {};
  return (
    <HomeCard
      header="Events pending review"
      headerLink={{to: '/events?status=new,acknowledged', text: 'All events pending review'}}
      loading={loading}
      error={error}
    >
      <div className="smaller-font-size-table-container">
        <Table
          data={items}
          keyField="alarm_id"
          loading={loading}
          columns={columns}
          condensed
        />
      </div>
    </HomeCard>
  );
}

export default PendingReviewEvents;

import React, { useEffect, useCallback, useMemo } from 'react';
import withNavigationDeviceProps from '../components/withNavigationDeviceProps';

import DevicePageLayout from '../components/DevicePageLayout';
import { connect } from 'react-redux';
import Table from '../../../components/Table';
import Toolbar from '../../../components/TableToolbar';

import {
  columns as gatewayColumns,
  renderGatewayAdminTimeColours,
} from '../../gateway/screens/GatewaysAdmin';
import { useColumnsWithVisibility } from '../../../components/table/utils';

import { fetchDeviceInfo } from '../actions';
import { fetchGateways } from '../../gateway/actions';
import { gwDeviceLastHeard } from '../../gateway/columns';

import { getDevice } from '../selectors';
import { getConnections, getGatewayListState } from '../../gateway/selectors';

const columns = [
  {
    ...gwDeviceLastHeard,
    style: renderGatewayAdminTimeColours,
  },
  ...gatewayColumns,
];

const defaultSorted = [{
  dataField: gwDeviceLastHeard.dataField,
  order: 'desc',
}];

const noDataIndication = () => 'This device has no current access point connections';

function DeviceNetwork({
  deviceId,
  loading,
  error,
  lastFetch,
  connections,
  device = {},
  fetchGateways,
  fetchDeviceInfo,
}) {

  // get device on id load/change
  useEffect(() => {
    fetchDeviceInfo({ id: deviceId });
  }, [deviceId]);

  // fetch gateway list if it has not yet been fetched
  useEffect(() => {
    if (!lastFetch) {
      fetchGateways();
    }
  }, [lastFetch, fetchGateways]);

  const renderHeader = useCallback(props => {
    return (
      <Toolbar
        searchable
        title="Connected Access Points"
        loading={loading}
        error={error}
        lastFetch={lastFetch}
        tableProps={props}
      />
    );
  }, [loading, error, lastFetch]);

  const connectedGateways = useMemo(() => {
    const activeConnections = (connections || [])
      // show only active gateways and devices
      .filter(([gateway, device]) => !gateway.archived && !device.archived);
    return activeConnections
      // filter to only those gateways connected to this device
      .filter(([, device]) => device.id === deviceId)
      .map(([gateway, device, { last_heard }]) => {
        return {
          ...gateway,
          // add gateway relations to be queried in the table columns
          _related: {
            device: { ...device, last_heard },
            devices: activeConnections
              .filter(([{ id }]) => id === gateway.id)
              .map(([, device, { last_heard }]) => {
                return { ...device, last_heard };
              }),
          },
        };
      });
  }, [connections, deviceId]);

  const visibleColumns = useColumnsWithVisibility(columns, {
    // only show name and type if there is at least one value of these
    name: !!connectedGateways.find(g => g.name),
    type: !!connectedGateways.find(g => g.type),
    // only show organisation name if more than 1 organisations are present
    organisation_name: [...new Set(connectedGateways.map(g => g.organisation_id))].length > 1,
  });

  // return archived status message
  if (device.archived) {
    return (
      <div className="my-4">
        <p>
          Network connections are not currently available for archived devices.
        </p>
      </div>
    );
  }

  return (
    <div className="my-4">
      <Table
        pagination
        data={connectedGateways}
        keyField="id"
        defaultSorted={defaultSorted}
        renderHeader={renderHeader}
        columns={visibleColumns}
        noDataIndication={noDataIndication}
        loading={loading}
        refreshHandler={fetchGateways}
      />
    </div>
  );
}

const mapStateToProps = (state, { deviceId }) => {
  const device = getDevice(state, deviceId);
  const connections = getConnections(state, deviceId);
  const { loading, error, lastFetch } = getGatewayListState(state);
  return {
    loading,
    error,
    lastFetch,
    device,
    connections,
  };
};
const mapDispatchToProps = { fetchDeviceInfo, fetchGateways };

const ConnectedDeviceNetwork = withNavigationDeviceProps(
  connect(mapStateToProps, mapDispatchToProps)(DeviceNetwork)
);

export default function DeviceNetworkPage() {
  return (
    <DevicePageLayout>
      <ConnectedDeviceNetwork />
    </DevicePageLayout>
  );
}


import React from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { DropdownTopWindowButton } from '../../../components/DropdownButton';
import { LinkContainer } from 'react-router-bootstrap';

import {
  IoIosCreate,
  IoIosCube,
  IoIosKey,
  IoIosCog
} from "react-icons/io";

// Other
import ArchiveUserModal from '../components/ArchiveUserModal';
import Private from '../../../components/Private';
import { getUser, isUserSelf, getUserPathString } from '../selectors';

function UserDropdown({ user={}, userIsSelf, userPathString }) {
  return (
    <ButtonGroup>
      <LinkContainer to={`/users/${userPathString}/edit`}>
        <Button size="md" variant="outline-secondary">
          <IoIosCreate size="1.2em" /> <span>Edit</span>
        </Button>
      </LinkContainer>
      <Private minUserType="Admin">
        <LinkContainer to={`/users/${userPathString}/equipment/edit`}>
          <Button size="md" variant="outline-secondary">
            <IoIosCube size="1.2em" /> <span>Equipment</span>
          </Button>
        </LinkContainer>
      </Private>
      <DropdownTopWindowButton as={ButtonGroup} menuAlign="right" variant="outline-secondary" title="">
        {userIsSelf ? (
          // show own password page
          <LinkContainer to={`/users/${userPathString}/password/edit`}>
            <Dropdown.Item>
              <IoIosKey size="1.2em" /> <span>Update password</span>
            </Dropdown.Item>
          </LinkContainer>
        ) : (
          // show other user's password page
          <Private minUserType="Admin">
            <LinkContainer to={`/users/${userPathString}/password/edit`}>
              <Dropdown.Item>
                <IoIosKey size="1.2em" /> <span>Update password</span>
              </Dropdown.Item>
            </LinkContainer>
          </Private>
        )}
        {userIsSelf ? (
          // show own preferences page
          <LinkContainer to={`/users/${userPathString}/preferences/edit`}>
            <Dropdown.Item>
              <IoIosCog size="1.2em" /> <span>Update preferences</span>
            </Dropdown.Item>
          </LinkContainer>
        ) : (
          // show other user's preferences page
          <Private minUserType="Admin">
            <LinkContainer to={`/users/${userPathString}/preferences/edit`}>
              <Dropdown.Item>
                <IoIosCog size="1.2em" /> <span>Update preferences</span>
              </Dropdown.Item>
            </LinkContainer>
          </Private>
        )}
        {!userIsSelf && user.id && (
          <ArchiveUserModal userId={user.id} />
        )}
      </DropdownTopWindowButton>
    </ButtonGroup>
  );
}

const mapStateToProps = (state, { userId }) => {
  return {
    user: getUser(state, userId),
    userPathString: getUserPathString(state, userId),
    userIsSelf: isUserSelf(state, userId),
  };
};

export default connect(mapStateToProps)(UserDropdown);

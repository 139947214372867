import React from 'react';
import { Card } from 'react-bootstrap';

export default function EmptyCard({ Icon, children }) {
  return (
    // note: the text-left rule is added to overrule the react-bs-table-no-data styling
    // when that package is dropped, the styling can be more neutral
    <Card className="my-3 mx-auto text-left" style={styles.card}>
      {Icon && (
        <Card.Header>
          <Icon className="mx-auto" size="2.5em" />
        </Card.Header>
      )}
      {children}
    </Card>
  );
}

// export sub components from the original card
// (so Card does not also need to be imported by dependencies)
EmptyCard.Header = Card.Header;
EmptyCard.Body = Card.Body;
EmptyCard.Footer = Card.Footer;
EmptyCard.Title = Card.Title;
EmptyCard.Text = Card.Text;

// extend the EmptyCard component with helper sub-components
EmptyCard.UniversityFooter = function UniversityFooter() {
  return (
    <Card.Footer>
      <p className="mb-1">New to FitMachine?</p>
      <p className="mb-1">
        Visit <a href="https://learn.fitmachine.com/knowledge" target="_blank" rel="noreferrer">
          MOVUS Knowledge Base
        </a> for tutorials covering how to deploy, use, and apply the FitMachine solution.
      </p>
    </Card.Footer>
  );
};

const styles = {
  card: { maxWidth: '30rem' },
};


import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';


import {
  IoIosTrash,
} from "react-icons/io";

import { archiveDevice } from '../actions';

import ConfirmModal from '../../../components/ConfirmModal';
import Private from '../../../components/Private';
import { getDevice } from '../selectors';

function ArchiveDeviceModal({
  device,
  archiveDevice,
  onSuccess,
  onError,
  ...props
}) {

  const onClick = useCallback(async () => {
    try {
      await archiveDevice(device);
      if (onSuccess) {
        onSuccess(device);
      }
    }
    catch(e) {
      if (onError) {
        onError(device);
      }
    }
  }, [archiveDevice, device]);

  return (
    <Private minUserType="Admin">
      <ConfirmModal
        confirmText="Archive"
        body="Archived devices continue to communicate with MachineCloud, however data is not processed by the Artificial Intelligence and alarms are not triggered. Archived devices are only visible via the Device Admin screen."
        {...props}
      >
        <Dropdown.Item onClick={onClick}>
          <IoIosTrash size="1.2em" /> Archive
        </Dropdown.Item>
      </ConfirmModal>
    </Private>
  );
}

const mapStateToProps = (state, { deviceId }) => ({
  device: getDevice(state, deviceId),
});
const mapDispatchToProps = { archiveDevice };

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveDeviceModal);

import { Container, Row, Col } from 'react-bootstrap';
import Title from '../../../components/Title';
import RecentEvents from '../../../components/home/RecentEvents';
import AcknowledgedEvents from '../../../components/home/AcknowledgedEvents';
import ValidatedEvents from '../../../components/home/ValidatedEvents';
import PendingEvents from '../../../components/home/PendingEvents';
import EventStatus from '../../../components/home/EventStatus';
import ConditionTrend from '../../../components/home/ConditionTrend';
import DeviceStatus from '../../../components/home/DeviceStatus';
import "./home.scss";

function HomePlantView() {
  return (
    <div className="home">
      <Container fluid>
        <Title
          title="Home"
        />
        <Row noGutters>
          <Col xl={{span: 3, order: 1}} lg={6}>
            <EventStatus />
          </Col>
          <Col xl={{span: 6, order: 2}} lg={{span: 12, order: 'last'}}>
            <ConditionTrend />
          </Col>
          <Col xl={{span: 3, order: 3}} lg={6}>
            <DeviceStatus />
          </Col>
        </Row>
        <Row noGutters className="smaller-font-size-table-container">
          <Col xl={3} lg={6}>
            <RecentEvents />
          </Col>
          <Col xl={3} lg={6}>
            <AcknowledgedEvents />
          </Col>
          <Col xl={3} lg={6}>
            <ValidatedEvents />
          </Col>
          <Col xl={3} lg={6}>
            <PendingEvents />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomePlantView;